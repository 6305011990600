import { useAuthStore } from "@/store/auth-store";

export const useGetServer = () => {
  const authStore = useAuthStore();
  const badgeLabel =
    authStore.server.label == "Default"
      ? import.meta.env.VITE_APP_MODE
      : authStore.server.label;

  return badgeLabel;
};
