<script setup lang="ts">
import LinkBack from "@/components/button/link-back.vue";
import Logo from "../fragments/logo.vue";
import { useProgramStore } from "@/store/program";

const programStore = useProgramStore();
</script>

<template>
  <nav
    class="flex h-[90px] w-full flex-row justify-between gap-3 overflow-hidden border border-primary_slate bg-white"
  >
    <Logo variant="sidebar-less" />
    <div class="flex w-full flex-row items-center gap-4">
      <LinkBack
        class="mr-3"
        :no-redirect="programStore.form.name !== ''"
        @update:click="programStore.openModal"
      />

      <h1 class="text-2xl">Create New Program</h1>
    </div>
  </nav>
</template>
