<script setup lang="ts">
import LinkBack from "@/components/button/link-back.vue";
import Logo from "../fragments/logo.vue";
import { useRouter } from "vue-router";
// import { useCreateMissionStore } from "@/store/create-mission";

// const missionStore = useCreateMissionStore();
const router = useRouter();

const handleBackWithoutQuery = () => {
  if (
    router.options.history.state.back !== null &&
    router.options.history.state.back !== undefined
  ) {
    const previousPath = router.options.history.state.back as string;
    const pathWithoutQuery = previousPath?.split("?")[0];
    router.push({ path: pathWithoutQuery });
  } else {
    router.push({ name: "reward.index" });
  }
};
</script>

<template>
  <nav
    class="flex h-[90px] w-full flex-row justify-between gap-3 overflow-hidden border border-primary_slate bg-white"
  >
    <Logo variant="sidebar-less" />
    <div class="flex w-full flex-row items-center gap-4">
      <LinkBack
        class="mr-3"
        :no-redirect="true"
        @update:click="handleBackWithoutQuery"
      />

      <h1 class="text-2xl">Add Reward</h1>
    </div>
  </nav>
</template>
