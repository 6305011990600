<script setup lang="ts">
import { Environment, SidebarSuperDashboard } from "@/constants";

import { useAuthStore } from "@/store/auth-store";
import { useGetServer } from "@/composables/get-server";
import Logo from "../fragments/logo.vue";
import SidebarLink from "../fragments/sidebar-link.vue";
import { useLayoutStore } from "@/store/layout";
import { cn } from "@/lib/utils";

const authStore = useAuthStore();

const badgeLabel = useGetServer();
const layoutStore = useLayoutStore();
</script>

<template>
  <div
    v-if="layoutStore.isSidebarOpen"
    class="fixed inset-0 z-50 bg-black/50 md:hidden"
    @click="layoutStore.toggleSidebar"
  ></div>

  <aside
    :class="
      cn(
        'fixed left-0 top-0 z-20 flex h-full min-h-screen flex-col gap-3 border-r border-primary_slate bg-white transition-all duration-300 ease-in-out',
        layoutStore.isSidebarOpen
          ? 'z-[60] w-[250px] translate-x-0 px-4 md:px-2'
          : '-translate-x-full md:w-[200px] md:translate-x-0 md:px-2 lg:w-[250px] lg:px-4',
      )
    "
  >
    <Logo variant="without-border" />

    <div class="mt-6 flex flex-col gap-3">
      <template v-for="item in SidebarSuperDashboard" :key="item.id">
        <SidebarLink :item="item" />
      </template>
    </div>
  </aside>

  <div
    v-if="Environment !== 'production' && layoutStore.isSidebarOpen"
    class="fixed left-[-68px] top-[18px] z-[70]"
  >
    <div
      :class="`w-[200px] -rotate-45 bg-${authStore.server.color} py-1 text-center text-sm text-white`"
    >
      {{ badgeLabel }}
    </div>
  </div>
</template>
