import { BadgeVariantProps } from "@/components/badge/index.vue";
import { ButtonVariantProps } from "@/components/button/index.vue";
import {
  Mission,
  MissionApprovalStatus2,
  MissionList,
  MissionObjective,
} from "@/types/mission";
import { ActionReact } from "@/types/mission-react";
import { SubmissionReviewType } from "@/types/review";

export const optionsObjective: { label: string; value: MissionObjective }[] = [
  {
    label: "Awareness",
    value: "AWARENESS",
  },
  {
    label: "Reputation",
    value: "REPUTATION",
  },
  {
    label: "Internal Communication",
    value: "INTERNAL_COMMUNICATION",
  },
  {
    label: "Conversion",
    value: "CONVERSION",
  },
];

export const MissionIcon = {
  EVENT: "eva:pin-fill",
  POST: "eva:share-fill",
  SHARE: "eva:share-fill",
  ARTICLE: "eva:briefcase-fill",
  REACT: "bi:fire",
  WATCH: "eva:video-fill",
  SURVEY: "eva:edit-2-fill",
  POLLING: "eva:edit-2-fill",
  RESEARCH: "eva:edit-2-fill",
  AFFILIATE: "eva:shopping-cart-fill",
  USER_ACTIVITY: "eva:people-fill",
};

export const MissionLabel = {
  EVENT: "Event Attendance",
  POST: "Member Generated Content",
  SHARE: "Share Provided Content",
  ARTICLE: "Crowdsourcing",
  REACT: "React",
  WATCH: "Watch Video",
  SURVEY: "Survey",
  POLLING: "Polling",
  RESEARCH: "Research",
  AFFILIATE: "Affiliate",
  USER_ACTIVITY: "User Activity",
};

export const MissionReactLabel = {
  REACT_INSTAGRAM: "React Instagram",
  REACT_TIKTOK: "React Tiktok",
  REACT_TWITTER: "React Twitter",
  REACT_LINKEDIN: "React LinkedIn",
  REACT_YOUTUBE: "React Youtube",
  REACT_CUSTOM: "React Custom",
};

export const optionsType: { label: string; value: Mission; icon?: string }[] = [
  {
    label: "Event Attendance",
    value: "EVENT",
    icon: MissionIcon["EVENT"],
  },
  {
    label: "Post Content",
    value: "POST",
    icon: MissionIcon["POST"],
  },
  // {
  //   label: "Share Content",
  //   value: "SHARE",
  //   icon: MissionIcon["SHARE"],
  // },

  {
    label: "Crowdsourcing",
    value: "ARTICLE",
    icon: MissionIcon["ARTICLE"],
  },
  {
    label: "React",
    value: "REACT",
    icon: MissionIcon["REACT"],
  },
  {
    label: "Watch Video",
    value: "WATCH",
    icon: MissionIcon["WATCH"],
  },
  {
    label: "Polling & Survey",
    value: "SURVEY",
    icon: MissionIcon["SURVEY"],
  },
  // {
  //   label: "Affiliate",
  //   value: "AFFILIATE",
  //   icon: MissionIcon["AFFILIATE"],
  // },
];

export const optionsRadiusEvent = [
  {
    label: "20 Meter",
    value: 20,
  },
  {
    label: "50 Meter",
    value: 50,
  },
  {
    label: "100 Meter",
    value: 100,
  },
];

export const optionsTypeAwareness: {
  label: string;
  value: Mission;
  icon?: string;
}[] = [
  {
    label: "Event Attendance",
    value: "EVENT",
    icon: MissionIcon["EVENT"],
  },
  {
    label: "Post Content",
    value: "POST",
    icon: MissionIcon["POST"],
  },
  {
    label: "Crowdsourcing",
    value: "ARTICLE",
    icon: MissionIcon["ARTICLE"],
  },
];

export const optionsTypeRepuation: {
  label: string;
  value: Mission;
  icon?: string;
}[] = [
  {
    label: "React",
    value: "REACT",
    icon: MissionIcon["REACT"],
  },
  {
    label: "Watch Video",
    value: "WATCH",
    icon: MissionIcon["WATCH"],
  },
  {
    label: "Polling & Survey",
    value: "SURVEY",
    icon: MissionIcon["SURVEY"],
  },
];

export const handleGetBadge = (
  mission?: MissionList["type"],
): {
  icon: string;
  textClass: string;
  iconClass: string;
  variant: BadgeVariantProps["variant"];
  text: string;
  target?: string;
  type: string;
} => {
  if (!mission)
    return {
      icon: "",
      iconClass: "",
      textClass: "",
      variant: "white",
      text: "",
      type: "",
    };
  switch (mission) {
    case "POST":
      return {
        icon: "eva:plus-square-fill",
        textClass: "text-primary_green",
        iconClass: "text-primary_green",
        variant: "green",
        text: "Member Generated Content",
        type: "Post Content",
        target: "Member Generated Content",
      };
    case "SHARE":
      return {
        icon: "eva:share-fill",
        textClass: "text-primary_green",
        iconClass: "text-primary_green",
        variant: "green",
        text: "Admin Provided Content",
        type: "Post Content",
        target: "Admin Provided Content",
      };
    case "SURVEY":
      return {
        icon: "eva:message-circle-fill",
        iconClass: "text-sky-500",
        textClass: "text-primary_black",
        variant: "sky",
        type: "Research & Survey",
        text: "Survey",
      };
    case "POLLING":
      return {
        icon: "eva:radio-button-on-fill",
        iconClass: "text-sky-500",
        textClass: "text-primary_black",
        variant: "sky",
        type: "Research & Polling",
        text: "Polling",
      };
    case "RESEARCH":
      return {
        icon: "eva:edit-2-fill",
        textClass: "text-sky-500",
        iconClass: "text-sky-500",
        variant: "sky",
        text: "Research",
        target: "Research",
        type: "Research & Polling",
      };
    case "REACT":
      return {
        icon: "bi:fire",
        textClass: "text-primary_black",
        iconClass: "text-primary_red",
        variant: "red",
        text: "React",
        type: "React",
        target: "React",
      };
    case "WATCH":
      return {
        icon: "eva:video-fill",
        iconClass: "text-primary_yellow",
        textClass: "text-primary_blue",
        variant: "yellow",
        text: "Watch Video",
        type: "Watch Video",
        target: "Youtube",
      };
    case "ARTICLE":
      return {
        icon: "eva:file-text-fill",
        textClass: "text-primary_purple",
        iconClass: "text-primary_purple",
        variant: "purple",
        text: "Member Generated Article",
        type: "Crowdsourcing",
        target: "Member Generated Article",
      };

    case "EVENT":
      return {
        icon: "eva:pin-fill",
        textClass: "text-primary_blue",
        iconClass: "text-primary_blue",
        variant: "blue",
        text: "Event Attendance",
        target: "Event Attendance",
        type: "Event Attendance",
      };

    default:
      return {
        icon: "eva:eye-off-fill",
        type: "Unknown",
        textClass: "text-primary_green",
        iconClass: "text-primary_green",
        variant: "green",
        text: mission.toLocaleLowerCase() || "Unknown",
        target: mission.toLocaleLowerCase() || "Unknown",
      };
  }
};

export const handleIconReact = (
  type: ActionReact["type"] | SubmissionReviewType,
) => {
  switch (type) {
    case "LIKE":
      return "eva:heart-outline";
    case "COMMENT":
      return "eva:message-circle-outline";
    case "SHARE":
      return "eva:share-outline";
    case "RETWEET":
      return "eva:repeat-outline";
    case "APPSTORE":
      return "eva:download-outline";
    case "PLAYSTORE":
      return "eva:download-outline";
    case "SUBSCRIBE":
      return "eva:link-2-outline";
    default:
      return "eva:pin-fill";
  }
};

export const handleGetApprovalButton = (
  type: MissionApprovalStatus2,
): {
  textClass: string;
  variant: ButtonVariantProps["variant"];
  text: string;
} => {
  switch (type) {
    case "REVISED":
      return {
        textClass: "text-primary_blue",
        variant: "default",
        text: "Revised",
      };
    case "REJECTED":
      return {
        textClass: "text-primary_red",
        variant: "destructive",
        text: "Rejected",
      };
    case "NOT_REVISED_YET":
      return {
        textClass: "text-primary_blue",
        variant: "default",
        text: "Not Revision Yet",
      };
    case "SUBMITTED":
      return {
        textClass: "text-primary_blue",
        variant: "sky",
        text: "Revision Submitted",
      };
    case "EXPIRED":
      return {
        textClass: "text-primary_gray",
        variant: "slate",
        text: "Expired",
      };
    default:
      return {
        textClass: "text-primary_green",
        variant: "roleGreen",
        text: type,
      };
  }
};

export const MISSION_CONFIG = {
  EVENT: {
    title: MissionLabel["EVENT"],
    bgColor: "bg-primary_blue2",
    icon: MissionIcon["EVENT"],
    description:
      "Expand the number of attendees at your events by encouraging your members to join specific events through this mission type.",
    value: "EVENT" as Mission,
  },
  POST: {
    title: "Post Content",
    bgColor: "bg-primary_green",
    icon: MissionIcon["SHARE"],
    description:
      "Boost content related to your product or campaign by encouraging your members to generate and share their own content on their social media platforms.",
    value: "POST" as Mission,
  },
  ARTICLE: {
    title: MissionLabel["ARTICLE"],
    bgColor: "bg-primary_purple",
    icon: MissionIcon["ARTICLE"],
    description:
      "Empower members to share creative ideas, boosting brand value, product knowledge, or other goals.",
    value: "ARTICLE" as Mission,
  },
  WATCH: {
    title: MissionLabel["WATCH"],
    bgColor: "bg-primary_yellow",
    icon: MissionIcon["WATCH"],
    description:
      "Increase the viewership of your videos, or you can use this for training and program orientation for your employees.",
    value: "WATCH",
  },
  REACT: {
    title: MissionLabel["REACT"],
    bgColor: "bg-primary_yellow_dark",
    icon: MissionIcon["REACT"],
    description:
      "Enhance the quantity of likes, comments, and shares on your social media, or you can also boost your app's reviews through this mission type.",
    value: "REACT",
  },
  POLLING: {
    title: MissionLabel["SURVEY"] + " & " + MissionLabel["POLLING"],
    bgColor: "bg-primary_toska",
    icon: MissionIcon["POLLING"],
    description:
      "The Polling & Survey mission invites members to join in on polls and surveys, share their thoughts, and get benefits like useful data and cool insights. ",
    value: "POLLING",
  },
} as const;

export const filterOptions = [
  {
    label: "All Missions",
    value: "",
  },
  {
    label: "Running",
    value: "RUNNING",
  },
  {
    label: "Upcoming",
    value: "UPCOMING",
  },
  {
    label: "Ended",
    value: "ENDED",
  },
];
