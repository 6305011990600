import { ref, Ref } from "vue";
import {
  addVoucher,
  approveReward,
  buyVoucher,
  EditVoucher,
  getList,
  getRevenueAnalytics,
  getRewardSoldAnalytics,
  getSummary,
  getTopPurhaseReward,
} from "@/services/super-dashboard/reward";
import { FilterDateType, PageQueryType, ResponseType } from "@/types";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/vue-query";
import {
  RevenueAnalytics,
  Reward,
  Summary,
  TopPurchase,
  TopPurchaseFilter,
} from "@/types/super-dashboard/reward";
import { chartDateType } from "@/types/analytics";
import { useMutationResources } from "../toast-query-client";
import nProgress from "nprogress";
import { useCreateRewardStore } from "@/store/create-reward";
import { toast } from "vue3-toastify";
import { AddVoucherValues } from "@/constants/form/reward";

export const useGetTopPurchaseReward = (by: Ref<TopPurchaseFilter>) => {
  return useQuery<ResponseType<TopPurchase[]>, Error>({
    queryKey: ["super-dashboard", "reward", "top-purchase", by],
    queryFn: () => getTopPurhaseReward(by.value),
  });
};
export const useGetSummary = (
  data: Ref<FilterDateType> = ref({
    start_date: new Date(),
    end_date: new Date(),
  }),
) => {
  return useQuery<ResponseType<Summary>, Error>({
    queryKey: ["super-dashboard", "reward", "summary"],
    queryFn: () => getSummary(data.value),
  });
};

export const useGetRewardSoldAnalytics = (data: Ref<FilterDateType>) => {
  return useQuery<ResponseType<chartDateType[]>, Error>({
    queryKey: ["super-dashboard", "reward", "analytics", "sold", data],
    queryFn: () => getRewardSoldAnalytics(data.value),
  });
};

export const useGetRevenueAnalytics = (data: Ref<FilterDateType>) => {
  return useQuery<ResponseType<RevenueAnalytics[]>, Error>({
    queryKey: ["super-dashboard", "reward", "analytics", "revenue", data],
    queryFn: () => getRevenueAnalytics(data.value),
  });
};

export const useGetList = (
  pageQuery: Ref<PageQueryType>,
  status: Ref<Reward["status"]> = ref("REVIEW"),
) => {
  return useInfiniteQuery<ResponseType<Reward[]>, Error>({
    queryKey: ["super-dashboard", "reward", "lists", status, pageQuery],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getList(
        {
          ...pageQuery.value,
          page: pageNumber,
        },
        status.value,
      );
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: pageQuery.value.page as number | undefined,
  });
};

export const useApproveReward = () => {
  const { queryClient } = useMutationResources();
  return useMutation({
    mutationFn: approveReward,
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["super-dashboard", "reward", "lists"],
      });
      nProgress.start();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["super-dashboard", "reward", "lists"],
      });

      toast.success("Reward Approved");
    },
    onError: () => {
      console.error("Error adding item");
    },
    onSettled: () => {
      nProgress.done();
    },
  });
};

export const useBuyVoucher = () => {
  const { queryClient } = useMutationResources();
  const rewardStore = useCreateRewardStore();
  return useMutation({
    mutationFn: buyVoucher,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ["reward"] });
      nProgress.start();
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["reward"] });
      if (data.data?.invoice_url)
        rewardStore.invoice_url = data.data?.invoice_url;
    },
    onError: () => {
      console.error("Error adding item");
    },
    onSettled: () => {
      nProgress.done();
    },
  });
};

export const useAddVoucher = () => {
  const { queryClient } = useMutationResources();
  return useMutation({
    mutationFn: addVoucher,
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["super-dashboard", "reward"],
      });

      await queryClient.cancelQueries({
        queryKey: ["reward", "vouchers", "buy"],
      });

      nProgress.start();
    },
    onSuccess: () => {
      toast.success("Voucher Created");
      queryClient.invalidateQueries({
        queryKey: ["super-dashboard", "reward"],
      });

      queryClient.invalidateQueries({
        queryKey: ["reward", "vouchers", "buy"],
      });
    },
    onError: () => {
      console.error("Error adding item");
    },
    onSettled: () => {
      nProgress.done();
    },
  });
};
export const useEditVoucher = (id: string) => {
  const { queryClient } = useMutationResources();
  return useMutation({
    mutationFn: (body: AddVoucherValues) => EditVoucher(body, id),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["super-dashboard", "reward"],
      });

      await queryClient.cancelQueries({
        queryKey: ["reward", "vouchers", "buy"],
      });
      nProgress.start();
    },
    onSuccess: () => {
      toast.success("Voucher Edited");
      queryClient.invalidateQueries({
        queryKey: ["super-dashboard", "reward"],
      });

      queryClient.invalidateQueries({
        queryKey: ["reward", "vouchers", "buy"],
      });
    },
    onError: () => {
      console.error("Error adding item");
    },
    onSettled: () => {
      nProgress.done();
    },
  });
};
