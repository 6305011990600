<script setup lang="ts"></script>

<template>
  <div class="flex min-h-screen w-full items-center justify-center bg-gray-100">
    <div class="text-center">
      <div
        class="mx-auto h-16 w-16 animate-spin rounded-full border-b-4 border-t-4 border-primary_yellow"
      ></div>
      <p class="mt-4 text-lg text-gray-600">Loading Cuit...</p>
    </div>
  </div>
</template>
