import { Ref } from "vue";
import {
  addGroups,
  getAllGroups,
  getGroupDetail,
  getMemberGroups,
  getGroupPerformancePost,
  getGroupPerformanceReact,
  addMemberToGroup,
  deleteGroup,
  deleteMemberFromGroup,
  updateGroups,
  addGroupToProgram,
} from "@/services/group";
import { PageQueryType, ResponseType } from "@/types";
import { Group, GroupMember } from "@/types/group";
import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/vue-query";
import { useMutationResources } from "./toast-query-client";
import {
  AddMemberToGroupValues,
  CreateGroupValues,
} from "@/constants/form/group";
import { PostPerformanceType, ReactPerformanceType } from "@/types/member";
// import { useAuthStore } from "@/store/auth-store";
import { getCompanyId } from "@/composables/get-company-id";
import nProgress from "nprogress";

export const useGetGroups = (data: Ref<PageQueryType>) => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<Group[]>, Error>({
    queryKey: ["group", authCompany, data],
    queryFn: () => getAllGroups(data.value),
  });
};
export const useGetGroupsInfiniteScroll = (
  pageQuery: Ref<PageQueryType>,
  withGeneral: boolean = true,
  unused_in?: string,
) => {
  const authCompany = getCompanyId();
  return useInfiniteQuery<ResponseType<Group[]>, Error>({
    queryKey: ["group", "list", authCompany, unused_in, pageQuery, withGeneral],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getAllGroups(
        {
          ...pageQuery.value,
          page: pageNumber,
        },
        withGeneral,
        unused_in,
      );
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: pageQuery.value.page as number | undefined,
    staleTime: 0,
  });
};

const optimisticUpdateGroup = async ({
  newGroups,
  queryClient,
  isRealData = false,
}: {
  newGroups: Group | { title: string } | CreateGroupValues;
  queryClient: QueryClient;
  isRealData?: boolean;
}) => {
  await queryClient.cancelQueries({ queryKey: ["group"] });

  const tempId = `temp-${Date.now()}`;
  let formattedNewGroup: Group = {
    id: tempId,
    title: "",
    banner_url: "",
    follower_total: 0,
    is_general: false,
    member_ids: [],
    member_top_count: 0,
    member_total: 0,
    point_total: 0,
    post_total: 0,
    reward_total: 0,
  };

  if ("member_ids" in newGroups) {
    // for adding new group in modal
    formattedNewGroup.title = newGroups.title;
    formattedNewGroup.member_ids = newGroups.member_ids;
  } else if ("id" in newGroups && "title" in newGroups) {
    // for adding after success in store group
    formattedNewGroup = newGroups as Group;
  } else {
    // for adding new group but just use title
    formattedNewGroup.title = newGroups.title;
  }

  const queryCache = queryClient.getQueryCache();
  const groupQueries = queryCache.findAll({
    queryKey: ["group", "list"],
  });

  groupQueries.forEach((query) => {
    queryClient.setQueryData(
      query.queryKey,
      (oldData: InfiniteData<ResponseType<Group[]>>) => {
        if (!oldData || !oldData.pages || !oldData.pages[0]) {
          return oldData;
        }

        let newPages = [...oldData.pages];
        let firstPage = { ...newPages[0] };
        let dataArray = [...(firstPage.data || [])];

        if (isRealData) {
          // Replace the temporary entry with the real one
          const tempIndex = dataArray.findIndex((group) =>
            group.id.includes("temp"),
          );
          if (tempIndex !== -1) {
            // Remove the temporary item
            dataArray.splice(tempIndex, 1);
            // Add the real data as the first item
            dataArray.unshift(formattedNewGroup);
          } else {
            // If we can't find the temp entry, just add the real one at the beginning
            dataArray.unshift(formattedNewGroup);
          }
        } else {
          // Check if we already added a temporary item to avoid duplicates
          const hasTempItem = dataArray.some((group) =>
            group.id.includes("temp"),
          );
          if (!hasTempItem) {
            // Add the temporary item as the first element
            dataArray.unshift(formattedNewGroup);
          }
        }

        firstPage.data = dataArray;
        newPages[0] = firstPage;

        return {
          ...oldData,
          pages: newPages,
        };
      },
    );
  });
};

export const useAddGroup = () => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: addGroups,
    onMutate: async (newGroups: CreateGroupValues | { title: string }) => {
      // Add optimistic update with temporary ID at the beginning of the list
      await optimisticUpdateGroup({
        newGroups,
        queryClient,
        isRealData: false,
      });
      return { newGroups };
    },
    onSuccess: (data: ResponseType<Group>) => {
      if (data.data) {
        // Replace optimistic entry with real data at the beginning of the list
        optimisticUpdateGroup({
          newGroups: data.data,
          queryClient,
          isRealData: true,
        });
        toast.success("Group Created");
      } else {
        toast.success("Group Created");
        // If no data returned, just refresh the query
        queryClient.invalidateQueries({ queryKey: ["group"] });
      }
    },
    onError: (error) => {
      queryClient.invalidateQueries({ queryKey: ["group"] });
      toast.error("Failed to create group");
      console.error("Error adding item", error);
    },
  });
};

export const useAddGroupToProgram = (programId: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: (data: string[]) =>
      addGroupToProgram({
        group_ids: data,
        programId,
      }),

    onSuccess: () => {
      toast.success("Group Created");
      queryClient.invalidateQueries({ queryKey: ["group"] });
      queryClient.invalidateQueries({ queryKey: ["member", "list"] });
    },
    onError: (error) => {
      console.error("Error adding item", error);
    },
  });
};

export const useUpdateGroup = (id: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: (data: CreateGroupValues) => updateGroups(id, data),
    onMutate: async (updatedGroup: CreateGroupValues) => {
      await queryClient.cancelQueries({ queryKey: ["group", id] });
      return { updatedGroup };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["group", id] });
      toast.success("Group Updated Successfully");
    },
    onError: (error, context) => {
      queryClient.setQueryData(["Groups"], context);
      console.error("Error updaated Group", error);
    },
  });
};

export const useGetGroupDetail = (id: string) => {
  return useQuery<ResponseType<Group>, Error>({
    queryKey: ["group", id],
    queryFn: () => getGroupDetail(id),
  });
};

export const useGetGroupPerformancePost = (id: string, month: Ref<string>) => {
  return useQuery<ResponseType<PostPerformanceType>, Error>({
    queryKey: ["group", id, "performance", "post", month],
    queryFn: () => getGroupPerformancePost(id, month.value),
  });
};
export const useGetGroupPerformanceReact = (id: string, month: Ref<string>) => {
  return useQuery<ResponseType<ReactPerformanceType>, Error>({
    queryKey: ["group", id, "performance", "react", month],
    queryFn: () => getGroupPerformanceReact(id, month.value),
  });
};

export const useGetMemberGroups = (
  groupId: string,
  pageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<GroupMember[]>, Error>({
    queryKey: ["group", groupId, "member-list", pageQuery],
    queryFn: () => getMemberGroups(groupId, pageQuery.value),
  });
};
export const useGetMemberGroupsInfiniteScroll = (
  groupId: string,
  pageQuery: Ref<PageQueryType>,
) => {
  return useInfiniteQuery<ResponseType<GroupMember[]>, Error>({
    queryKey: ["group", groupId, "member-list", pageQuery],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getMemberGroups(groupId, {
        ...pageQuery.value,
        page: pageNumber,
      });
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: pageQuery.value.page as number | undefined,
  });
};

export const useAddMemberToGroup = (groupId: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: (data: AddMemberToGroupValues) =>
      addMemberToGroup(groupId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["group"] });
      toast.success("Added Member to Group Successfully");
    },
    onError: (error, context) => {
      queryClient.setQueryData(["Groups"], context);
      console.error("Error adding item", error);
    },
  });
};

export const useDeleteGroup = () => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: (ids: Array<string>) => deleteGroup(ids),
    onMutate: async () => {
      nProgress.start();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["group"] });
      toast.success("Group deleted successfully");
    },
    onError: () => {
      console.error("Error Deleting item");
    },
    onSettled: () => {
      nProgress.done();
    },
  });
};

export const useDeleteMemberFromGroup = (groupId: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: (memberId: string[]) =>
      deleteMemberFromGroup(groupId, memberId),
    onMutate: async (memberId: string[]) => {
      await queryClient.cancelQueries({ queryKey: ["group", groupId] });
      nProgress.start();
      return { memberId };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["group", groupId] });
      queryClient.invalidateQueries({ queryKey: ["form-resources", "member"] });
      // queryClient.invalidateQueries({
      //   queryKey: ["member", "list"],
      // });
      toast.success("Member deleted successfully");
    },
    onSettled: () => {
      nProgress.done();
    },
    onError: () => {
      console.error("Error Deleting item");
    },
  });
};
