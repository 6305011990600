<script lang="ts" setup>
import { useLayoutStore } from "@/store/layout";

import { useRoute } from "vue-router";
import { computed, ComputedRef } from "vue";
import { MissionDetailPage } from "@/types/mission";
import Link from "@/components/button/link-pinia.vue";
import Loading from "@/components/loading/index.vue";

import { useGetMissionDetail as usegetMissionDetailEvent } from "@/hooks/mission-event";
import { useGetMissionDetail as usegetMissionDetailPost } from "@/hooks/mission-post";
import { useGetMissionDetail as useGetMissionDetailShare } from "@/hooks/mission-share";
import { useGetMissionDetail as useGetMissionDetailSurvey } from "@/hooks/mission-survey";
import { useGetMissionDetail as useGetMissionDetailPolling } from "@/hooks/mission-polling";
import { useGetMissionDetail as useGetMissionDetailCrowdsourcing } from "@/hooks/mission-crowdsourcing";
import { useGetMissionDetail as useGetMissionDetailReact } from "@/hooks/mission-react";
import { useGetMissionDetail as useGetMissionDetailWatch } from "@/hooks/mission-watch";

const route = useRoute();

const { data: dataEvent } = usegetMissionDetailEvent(
  route.params.id as string,
  route.name === "mission.detail.event",
);

const { data: dataPost } = usegetMissionDetailPost(
  route.params.id as string,
  route.name === "mission.detail.post",
);

const { data: dataShare } = useGetMissionDetailShare(
  route.params.id as string,
  route.name === "mission.detail.share",
);
const { data: dataSurvey } = useGetMissionDetailSurvey(
  route.params.id as string,
  route.name === "mission.detail.survey",
);
const { data: dataPolling } = useGetMissionDetailPolling(
  route.params.id as string,
  route.name === "mission.detail.polling",
);
const { data: dataCrowdsourcing } = useGetMissionDetailCrowdsourcing(
  route.params.id as string,
  route.name === "mission.detail.crowdsourcing",
);
const { data: dataReact } = useGetMissionDetailReact(
  route.params.id as string,
  route.name === "mission.detail.react",
);
const { data: dataWatch } = useGetMissionDetailWatch(
  route.params.id as string,
  route.name === "mission.detail.watch",
);

const data = computed(() => {
  if (route.name === "mission.detail.event") {
    return dataEvent.value;
  } else if (route.name === "mission.detail.post") {
    return dataPost.value;
  } else if (route.name === "mission.detail.share") {
    return dataShare.value;
  } else if (route.name === "mission.detail.crowdsourcing") {
    return dataCrowdsourcing.value;
  } else if (route.name === "mission.detail.survey") {
    return dataSurvey.value;
  } else if (route.name === "mission.detail.polling") {
    return dataPolling.value;
  } else if (route.name === "mission.detail.react") {
    return dataReact.value;
  } else if (route.name === "mission.detail.watch") {
    return dataWatch.value;
  }
});

const link: ComputedRef<
  {
    value: MissionDetailPage;
    text: string;
    icon: string;
    hidden: boolean;
    notificationCount?: number;
  }[]
> = computed(() => [
  {
    value: "participants",
    text: "Participant",
    icon: "eva:grid-fill",
    hidden: false,
  },
  {
    value: "polling-result",
    text: "Polling Result",
    icon: "eva:pie-chart-2-fill",
    hidden: route.name !== "mission.detail.polling",
  },
  {
    value: "approval",
    text: "Task Approval",
    icon: "eva:checkmark-square-2-fill",
    hidden: data.value?.data?.approval == "AUTO",
    notificationCount: data.value?.data?.approval_count,
  },
  {
    value: "revision-reject",
    text: "Revision & Reject History",
    icon: "eva:swap-fill",
    hidden: data.value?.data?.approval == "AUTO",
  },
  {
    value: "content-library",
    text: "Content Library",
    icon: "eva:grid-outline",
    hidden: route.meta.contentLibrary !== true,
  },
  {
    value: "additional-reward",
    text: "Additional Reward",
    icon: "eva:gift-fill",
    hidden:
      data.value?.data?.additional_reward.length == 0 &&
      data.value.data.additional_point.length == 0,
  },
  {
    value: "detail",
    text: "Mission Detail",
    icon: "eva:settings-2-outline",
    hidden: false,
  },
]);

const layoutStore = useLayoutStore();
</script>

<template>
  <div v-if="data?.data" class="flex flex-row items-center gap-3 overflow-x-auto">
    <Link
      v-for="link in link"
      :hidden="link.hidden"
      :value="link.value"
      :text="link.text"
      :icon="link.icon"
      :checked="layoutStore.getMissionDetailPage === link.value"
      :notification-count="link.notificationCount"
      @update:click="layoutStore.setMissionDetailPage"
    />
  </div>

  <Loading v-else />
</template>
