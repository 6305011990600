<script setup lang="ts">
import {
  useRoute,
  // useRouter
} from "vue-router";
import {
  // useDeleteMember,
  useGetMemberDetail,
} from "@/hooks/member";
import { ref } from "vue";
// import Swal from "sweetalert2";

// import DropdownCrud from "@/components/dropdown/crud.vue";
import LinkBack from "@/components/button/link-back.vue";
import Button from "@/components/button/index.vue";
import Images from "@/components/images/index.vue";
import ModalViewMember from "@/organism/member/modal-view.vue";
import ModalEditMember from "@/organism/member/modal-edit.vue";

const route = useRoute();
// const router = useRouter();

const modalView = ref(false);
const modalEdit = ref(false);
const { data } = useGetMemberDetail(route.params.id as string);
// const { mutateAsync, isPending, isSuccess } = useDeleteMember();

// const handleView = async (e: string) => {
//   if (isFetching.value || isLoading.value) {
//     return;
//   }
//   if (e === "view") {
//     modalView.value = true;
//   } else if (e === "update") {
//     modalEdit.value = true;
//   } else if (e === "delete") {
//     const result = await Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     });

//     if (result.isConfirmed) {
//       await mutateAsync([route.params.id as string]);
//       if (isSuccess.value) {
//         router.push({ name: "member.list" });
//       }
//     }
//   }
// };
</script>

<template>
  <nav
    class="flex h-[90px] w-full flex-row items-center justify-between overflow-hidden border-b border-primary_slate bg-white px-3 py-3 pr-7"
  >
    <div class="flex w-full flex-row items-center gap-4">
      <LinkBack class="mr-3" />
      <Images
        :src="data?.data?.avatar_url"
        class="h-12 w-12 rounded-full"
        :alt="data?.data?.name"
      />
      <h1 class="title-routing">
        {{ data?.data?.name }}
      </h1>
      <Button
        v-if="data?.data?.is_pro"
        size="sm"
        text="Pro"
        icon="eva:shield-fill"
      />
    </div>
    <!-- <DropdownCrud
      class="mr-4 w-[200px] p-3"
      text-class="text-sm"
      @update:model-value="(e) => handleView(e)"
    >
      <Button
        variant="outline"
        size="base"
        :icon="
          !isFetching && !isLoading && !isPending ? 'eva:settings-outline' : ''
        "
        icon-class="text-xl text-primary_blue"
        :loading="isFetching || isLoading || isPending"
      />
    </DropdownCrud> -->

    <ModalViewMember v-model="modalView" :data="data?.data" />
    <ModalEditMember
      v-model="modalEdit"
      :data="data?.data"
      :memberId="route.params.id as string"
    />
  </nav>
</template>
