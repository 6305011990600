<script setup lang="ts">
import Button from "@/components/button/index.vue";
import { useAddBadgeToProgram } from "@/hooks/badge";
import { useAddBadgeStore } from "@/store/add-badge";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";

const badgeStore = useAddBadgeStore();
const toast = useToast();
const route = useRoute();

const { mutateAsync } = useAddBadgeToProgram(route.params.programId as string);

const submit = async () => {
  badgeStore.setDirty(true);
  const filteredBadges =
    badgeStore.badges?.filter((item) => item.checked) || [];

  if (!filteredBadges.length) {
    toast.error("Please select badge");
    return;
  }

  const hasEmptyReward = filteredBadges.some((item) => item.reward_id === "");
  if (hasEmptyReward) {
    toast.error("Please select reward");
    return;
  }

  mutateAsync(filteredBadges);
};
</script>

<template>
  <div class="flex flex-row items-center gap-3">
    <p>Step</p>
    <Button
      text="Add New Badge"
      size="sm"
      icon-class="text-white"
      variant="stepFulfilledYellow"
    />
  </div>
  <div class="flex flex-row items-center gap-3">
    <Button
      @click="submit"
      :loading="badgeStore.loading"
      text="Activate Badge"
      size="sm"
      variant="roleGreen"
      icon="eva:arrow-ios-forward-outline"
      position-icon="right"
    />
  </div>
</template>
