<script setup lang="ts">
// import { company_logo } from "@/constants/image";
import company_logo from "@/assets/icon/cuit-logo.svg";
import company_logo_global from "@/assets/images/logo-global.png";
import Icon from "@/components/icon/index.vue";
import { useGetMyCompany } from "@/hooks/company";
import { cn } from "@/lib/utils";
import { useAuthStore } from "@/store/auth-store";
import { computed } from "vue";
import { useRoute } from "vue-router";

import Images from "@/components/images/index.vue";
import { useLayoutStore } from "@/store/layout";

interface Props {
  variant?: "sidebar-less" | "sidebar" | "without-border";
}

const route = useRoute();
const authStore = useAuthStore();
const layoutStore = useLayoutStore();

const enabled = computed(
  () => authStore.getCompanyId !== null && route.meta.superdashboard !== true,
);

const { data } = useGetMyCompany(authStore.getCompanyId!, enabled);

withDefaults(defineProps<Props>(), {
  variant: "sidebar",
});
</script>

<template>
  <div
    :class="
      cn(
        'relative flex flex-row items-center justify-center overflow-hidden',
        variant == 'sidebar-less' && 'w-1/4 border-r',
        variant == 'sidebar' && 'h-[90px] border-b py-3',
        variant == 'without-border' && 'h-[90px] py-3',
      )
    "
  >
    <Images
      v-if="$route.meta.superdashboard"
      :src="company_logo_global"
      class="w-[160px]"
    />
    <component
      v-else-if="
        data?.data?.is_custom_logo == false || data?.data?.logo_url == null
      "
      :is="company_logo"
      class="w-[160px]"
    />

    <Images v-else :src="data?.data?.logo_url" class="w-[160px]" />
    <Icon
      v-if="
        $route.name != 'program.create' &&
        $route.name != 'program.detail.badge.add' &&
        $route.name != 'reward.create' &&
        $route.name != 'program.detail.mission.create'
      "
      icon="eva:close-outline"
      class="cursor-pointer text-2xl md:hidden"
      @click="layoutStore.toggleSidebar()"
    />
  </div>
</template>
