import * as yup from "yup";

export const addMemberSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: yup.string().nullable().notRequired(),
  // .matches(/^\d+$/, "Phone must contain only numbers")
  // .min(10, "Minimum length for phone is 10")
  // .max(15, "Maximum length for phone is 15"),
  gender: yup.string().nullable(),
  province_id: yup.string().required("Province is required"),
  group_id: yup.array().of(yup.string()).required("Group is required"),
  endpoint: yup.string().nullable(),
});

export const updateMemberSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: yup.string().nullable(),
  gender: yup.string().nullable(),
  province_id: yup.string().required("Province is required"),
  group_ids: yup.array().of(yup.string()).required("Group is required"),
});

export type AddMemberValues = yup.InferType<typeof addMemberSchema>;
export type UpdateMemberValues = yup.InferType<typeof updateMemberSchema>;
