import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import moment from "moment";
import { Mission } from "@/types/mission";
import isEmailValid from "@/lib/validation";
import { MissionAndSubmission } from "@/types/company";

export type Period = 1 | 3 | 6 | 12 | "CUSTOM";

export const useCreateClientStore = function () {
  const memberId = "";
  const toast = useToast();
  const store = defineStore("create-client" + memberId, {
    state: () => ({
      step: 0,
      period: 3 as Period,
      selected_quota_email: "CUSTOM" as number | "CUSTOM",
      selected_quota_whatsapp: "CUSTOM" as number | "CUSTOM",
      openModalExit: false,
      stepFulfilled: {
        0: false,
        1: false,
        2: false,
      },
      isDirty: false,
      form: {
        name: "",
        description: "",
        logo: {
          path: "",
          file: "",
        },
        category_id: "",
        start_at: moment()
          .set("hour", 0)
          .set("minute", 0)
          .format("YYYY-MM-DD HH:mm"),
        end_at: moment()
          .set("hour", 23)
          .set("minute", 59)
          .add(3, "month")
          .format("YYYY-MM-DD HH:mm"),
        quota: 0,
        selectedMission: [] as Mission[],
        mission: {
          event: [] as MissionAndSubmission["event"][],
          post: [] as MissionAndSubmission["post"][],
          crowdsourcing: [] as MissionAndSubmission["crowdsourcing"][],
          watch: [] as MissionAndSubmission["watch"][],
          react: [] as MissionAndSubmission["react"][],
          polling_survey: [] as MissionAndSubmission["polling_survey"][],
        },
        is_email: true,
        is_whatsapp: true,

        whatsapp_quota: 1000,
        email_quota: 1000,
        admin_internal: {
          name: "",
          email: "",
          taken: false,
        },
        admin_client: {
          name: "",
          email: "",
          taken: false,
        },
        endpoint: window.location.origin + "/create-password",
      },
    }),
    getters: {
      isNextDisabled(state) {
        switch (state.step) {
          case 0:
            return !(
              state.form.name &&
              state.form.description &&
              state.form.logo.file &&
              state.form.category_id &&
              state.form.quota > 0
            );
  
          case 1:
            return state.form.selectedMission.length === 0;
  
          case 2:
            return !(
              state.form.admin_internal.name &&
              state.form.admin_internal.email &&
              state.form.admin_client.name &&
              state.form.admin_client.email
            );
  
          default:
            return true;
        }
      },
      isSubmitDisabled(state) {
        return !(
          state.form.admin_internal.name &&
          state.form.admin_internal.email &&
          state.form.admin_client.name &&
          state.form.admin_client.email
        );
      },
      getStep(state) {
        return state.step;
      },
      getErrorStep0(state) {
        if (state.isDirty === false) return;
        const error = {
          name: "",
          description: "",
          logo: "",
          category_id: "",
          start_at: "",
          end_at: "",
          quota: "",
        };
        if (state.form.name == "") {
          error.name = "Name is required";
        }
        if (state.form.description == "") {
          error.description = "Description is required";
        }
        if (state.form.logo.file == "") {
          error.logo = "Logo is required";
        }
        if (state.form.category_id == "") {
          error.category_id = "Category is required";
        }
        if (state.form.start_at == "") {
          error.start_at = "Start at is required";
        }
        if (state.form.end_at == "") {
          error.end_at = "End at is required";
        }

        if (state.form.quota == 0) {
          error.quota = "Quota is required";
        }

        return error;
      },
      getErrorStep2(state) {
        if (state.isDirty === false) return;
        const error = {
          email_quota: "",
          whatsapp_quota: "",
          admin_internal: {
            name: "",
            email: "",
          },
          admin_client: {
            name: "",
            email: "",
          },
        };

        if (state.form.email_quota == 0) {
          error.email_quota = "Email quota is required";
        }
        if (state.form.whatsapp_quota == 0) {
          error.whatsapp_quota = "Whatsapp quota is required";
        }

        if (state.form.admin_internal.name == "") {
          error.admin_internal.name = "Admin internal name is required";
        }
        if (!state.form.admin_internal.email) {
          error.admin_internal.email = "Admin internal email is required";
        } else if (!isEmailValid(state.form.admin_internal.email)) {
          error.admin_internal.email = "Please enter a valid email address";
        } else if (
          state.form.admin_internal.email === state.form.admin_client.email
        ) {
          error.admin_internal.email = "Please use a different email.";
        }

        if (state.form.admin_client.name == "") {
          error.admin_client.name = "Admin client name is required";
        }
        if (!state.form.admin_client.email) {
          error.admin_client.email = "Admin client email is required";
        } else if (!isEmailValid(state.form.admin_client.email)) {
          error.admin_client.email = "Please enter a valid email address";
        } else if (
          state.form.admin_client.email === state.form.admin_internal.email
        ) {
          error.admin_client.email = "Please use a different email.";
        }

        return error;
      },
    },
    actions: {
      backStep() {
        this.step -= 1;
      },
      nextStep() {
        this.isDirty = true;
        switch (this.step) {
          case 0:
            const validate = this.validateStep0();
            if (!validate) return;
            break;
          case 1:
            if (!this.validateStep1()) return;
            break;
          case 2:
            // this.validateStep2();
            break;
        }

        this.step += 1;
        this.isDirty = false;
      },
      validateStep0() {
        let valid = true;
        if (this.form.name == "") valid = false;
        if (this.form.description == "") valid = false;
        if (this.form.logo.file == "") valid = false;
        if (this.form.category_id == "") valid = false;
        if (this.form.start_at == "") valid = false;
        if (this.form.end_at == "") valid = false;
        if (this.form.quota == 0) valid = false;

        if (!valid) {
          toast.error("Please fill all required fields");
        }

        if (valid) {
          this.stepFulfilled[0] = true;
        }

        return valid;
      },
      validateStep1() {
        let valid = true;

        if (this.form.selectedMission.length == 0) {
          valid = false;
          toast.error("Please select at least one mission");
        }

        if (
          this.form.selectedMission.includes("EVENT") &&
          this.form.mission.event.length == 0
        ) {
          toast.error("Please select at least one event");
          valid = false;
        }

        if (
          this.form.selectedMission.includes("POST") &&
          this.form.mission.post.length == 0
        ) {
          toast.error("Please select at least one post");
          valid = false;
        }

        if (
          this.form.selectedMission.includes("ARTICLE") &&
          this.form.mission.crowdsourcing.length == 0
        ) {
          toast.error("Please select at least one article");
          valid = false;
        }

        if (
          this.form.selectedMission.includes("WATCH") &&
          this.form.mission.watch.length == 0
        ) {
          toast.error("Please select at least one watch");
          valid = false;
        }

        if (
          this.form.selectedMission.includes("REACT") &&
          this.form.mission.react.length == 0
        ) {
          toast.error("Please select at least one react");
          valid = false;
        }

        if (
          this.form.selectedMission.includes("POLLING") &&
          this.form.mission.polling_survey.length == 0
        ) {
          toast.error("Please select at least one polling survey");
          valid = false;
        }

        if (valid) {
          this.stepFulfilled[1] = true;
        }

        return valid;
      },
      openModal() {
        this.openModalExit = true;
      },
      toggleEvent(event: MissionAndSubmission["event"]) {
        if (this.form.mission.event.includes(event)) {
          const index = this.form.mission.event.indexOf(event);
          this.form.mission.event.splice(index, 1);
        } else {
          this.form.mission.event.push(event);
        }
      },
      togglePost(post: MissionAndSubmission["post"]) {
        if (this.form.mission.post.includes(post)) {
          const index = this.form.mission.post.indexOf(post);
          this.form.mission.post.splice(index, 1);
        } else {
          this.form.mission.post.push(post);
        }
      },
      toggleCrowdsourcing(
        crowdsourcing: MissionAndSubmission["crowdsourcing"],
      ) {
        if (this.form.mission.crowdsourcing.includes(crowdsourcing)) {
          const index = this.form.mission.crowdsourcing.indexOf(crowdsourcing);
          this.form.mission.crowdsourcing.splice(index, 1);
        } else {
          this.form.mission.crowdsourcing.push(crowdsourcing);
        }
      },
      toggleWatch(watch: MissionAndSubmission["watch"]) {
        if (this.form.mission.watch.includes(watch)) {
          const index = this.form.mission.watch.indexOf(watch);
          this.form.mission.watch.splice(index, 1);
        } else {
          this.form.mission.watch.push(watch);
        }
      },
      toggleReact(react: MissionAndSubmission["react"]) {
        if (this.form.mission.react.includes(react)) {
          const index = this.form.mission.react.indexOf(react);
          this.form.mission.react.splice(index, 1);
        } else {
          this.form.mission.react.push(react);
        }
      },
      togglePolling(polling_survey: MissionAndSubmission["polling_survey"]) {
        if (this.form.mission.polling_survey.includes(polling_survey)) {
          const index =
            this.form.mission.polling_survey.indexOf(polling_survey);
          this.form.mission.polling_survey.splice(index, 1);
        } else {
          this.form.mission.polling_survey.push(polling_survey);
        }
      },
    },
    // persist: true,
  });

  return store();
};
