import { EditProgramSchemaType } from "@/constants/form/program";
import axiosInstance from "@/lib/axiosInstance";
import { PageQueryType, ResponseType } from "@/types";
import {
  GetAwardsInProgramId,
  Program,
  ProgramLevels,
  ProgramOverview,
  ProgramSummary,
  QueryStatusProgram,
  ResponseProgramRunning,
} from "@/types/program";
import moment from "moment";
import nProgress from "nprogress";

export async function getProgramRunnings(): Promise<
  ResponseType<ResponseProgramRunning>
> {
  const response = await axiosInstance.get("/programs/runnings");
  return response.data;
}

export async function getProgramOverview(): Promise<
  ResponseType<ProgramOverview>
> {
  const response = await axiosInstance.get("/programs/overviews");
  return response.data;
}

export async function getProgramLevels(
  programId: string,
): Promise<ResponseType<ProgramLevels[]>> {
  const response = await axiosInstance.get(
    `/programs/${programId}/levels/bars`,
  );
  return response.data;
}

export async function addMemberToProgram(
  programId: string,
  userId: string[],
): Promise<ResponseType> {
  const response = await axiosInstance.post(`/programs/${programId}/members`, {
    ids: userId,
  });

  return response.data;
}

export async function getProgramById(
  programId: string,
): Promise<ResponseType<Program>> {
  const response = await axiosInstance.get(`/programs/${programId}`);
  return response.data;
}

export async function getProgramSummary(
  programId: string,
): Promise<ResponseType<ProgramSummary>> {
  const response = await axiosInstance.get(`/programs/${programId}/summary`);
  return response.data;
}

export async function getPaginatedPrograms(
  pageQuery: PageQueryType,
  status: QueryStatusProgram,
): Promise<ResponseType<Program[]>> {
  const response = await axiosInstance.get("/programs", {
    params: {
      ...pageQuery,
      status,
    },
  });
  return response.data;
}

export async function getAwardsInProgramId(
  programId: string,
): Promise<ResponseType<GetAwardsInProgramId[]>> {
  const response = await axiosInstance.get(`/programs/${programId}/levels`, {
    params: { mock: true },
  });
  return response.data;
}

export async function createProgram(data: any): Promise<ResponseType<Program>> {
  const response = await axiosInstance.post("/programs", data);
  return response.data;
}

export async function editProgram(
  programId: string,
  data: EditProgramSchemaType,
): Promise<ResponseType<Program>> {
  const form = new FormData();

  form.append("name", data.name);

  if (data?.banner) {
    form.append("banner", data.banner as File);
  }

  form.append("description", data.description);
  form.append("term_condition", data.term_condition);
  form.append("start_at", data.start_at);
  form.append("end_at", data.end_at);

  const response = await axiosInstance.post(`/programs/${programId}`, form);
  return response.data;
}

export async function getTotalMemberInProgram(
  programId: string,
): Promise<ResponseType<number>> {
  const response = await axiosInstance.get(
    `/programs/${programId}/members/total`,
  );
  return response.data;
}

export const deleteMemberFromProgram = async (
  programId: string,
  ids: Array<string>,
): Promise<ResponseType<string>> => {
  const response = await axiosInstance.post(
    `/programs/${programId}/members/delete`,
    {
      ids: ids,
    },
  );
  return response.data;
};

export function downloadReport(): void {
  nProgress.start();

  axiosInstance
    .get(`/programs/report`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment().format("DDMMYY")}_program_report.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}
