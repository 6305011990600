import { Ref } from "vue";
import { useQuery } from "@tanstack/vue-query";

import { PageQueryType, ResponseType } from "@/types";
import {
  getMissionDetail,
  getMissionSummary,
  getMissionParticipant,
  // getMissionAnalytics,
  // getMissionContentLibrary,
} from "@/services/mission-watch";

import {
  MissionWatch,
  ParticipantWatch,
  SummaryWatch,
} from "@/types/mission-watch";

export const useGetMissionDetail = (
  missionId: string,
  enabled: boolean = true,
) => {
  return useQuery<ResponseType<MissionWatch>, Error>({
    queryKey: ["mission", "detail", "watch", missionId],
    queryFn: () => getMissionDetail(missionId),
    enabled,
  });
};

export const useGetMissionSummary = (missionId: string) => {
  return useQuery<ResponseType<SummaryWatch>, Error>({
    queryKey: ["mission", "detail", "watch", , missionId, "summary"],
    queryFn: () => getMissionSummary(missionId),
  });
};

export const useGetMissionParticipant = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<ParticipantWatch[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "watch",
      missionId,
      "participant",
      pageQuery,
    ],
    queryFn: () => getMissionParticipant(missionId, pageQuery.value),
  });
};

// export const useGetMissionAnalytics = (
//   missionId: string,
//   social?: Ref<MissionSubType> | undefined,
// ) => {
//   return useQuery<ResponseType<MissionPostAnalytics>, Error>({
//     queryKey: ["mission", "detail", "share", missionId, "analytics", social],
//     queryFn: () => getMissionAnalytics(missionId, social?.value),
//   });
// };

// export const useGetMissionSummary = (missionId: string) => {
//   return useQuery<ResponseType<SummaryShare>, Error>({
//     queryKey: ["mission", "detail", "event", , missionId, "summary"],
//     queryFn: () => getMissionSummary(missionId),
//   });
// };

// export const useGetMissionParticipant = (
//   missionId: string,
//   pageQuery: Ref<PageQueryType>,
//   social?: Ref<MissionSubType>,
// ) => {
//   return useQuery<ResponseType<ParticipantShare[]>, Error>({
//     queryKey: [
//       "mission",
//       "detail",
//       "share",
//       missionId,
//       "participant",
//       pageQuery,
//       social,
//     ],
//     queryFn: () =>
//       getMissionParticipant(missionId, pageQuery.value, social?.value),
//   });
// };

// export const useGetMissionContentLibrary = (
//   pageQuery: Ref<PageQueryType<string>>,
//   missionId: string,
// ) => {
//   return useQuery<ResponseType<ProofPost[]>, Error>({
//     queryKey: [
//       "mission",
//       "detail",
//       "share",
//       missionId,
//       "participant",
//       pageQuery,
//     ],
//     queryFn: () => getMissionContentLibrary(pageQuery.value, missionId),
//   });
// };

// export const useupdateMission = (missionId: string) => {
//   const { queryClient, toast } = useMutationResources();

//   return useMutation({
//     mutationFn: (data: EditMissionEventValue) =>
//       updateMission(missionId, data),
//     onSuccess: () => {
//       queryClient.invalidateQueries({
//         queryKey: ["mission", "detail", "event", missionId],
//       });
//       toast.success("Mission Updated");
//     },
//     onError: () => {
//       console.error("Error Updating Mission");
//       toast.error("Error Updating Mission");
//     },
//   });
// };
