import { Ref } from "vue";
import { useMutation, useQuery } from "@tanstack/vue-query";

import { PageQueryType, ResponseType } from "@/types";
import {
  ApprovalDataCrowdsourcing,
  MissionCrowdsourcing,
  ParticipantCrowdsourcing,
  SubmissionCrowdsourcingHistories,
  SummaryCrowdsourcing,
} from "@/types/mission-crowdsourcing";
import {
  changeStatusSubmissionCrowdsourcing,
  getApproval,
  getMissionContentLibrary,
  getMissionDetail,
  getMissionParticipant,
  getMissionSubmission,
  getMissionSummary,
  reminderMissionArticle,
} from "@/services/mission-crowdsourcing";
import { MissionSubType, ProofCrowdsourcing } from "@/types/mission";
import { SubmissionArticle2 } from "@/types/proposal";
import { useMutationResources } from "./toast-query-client";

export const useGetMissionDetail = (
  missionId: string,
  enabled: boolean = true,
) => {
  return useQuery<ResponseType<MissionCrowdsourcing>, Error>({
    queryKey: ["mission", "detail", "crowdsourcing", missionId],
    queryFn: () => getMissionDetail(missionId),
    enabled,
  });
};

export const useGetMissionSummary = (missionId: string) => {
  return useQuery<ResponseType<SummaryCrowdsourcing>, Error>({
    queryKey: ["mission", "detail", "crowdsourcing", , missionId, "summary"],
    queryFn: () => getMissionSummary(missionId),
  });
};

export const useGetMissionSubmission = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<SubmissionCrowdsourcingHistories[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "crowdsourcing",
      "submission",
      missionId,
      pageQuery,
    ],
    queryFn: () => getMissionSubmission(missionId, pageQuery.value),
  });
};

export const useGetMissionParticipant = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
  social?: Ref<MissionSubType>,
) => {
  return useQuery<ResponseType<ParticipantCrowdsourcing[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "crowdsourcing",
      missionId,
      "participant",
      pageQuery,
      social,
    ],
    queryFn: () =>
      getMissionParticipant(missionId, pageQuery.value, social?.value),
  });
};

export const useGetMissionContentLibrary = (
  pageQuery: Ref<PageQueryType<string>>,
  missionId: string,
) => {
  return useQuery<ResponseType<ProofCrowdsourcing[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "crowdsourcing",
      missionId,
      "participant",
      pageQuery,
    ],
    queryFn: () => getMissionContentLibrary(pageQuery.value, missionId),
  });
};

export const useGetApprovalCrowdsourcing = (missionId: string) => {
  return useQuery<ResponseType<ApprovalDataCrowdsourcing>, Error>({
    queryKey: ["mission", "detail", "crowdsourcing", "approval", missionId],
    queryFn: () => getApproval(missionId),
  });
};

export const useChangeStatusSubmissionCrowdsourcing = (
  missionId: string,
  type?: Ref<"file" | "text">,
) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: changeStatusSubmissionCrowdsourcing,
    onMutate: async ({ t_mission_id }) => {
      await queryClient.cancelQueries({
        queryKey: ["mission", "detail", "crowdsourcing", "approval", missionId],
      });
      await queryClient.cancelQueries({
        queryKey: ["mission", "detail", "crowdsourcing", "approval", missionId],
      });

      const previousCrowdsourcingData = queryClient.getQueryData([
        "mission",
        "detail",
        "crowdsourcing",
        "approval",
        missionId,
      ]);

      queryClient.setQueryData(
        ["mission", "detail", "crowdsourcing", "approval", missionId],
        (old: ResponseType<ApprovalDataCrowdsourcing> | undefined) => {
          if (!old || !old.data) {
            console.error("Old data is missing or undefined:", old);
            return old;
          }

          const submissionType = type?.value!;
          if (!old.data[submissionType]) {
            console.error(
              `Submission type "${submissionType}" does not exist in data.`,
            );
            return old;
          }

          return {
            ...old,
            data: {
              ...old.data,
              [submissionType]: old.data[submissionType].filter(
                (data: SubmissionArticle2) =>
                  data.submissions.id !== t_mission_id,
              ),
            },
          };
        },
      );

      return { previousCrowdsourcingData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "mission",
          "detail",
          "crowdsourcing",
          "submission",
          missionId,
        ],
      });

      queryClient.invalidateQueries({
        queryKey: ["mission", "detail", "crowdsourcing", missionId],
      });
      toast.success("Change Status Success");
    },
    onError: (err, _, context) => {
      if (context?.previousCrowdsourcingData) {
        queryClient.setQueryData(
          ["mission", "detail", "crowdsourcing", "approval", missionId],
          context.previousCrowdsourcingData,
        );
      }

      console.log(err);
    },
  });
};

export const useReminderSubmission = (missionId: string) => {
  const { queryClient, toast } = useMutationResources();
  return useMutation({
    mutationFn: reminderMissionArticle,
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [
          "mission",
          "detail",
          "crowdsourcing",
          "submission",
          missionId,
        ],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "mission",
          "detail",
          "crowdsourcing",
          "submission",
          missionId,
        ],
      });
      toast.success("Reminder Success");
    },
    onError: () => {
      console.error("Error Reminder");
    },
  });
};

// export const useupdateMission = (missionId: string) => {
//   const { queryClient, toast } = useMutationResources();

//   return useMutation({
//     mutationFn: (data: EditMissionEventValue) =>
//       updateMission(missionId, data),
//     onSuccess: () => {
//       queryClient.invalidateQueries({
//         queryKey: ["mission", "detail", "event", missionId],
//       });
//       toast.success("Mission Updated");
//     },
//     onError: () => {
//       console.error("Error Updating Mission");
//       toast.error("Error Updating Mission");
//     },
//   });
// };
