<script setup lang="ts">
import LinkBack from "@/components/button/link-back.vue";
import Logo from "../fragments/logo.vue";
import { useCreateMissionStore } from "@/store/create-mission";

const missionStore = useCreateMissionStore();
</script>

<template>
  <nav
    class="flex h-[90px] w-full flex-row justify-between gap-3 overflow-hidden border border-primary_slate bg-white"
  >
    <Logo variant="sidebar-less" />
    <div class="flex w-full flex-row items-center gap-4">
      <LinkBack
        class="mr-3"
        :no-redirect="missionStore.missionType !== null"
        @update:click="missionStore.openModal"
      />

      <h1 class="text-2xl">Create New Mission</h1>
    </div>
  </nav>
</template>
