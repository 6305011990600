import { MemberPagesInPrograms } from "./member";

export const ProgramsPages = {
  path: "/program",
  name: "program",
  children: [
    {
      path: "",
      name: "program.index",
      component: () => import("@/pages/program/index.vue"),
      meta: { title: "Program", allowedLevels: ["SUPER", "MAIN"] },
    },
    {
      path: "history",
      name: "program.history",
      component: () => import("@/pages/program/history.vue"),
      meta: { title: "Program", allowedLevels: ["SUPER", "MAIN"] },
    },
    {
      path: "create",
      name: "program.create",
      component: () => import("@/pages/program/create.vue"),
      meta: { header: false, sidebar: false, footer: true },
    },
    {
      path: "detail/:programId",
      name: "program.detail",
      children: [
        MemberPagesInPrograms,
        {
          path: "overview",
          name: "program.detail.index",
          component: () => import("@/pages/program/detail/index.vue"),
          meta: {
            header: false,
            programDetail: true,
            sidebarProgram: true,
          },
        },
        {
          path: "mission",
          name: "program.detail.mission",
          children: [
            {
              path: "",
              name: "program.detail.mission.index",
              component: () => import("@/pages/program/detail/mission.vue"),
              meta: {
                // header: false,
                programDetail: true,
                sidebarProgram: true,
              },
            },
            {
              path: "all-mission-history",
              name: "program.detail.mission.all-mission-history",
              component: () =>
                import("@/pages/program/detail/all-mission-history.vue"),
              meta: {
                header: false,
                programDetail: true,
                sidebarProgram: true,
              },
            },
            {
              path: "create",
              name: "program.detail.mission.create",
              component: () =>
                import("@/pages/program/detail/create-mission.vue"),
              meta: {
                header: false,
                sidebar: false,
                footer: true,
                programDetail: true,
              },
            },
            {
              path: ":id",
              name: "mission.detail",
              children: [
                {
                  path: "event",
                  name: "mission.detail.event",
                  component: () => import("@/pages/mission/detail/event.vue"),
                  meta: {
                    missionDetail: true,
                    sidebarProgram: true,
                    programDetail: true,
                  },
                },
                {
                  path: "post",
                  name: "mission.detail.post",
                  component: () => import("@/pages/mission/detail/post.vue"),
                  meta: {
                    missionDetail: true,
                    contentLibrary: true,
                    sidebarProgram: true,
                    programDetail: true,
                  },
                },
                {
                  path: "react",
                  name: "mission.detail.react",
                  component: () => import("@/pages/mission/detail/react.vue"),
                  meta: {
                    missionDetail: true,
                    sidebarProgram: true,
                    programDetail: true,
                  },
                },
                {
                  path: "share",
                  name: "mission.detail.share",
                  component: () => import("@/pages/mission/detail/share.vue"),
                  meta: {
                    missionDetail: true,
                    contentLibrary: true,
                    sidebarProgram: true,
                    programDetail: true,
                  },
                },
                {
                  path: "watch",
                  name: "mission.detail.watch",
                  component: () => import("@/pages/mission/detail/watch.vue"),
                  meta: {
                    missionDetail: true,
                    sidebarProgram: true,
                    programDetail: true,
                    // contentLibrary: true,
                  },
                },
                {
                  path: "survey",
                  name: "mission.detail.survey",
                  component: () => import("@/pages/mission/detail/survey.vue"),
                  meta: {
                    missionDetail: true,
                    sidebarProgram: true,
                    programDetail: true,
                  },
                },
                {
                  path: "affiliate",
                  name: "mission.detail.affiliate",
                  component: () => import("@/pages/mission/detail/survey.vue"),
                  meta: {
                    missionDetail: true,
                    sidebarProgram: true,
                    programDetail: true,
                  },
                },
                {
                  path: "polling",
                  name: "mission.detail.polling",
                  component: () => import("@/pages/mission/detail/polling.vue"),
                  meta: {
                    missionDetail: true,
                    sidebarProgram: true,
                    programDetail: true,
                  },
                },
                {
                  path: "crowdsourcing",
                  name: "mission.detail.crowdsourcing",
                  component: () =>
                    import("@/pages/mission/detail/crowdsourcing.vue"),
                  meta: {
                    missionDetail: true,
                    sidebarProgram: true,
                    programDetail: true,
                  },
                },
              ],
            },
          ],
        },
        {
          path: "leaderboard",
          name: "program.detail.leaderboard",
          component: () => import("@/pages/program/detail/leaderboard.vue"),
          meta: {
            header: false,
            programDetail: true,
            sidebarProgram: true,
          },
        },
        {
          path: "content-library",
          name: "program.detail.content-library",
          component: () => import("@/pages/program/detail/content-library.vue"),
          meta: {
            header: false,
            programDetail: true,
            sidebarProgram: true,
          },
        },
        {
          path: "setting",
          name: "program.detail.setting",
          children: [
            {
              path: "",
              name: "program.detail.setting.index",
              component: () =>
                import("@/pages/program/detail/setting/index.vue"),
              meta: {
                programDetail: true,
                sidebarProgram: true,
              },
            },
            {
              path: "mission-point",
              name: "program.detail.setting.mission-point",
              children: [
                {
                  path: "",
                  name: "program.detail.setting.mission-point.index",
                  component: () =>
                    import("@/pages/program/detail/setting/mission-point.vue"),
                  meta: {
                    programDetail: true,
                    sidebarProgram: true,
                  },
                },
                {
                  path: ":type",
                  name: "program.detail.setting.mission-point.detail",
                  component: () =>
                    import(
                      "@/pages/program/detail/setting/mission-point-detail.vue"
                    ),
                  meta: {
                    header: false,
                    programDetail: true,
                    sidebarProgram: true,
                  },
                },
              ],
            },
          ],
        },
        {
          path: "badge",
          name: "program.detail.badge",
          children: [
            {
              path: "",
              name: "program.detail.badge.index",
              component: () => import("@/pages/program/detail/badge.vue"),
              meta: {
                header: false,
                programDetail: true,
                sidebarProgram: true,
              },
            },
            {
              path: "add",
              name: "program.detail.badge.add",
              component: () => import("@/pages/program/detail/add-badge.vue"),
              meta: {
                header: false,
                sidebar: false,
                footer: true,
                programDetail: true,
              },
            },
            {
              path: ":badgeId",
              name: "program.detail.badge.detail",
              component: () =>
                import("@/pages/program/detail/badge-detail.vue"),
              meta: {
                header: false,

                sidebarProgram: true,
                programDetail: true,
              },
            },
          ],
        },
      ],
    },
  ],
};
