export const RewardsPages = {
  path: "/super-dashboard/reward",
  name: "super-dashboard.reward",
  children: [
    {
      path: "",
      name: "super-dashboard.reward.index",
      component: () => import("@/pages/super-dashboard/reward/index.vue"),
      meta: {
        allowedLevels: ["SUPER"],
        title: "Reward Management",
        superdashboard: true,
      },
    },
    {
      path: "purchase-request",
      name: "super-dashboard.reward.purchase-request",
      component: () =>
        import("@/pages/super-dashboard/reward/purchase-request.vue"),
      meta: {
        allowedLevels: ["SUPER"],
        title: "Reward Management",
        superdashboard: true,
      },
    },
    {
      path: "transaction-history",
      name: "super-dashboard.reward.transaction-history",
      component: () =>
        import("@/pages/super-dashboard/reward/transaction-history.vue"),
      meta: {
        allowedLevels: ["SUPER"],
        title: "Reward Management",
        superdashboard: true,
      },
    },
    {
      path: "stock-management",
      name: "super-dashboard.reward.stock-management",
      component: () =>
        import("@/pages/super-dashboard/reward/stock-management.vue"),
      meta: {
        allowedLevels: ["SUPER"],
        title: "Reward Management",
        superdashboard: true,
      },
    },
  ],
};
