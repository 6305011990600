import axiosInstance from "@/lib/axiosInstance";
import { PageQueryType, ResponseType } from "@/types";
import { MissionSubType, ProofPost } from "@/types/mission";

import {
  ApprovalShareResponse,
  MissionShare,
  MissionShareAnalytics,
  ParticipantShare,
  SubmissionShareHistories,
  SummaryShare,
} from "@/types/mission-share";
import moment from "moment";
import nProgress from "nprogress";

export async function getMissionDetail(
  MissionId: string,
): Promise<ResponseType<MissionShare>> {
  const response = await axiosInstance.get(`/missions/${MissionId}/share`);
  return response.data;
}

export async function getMissionSummary(
  MissionId: string,
): Promise<ResponseType<SummaryShare>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/post/summary`,
  );
  return response.data;
}

export async function getMissionContentLibrary(
  pageQuery: PageQueryType<string>,
  missionId: string,
): Promise<ResponseType<ProofPost[]>> {
  const params = {
    ...pageQuery,
    mission_id: missionId,
  };
  const response = await axiosInstance.get(`/contents`, {
    params: params,
  });

  return response.data;
}

export async function getMissionAnalytics(
  missionId: string,
  social?: MissionSubType,
): Promise<ResponseType<MissionShareAnalytics>> {
  const response = await axiosInstance.get(
    `/missions/${missionId}/post/analysis`,
    {
      params: {
        social,
      },
    },
  );

  return response.data;
}

export async function getMissionParticipant(
  MissionId: string,
  PageQuery: PageQueryType,
  social?: MissionSubType,
): Promise<ResponseType<ParticipantShare[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/share/participants`,
    {
      params: {
        ...PageQuery,
        social,
      },
    },
  );
  return response.data;
}

export async function getApprovalShare(
  missionId: string,
): Promise<ResponseType<ApprovalShareResponse>> {
  const response = await axiosInstance.get(
    `/missions/${missionId}/share/approvals`,
  );
  return response.data;
}

export async function getMissionSubmission(
  MissionId: string,
  PageQuery: PageQueryType,
): Promise<ResponseType<SubmissionShareHistories[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/share/histories`,
    {
      params: PageQuery,
    },
  );
  return response.data;
}

export async function reminderMissionShare(
  tMissionShareId: string,
): Promise<ResponseType> {
  const response = await axiosInstance.post(
    `/missions/share/reminder/${tMissionShareId}`,
  );
  return response.data;
}

export async function changeStatusSubmissionShare({
  t_mission_share_id,
  status,
  message,
}: {
  t_mission_share_id?: string;
  mission_react_id?: string;
  participant_id?: string;
  status: "APPROVED" | "REJECTED" | "REVIEW";
  message?: string;
}): Promise<ResponseType> {
  let url = `/missions/share/approvals`;

  if (t_mission_share_id) {
    url = `/missions/share/approvals/${t_mission_share_id}`;
  }

  const response = await axiosInstance.post(url, {
    status,
    message,
  });

  return response.data;
}

export function downloadReport({
  missionId,
  name,
}: {
  missionId: string;
  name: string;
}): void {
  nProgress.start();

  axiosInstance
    .get(`/missions/${missionId}/share/story-report`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment().format("YYYYMMDD")}_apc_post_story_report_${name}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}

// export async function updateMission(
//   MissionId: string,
//   data: EditMissionEventValue,
// ): Promise<ResponseType<string>> {
//   const form = new FormData();

//   form.append("title", data.title);
//   form.append("description", data.description);
//   form.append("banner", data.banner);
//   form.append("start_at", data.start_at);
//   form.append("end_at", data.end_at);
//   form.append("lat", data.lat.toString());
//   form.append("long", data.long.toString());
//   form.append("address", data.address);

//   if (data.brief_file) {
//     form.append("brief_file", data.brief_file);
//   }

//   if (data.brief_text) {
//     form.append("brief_text", data.brief_text);
//   }

//   const response = await axiosInstance.put(
//     `/missions/${MissionId}/event`,
//     data,
//   );
//   return response.data;
// }
