import { Ref } from "vue";
import { useMutation, useQuery } from "@tanstack/vue-query";

import {
  changeStatusSubmissionEvent,
  getApprovalEvent,
  getMissionDetail,
  getMissionParticipant,
  getMissionSubmission,
  getMissionSummary,
  updateMission,
} from "@/services/mission-event";
import { PageQueryType, ResponseType } from "@/types";
import {
  ApprovalDataEvent,
  MissionEvent,
  ParticipantEvent,
  SubmissionEventHistories,
  SummaryEvent,
} from "@/types/mission-event";
import { useMutationResources } from "./toast-query-client";
import { EditMissionEventValue } from "@/constants/form/mission";

export const useGetMissionDetail = (
  missionId: string,
  enabled: boolean = true,
) => {
  return useQuery<ResponseType<MissionEvent>, Error>({
    queryKey: ["mission", "detail", "event", missionId],
    queryFn: () => getMissionDetail(missionId),
    enabled,
  });
};

export const usegetMissionSummary = (missionId: string) => {
  return useQuery<ResponseType<SummaryEvent>, Error>({
    queryKey: ["mission", "detail", "event", , missionId, "summary"],
    queryFn: () => getMissionSummary(missionId),
  });
};

export const useGetMissionSubmission = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<SubmissionEventHistories[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "event",
      "submission",
      missionId,
      pageQuery,
    ],
    queryFn: () => getMissionSubmission(missionId, pageQuery.value),
  });
};

export const usegetMissionParticipant = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<ParticipantEvent[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "event",
      missionId,
      "participant",
      pageQuery,
    ],
    queryFn: () => getMissionParticipant(missionId, pageQuery.value),
  });
};

export const useupdateMission = (missionId: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: (data: EditMissionEventValue) => updateMission(missionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["mission", "detail", "event", missionId],
      });
      toast.success("Mission Updated");
    },
    onError: () => {
      console.error("Error Updating Mission");
      toast.error("Error Updating Mission");
    },
  });
};

export const useChangeStatusSubmissionEvent = (missionId: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: changeStatusSubmissionEvent,
    onMutate: async ({ t_mission_id }) => {
      await queryClient.cancelQueries({
        queryKey: ["mission", "detail", "event", "submission", missionId],
      });
      await queryClient.cancelQueries({
        queryKey: ["mission", "detail", "event", "approval", missionId],
      });

      const previousEventData = queryClient.getQueryData([
        "mission",
        "detail",
        "event",
        "approval",
        missionId,
      ]);

      queryClient.setQueryData(
        ["mission", "detail", "event", "approval", missionId],
        (old: ResponseType<ApprovalDataEvent[]>) => {
          const filteredData =
            old.data?.filter(
              (data: ApprovalDataEvent) => data.id !== t_mission_id,
            ) || [];

          return {
            ...old, // Preserve other response properties
            data: filteredData,
          };
        },
      );

      return { previousEventData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["mission", "detail", "event", "submission", missionId],
      });

      queryClient.invalidateQueries({
        queryKey: ["mission", "detail", "event", missionId],
      });
      toast.success("Change Status Success");
    },
    onError: (err, _, context) => {
      if (context?.previousEventData) {
        queryClient.setQueryData(
          ["mission", "detail", "event", "approval", missionId],
          context.previousEventData,
        );
      }

      console.log(err);
    },
  });
};

export const useGetApprovalEvent = (missionId: string) => {
  return useQuery<ResponseType<ApprovalDataEvent[]>, Error>({
    queryKey: ["mission", "detail", "event", "approval", missionId],
    queryFn: () => getApprovalEvent(missionId),
  });
};
