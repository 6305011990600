import moment from "moment";
import { MissionObjective } from "@/types/mission";
import { Badge as BadgeFromBackend } from "@/types/badges";
import { defineStore } from "pinia";
import { useAuthStore } from "./auth-store";
import { toast } from "vue3-toastify";
import { Member } from "@/types/member";
import { Group } from "@/types/group";

export type MissionType =
  | "EVENT"
  | "POST"
  | "SHARE"
  | "ARTICLE"
  | "POLLING"
  | "SURVEY"
  | "WATCH"
  | "REACT_INSTAGRAM"
  | "REACT_TIKTOK"
  | "REACT_TWITTER";
export type PointActivity =
  | "ATTEND"
  | "PROPOSE_SCRIPT"
  | "PROPOSE_CONTENT"
  | "POST_INSTAGRAM"
  | "POST_TWITTER"
  | "POST_TIKTOK"
  | "LIKE"
  | "COMMENT"
  | "SHARE"
  | "POLLING"
  | "SURVEY"
  | "WATCH";

type Point = {
  activity: PointActivity;
  label: string;
  value: number;
};

type Badge = {
  title: string;
  description: string;
  banner_url: string;
  badge_id: string;
  reward_id: string;
  quota: number;
  checked: boolean;
  schedule: string;
};
interface Mission {
  target_count: number;
  target_count_label: string;
  type: MissionType;
  points: Point[];
}

interface Participant {
  type: "ALL" | "GROUP" | "MEMBER";
  ids: string[];
}

export type Period = 1 | 3 | 6 | 12 | "CUSTOM";

export const useProgramStore = function () {
  const companyId = useAuthStore().getCompanyId;
  const store = defineStore("program-store-" + companyId, {
    state: () => ({
      step: 0,
      maxReachedStep: 0,
      openModalExit: false,
      isSubmitted: false,
      stepFulfilled: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      periodEnabled: {
        1: true,
        3: true,
        6: true,
        12: true,
        CUSTOM: true,
      } as Record<Period, boolean>,
      period: 3 as Period,
      isDirty: false,
      form: {
        name: "",
        description: "",
        banner_url: "",
        banner_path: "",
        term_condition: "",
        banner_file: "",
        start_at: moment()
          .set("hour", 0)
          .set("minute", 0)
          .format("YYYY-MM-DD HH:mm"),
        end_at: moment()
          .set("hour", 23)
          .set("minute", 59)
          .add(3, "month")
          .format("YYYY-MM-DD HH:mm"),
        objectives: [] as null | MissionObjective[],
        missions: [] as null | Mission[],
        badges: [] as null | Badge[],
        participant: {
          type: "ALL",
          members: [] as Member[],
          groups: [] as Group[],
          // ids: [],
        },
      },
    }),
    getters: {
      isNextDisabled(state) {
        switch (state.step) {
          case 0:
            return (
              !state.form.name ||
              state.form.name.length > 240 ||
              !state.form.banner_url ||
              !state.form.description ||
              !state.form.term_condition
            );
          case 1:
            return !state.form.objectives?.some(
              (item) => item === "AWARENESS" || item === "REPUTATION",
            );
          case 2:
            const checkedItems =
              state.form.badges?.filter((item) => item.checked) || [];
            return checkedItems.some((item) => !item.reward_id);
          case 3:
            return (
              (state.form.participant.type === "GROUP" &&
                state.form.participant.groups.length === 0) ||
              (state.form.participant.type === "MEMBER" &&
                state.form.participant.members.length === 0)
            );
          default:
            return false;
        }
      },
      getErrorStep0(state) {
        if (state.isDirty === false) return;
        const error = {
          name: "",
          banner: "",
          description: "",
          term_condition: "",
        };
        if (state.form.name == "") {
          error.name = "Name is required";
        }

        if (state.form.name.length > 240) {
          error.name = "Name is too long";
        }

        if (state.form.banner_url == "") {
          error.banner = "Banner is required";
        }

        if (state.form.description == "") {
          error.description = "Description is required";
        }

        if (state.form.term_condition == "") {
          error.term_condition = "Terms & Conditions is required";
        }

        return error;
      },
      getBannerUrl(state) {
        if (
          state.form.banner_path !== "" &&
          state.form.banner_url !== "" &&
          state.form.banner_file !== ""
        ) {
          return state.form.banner_url;
        }
        return "";
      },
      isAwareness(state) {
        return state.form.objectives?.some((item) => item === "AWARENESS");
      },
      isReputation(state) {
        return state.form.objectives?.some((item) => item === "REPUTATION");
      },
      getMissionPoints(state) {
        let point = 0;

        state.form.missions?.forEach((mission) => {
          mission.points.forEach((item) => {
            point += item.value * mission.target_count;
          });
        });

        return point;
      },
      getMissionTargetCount(state) {
        let count = 0;

        state.form.missions?.forEach((mission) => {
          count += mission.target_count;
        });

        return count;
      },
      getCheckedBadges(state) {
        return state.form.badges?.filter((item) => item.checked) || [];
      },
    },
    actions: {
      setPeriod(period: Period) {
        this.period = period;
        if (period !== "CUSTOM") {
          this.form.end_at = moment(this.form.start_at)
            .set("hour", 23)
            .set("minute", 59)
            .add(period, "month")
            .format("YYYY-MM-DD HH:mm");
        }
      },
      openModal() {
        this.openModalExit = true;
      },
      closeModal() {
        this.openModalExit = false;
      },
      setDirty(value: boolean) {
        this.isDirty = value;
      },
      setParticipantType(type: Participant["type"]) {
        this.form.participant.type = type;
      },
      setParticipantMembers(members: Member[]) {
        this.form.participant.members = members;
      },
      setParticipantGroups(groups: Group[]) {
        this.form.participant.groups = groups;
      },
      setBadge(badge: BadgeFromBackend) {
        // console.log(badge, "from backend");
        if (this.form.badges?.some((item) => item.badge_id === badge.id))
          return;

        // console.log(badge.title);

        this.form.badges?.push({
          badge_id: badge.id,
          title: badge.title,
          description: badge.description,
          banner_url: badge.banner_url,
          quota: 1,
          reward_id: "",
          checked: false,
          schedule: badge.schedule,
        });
      },
      backStep() {
        this.step -= 1;
      },
      nextStep() {
        this.setDirty(true);
        switch (this.step) {
          case 0:
            this.validateStep0();
            break;
          case 1:
            this.validateStep1();
            break;
          case 2:
            this.validateStep2();
            break;
          case 3:
            this.validateStep3();
            break;
        }
        if (this.step > this.maxReachedStep) {
          this.maxReachedStep = this.step;
        }
      },
      validateStep0() {
        if (
          this.form.name !== "" &&
          this.form.name.length <= 240 &&
          this.form.banner_path !== "" &&
          this.form.banner_url !== "" &&
          this.form.banner_file !== "" &&
          this.form.description !== "" &&
          this.form.term_condition !== ""
        ) {
          this.step = 1;
          this.stepFulfilled[0] = true;
        } else {
          this.stepFulfilled[0] = false;
        }
      },
      validateStep1() {
        if (!this.isAwareness && !this.isReputation) {
          toast.error("Please select at least one objective");
          this.stepFulfilled[1] = false;
          return;
        }

        this.stepFulfilled[1] = true;
        this.step = 2;
      },
      validateStep2() {
        let isValid = true;

        // if (this.form.badges?.filter((item) => item.checked).length == 0) {
        //   toast.error("Please select at least one badge");
        //   isValid = false;
        // }

        this.form.badges?.forEach((item) => {
          if (item.reward_id == "" && item.checked) {
            toast.error("Please select reward for badge");
            isValid = false;
            return;
          }
        });

        if (isValid) {
          this.stepFulfilled[2] = true;
          this.step = 3;
        } else {
          this.stepFulfilled[2] = false;
        }
      },
      validateStep3() {
        if (
          (this.form.participant.type == "GROUP" &&
            this.form.participant.groups.length == 0) ||
          (this.form.participant.type == "MEMBER" &&
            this.form.participant.members.length == 0)
        ) {
          toast.error("Please select at least one participant");
          this.stepFulfilled[3] = false;
          return;
        }

        this.stepFulfilled[3] = true;
        this.step = 4;
      },
      goToStep(step: number) {
        if (step > this.step) {
          this.setDirty(true);

          for (let currentStep = this.step; currentStep < step; currentStep++) {
            let validationPassed = false;

            switch (currentStep) {
              case 0:
                this.validateStep0();
                validationPassed = this.stepFulfilled[0];
                break;
              case 1:
                this.validateStep1();
                validationPassed = this.stepFulfilled[1];
                break;
              case 2:
                this.validateStep2();
                validationPassed = this.stepFulfilled[2];
                break;
              case 3:
                this.validateStep3();
                validationPassed = this.stepFulfilled[3];
                break;
            }

            if (!validationPassed) {
              this.step = currentStep;
              return;
            }
          }
        }

        this.step = step;

        if (step > this.maxReachedStep) {
          this.maxReachedStep = step;
        }
      },
    },
    persist: true,
  });

  return store();
};
