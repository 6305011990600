import { Ref } from "vue";
import { useQuery } from "@tanstack/vue-query";

import {
  getMissionDetail,
  getMissionParticipant,
  getMissionSummary,
  getMissionPopular,
} from "@/services/mission-polling";
import { PageQueryType, ResponseType } from "@/types";
import {
  MissionPolling,
  ParticipantPolling,
  SummaryPolling,
  PopularPolling,
  PollingResult,
} from "@/types/mission-polling";

export const useGetMissionDetail = (
  missionId: string,
  enabled: boolean = true,
) => {
  return useQuery<ResponseType<MissionPolling>, Error>({
    queryKey: ["mission", "detail", "polling", missionId],
    queryFn: () => getMissionDetail(missionId),
    enabled,
  });
};

export const useGetMissionSummary = (missionId: string) => {
  return useQuery<ResponseType<SummaryPolling>, Error>({
    queryKey: ["mission", "detail", "polling", , missionId, "summary"],
    queryFn: () => getMissionSummary(missionId),
  });
};

export const useGetMissionParticipant = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<ParticipantPolling[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "polling",
      missionId,
      "participant",
      pageQuery,
    ],
    queryFn: () => getMissionParticipant(missionId, pageQuery.value),
  });
};

export const useGetMissionPopular = (
  missionId: string,
  type: PollingResult,
) => {
  return useQuery<ResponseType<PopularPolling[]>, Error>({
    queryKey: ["mission", "detail", "polling", missionId, type, "results"],
    queryFn: () => getMissionPopular(missionId, type),
  });
};
