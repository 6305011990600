<script lang="ts" setup>
import Link from "@/components/button/link.vue";
import { useRoute } from "vue-router";

const route = useRoute();

const links: { link: string; text: string; icon: string }[] = [
  {
    link: "/group/detail/" + route.params.id,
    text: "Detail Group",
    icon: "eva:person-outline",
  },
  {
    link: "/group/detail/" + route.params.id + "/member",
    text: "Member List",
    icon: "eva:folder-outline",
  },
];
</script>

<template>
  <div class="flex flex-row items-center gap-3">
    <Link
      v-for="link in links"
      :to="link.link"
      :text="link.text"
      :icon="link.icon"
    />
  </div>
</template>
