import {
  enabledQueryWithCompanyId,
  getCompanyId,
} from "@/composables/get-company-id";
import {
  getAllRewardCategoryOptions,
  getAllRewardOptions,
  getCategoryCompanyOptions,
  getGroupsOptions,
  getMembersOptions,
  getMissionPostOptions,
  getProgramsOptions,
  getProvinceOptions,
} from "@/services/form-resources";
import { ResponseType } from "@/types";
import { SortOptionsRewardType, SortOptionsType } from "@/types/components";
import { useQuery } from "@tanstack/vue-query";
import { ref, Ref } from "vue";

export const useGetAllMemberOptions = (
  unused_in?: Ref<string>,
  enabled?: Ref<boolean>,
) => {
  return useQuery<ResponseType<SortOptionsType[]>, Error>({
    queryKey: ["form-resources", "member", unused_in?.value],
    queryFn: () => getMembersOptions(unused_in?.value),
    enabled,
  });
};

export const useGetAllGroupOptions = (enabled: Ref<boolean> = ref(true)) => {
  return useQuery<ResponseType<SortOptionsType[]>, Error>({
    queryKey: ["form-resources", "group"],
    queryFn: () => getGroupsOptions(),
    enabled,
  });
};

export const useGetAllProgramsOptions = () => {
  return useQuery<ResponseType<SortOptionsType[]>, Error>({
    queryKey: ["form-resources", "program"],
    queryFn: () => getProgramsOptions(),
  });
};
export const useGetCategoryCompanyOptions = () => {
  return useQuery<ResponseType<SortOptionsType[]>, Error>({
    queryKey: ["form-resources", "company-category"],
    queryFn: () => getCategoryCompanyOptions(),
  });
};

export const useGetAllMissionPostOptions = () => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<SortOptionsType[]>, Error>({
    queryKey: ["form-resources", "mission-post", authCompany],
    queryFn: () => getMissionPostOptions(),
    enabled: enabledQueryWithCompanyId,
  });
};

export const useGetAllRewardOptions = (minQota: number = 0) => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<SortOptionsRewardType[]>, Error>({
    queryKey: ["form-resources", "reward", authCompany],
    queryFn: () => getAllRewardOptions(minQota),
  });
};
export const useGetAllRewardCategoryOptions = () => {
  return useQuery<ResponseType<SortOptionsRewardType[]>, Error>({
    queryKey: ["form-resources", "reward"],
    queryFn: () => getAllRewardCategoryOptions(),
  });
};

export const useGetAllProvinceOptions = (enabled: Ref<boolean> = ref(true)) => {
  return useQuery<ResponseType<SortOptionsType[]>, Error>({
    queryKey: ["form-resources", "province"],
    queryFn: () => getProvinceOptions(),
    enabled,
  });
};
