import { PageQueryType, ResponseType } from "@/types";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/vue-query";
import {
  addMemberToProgram,
  createProgram,
  deleteMemberFromProgram,
  editProgram,
  getAwardsInProgramId,
  getPaginatedPrograms,
  getProgramById,
  getProgramOverview,
  getProgramRunnings,
  getProgramSummary,
  getTotalMemberInProgram,
} from "@/services/program";
import {
  GetAwardsInProgramId,
  Program,
  ProgramOverview,
  ProgramSummary,
  QueryStatusProgram,
  ResponseProgramRunning,
} from "@/types/program";
import { ref, Ref } from "vue";
import { useMutationResources } from "./toast-query-client";
import nProgress from "nprogress";
import { EditProgramSchemaType } from "@/constants/form/program";
import {
  enabledQueryWithCompanyId,
  getCompanyId,
} from "@/composables/get-company-id";

export const useGetProgramRunnings = () => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<ResponseProgramRunning>, Error>({
    queryKey: ["program", "running", authCompany],
    queryFn: () => getProgramRunnings(),
    enabled: enabledQueryWithCompanyId,
  });
};

export const useGetProgramSummary = (programId: string) => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<ProgramSummary>, Error>({
    queryKey: ["program", "summary", authCompany, programId],
    queryFn: () => getProgramSummary(programId),
  });
};

export const useGetProgramOverview = () => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<ProgramOverview>, Error>({
    queryKey: ["program", "overview", authCompany],
    queryFn: () => getProgramOverview(),
  });
};

export const useGetProgramById = (
  programId: string,
  enabled: Ref<boolean> | undefined = ref(true),
) => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<Program>, Error>({
    queryKey: ["program", authCompany, programId],
    queryFn: () => getProgramById(programId),
    enabled,
  });
};

export function useGetPaginatedPrograms(
  pageQuery: Ref<PageQueryType>,
  status: QueryStatusProgram,
) {
  const authCompany = getCompanyId();
  return useInfiniteQuery<ResponseType<Program[]>, Error>({
    queryKey: ["program", "list", authCompany, status, pageQuery],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getPaginatedPrograms(
        {
          ...pageQuery.value,
          page: pageNumber,
        },
        status,
      );
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: pageQuery.value.page as number | undefined,
  });
}

export const useAddMemberToProgram = (programId: string) => {
  const { queryClient, toast } = useMutationResources();
  return useMutation({
    mutationFn: (data: string[]) => addMemberToProgram(programId, data),
    onMutate: async () => {
      nProgress.start();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["program"] });
      queryClient.invalidateQueries({ queryKey: ["member", "list"] });
      toast.success("Program Created");
    },
    onSettled: () => {
      nProgress.done();
    },
  });
};

export const useEditProgram = (programId: string) => {
  const { queryClient, toast } = useMutationResources();
  return useMutation({
    mutationFn: (data: EditProgramSchemaType) => editProgram(programId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["program"] });
      toast.success("Program Updated");
    },
    onError: () => {
      console.error("Error Updating Program");
    },
  });
};

export function useGetAwardsInProgramId(programId: string) {
  return useQuery<ResponseType<GetAwardsInProgramId[]>, Error>({
    queryKey: ["program", programId, "awards"],
    queryFn: () => getAwardsInProgramId(programId),
  });
}

export const useCreateProgram = () => {
  const { queryClient, toast } = useMutationResources();
  return useMutation({
    mutationFn: createProgram,
    onMutate: async () => {
      nProgress.start();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["program"] });
      toast.success("Program Created");
    },
    onError: () => {
      console.error("Error Creating Program");
      // toast.error("Error Creating Program");
    },

    onSettled: () => {
      nProgress.done();
    },
  });
};

export const useGetTotalMemberInProgram = (
  programId: string,
  enabled: boolean = true,
) => {
  return useQuery<ResponseType<number>, Error>({
    queryKey: ["program", programId, "members", "total"],
    queryFn: () => getTotalMemberInProgram(programId),
    enabled,
  });
};

export const useDeleteMemberFromProgram = (programId: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: (ids: Array<string>) => deleteMemberFromProgram(programId, ids),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["member", "list"] });
      toast.success("Member deleted successfully");
    },
    onError: () => {
      console.error("Error Deleting item");
    },
  });
};
