<script setup lang="ts">
import Button from "@/components/button/index.vue";
import { useCreateMissionStore } from "@/store/create-mission";

const missionStore = useCreateMissionStore();
</script>

<template>
  <div class="flex flex-row flex-wrap items-center gap-3 lg:flex-nowrap">
    <p>Step</p>
    <Button
      v-if="missionStore.stepFulfilled[0] || missionStore.getStep == 0"
      text="Create New Mission"
      size="sm"
      :icon="
        missionStore.stepFulfilled[0] && missionStore.getStep !== 0
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="missionStore.getStep == 0 ? 'stepFullfiled' : 'roleGreen'"
      @click="missionStore.goToStep(0)"
      class="step-button"
    />
    <Button
      v-if="missionStore.maxReachedStep >= 1"
      text="Select Participant"
      size="sm"
      :icon="
        missionStore.stepFulfilled[1] && missionStore.getStep !== 1
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      :variant="missionStore.getStep == 1 ? 'stepFullfiled' : 'roleGreen'"
      @click="missionStore.goToStep(1)"
      class="step-button"
    />
    <Button
      v-if="missionStore.maxReachedStep >= 2"
      text="Mission Info"
      size="sm"
      :icon="
        missionStore.stepFulfilled[2] && missionStore.getStep !== 2
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      :variant="missionStore.getStep == 2 ? 'stepFullfiled' : 'roleGreen'"
      @click="missionStore.goToStep(2)"
      class="step-button"
    />
  </div>
  <div class="flex flex-row items-center gap-3">
    <Button
      v-if="missionStore.getStep > 0"
      :loading="missionStore.loadingFetch"
      @click="missionStore.backStep"
      text="Previous"
      size="sm"
      variant="outline"
      icon="eva:arrow-ios-back-outline"
    />
    <Button
      v-if="missionStore.getStep < 2"
      :loading="missionStore.loadingFetch"
      :disabled="!missionStore.isCurrentStepValid"
      @click="missionStore.nextStep"
      text="Continue"
      size="sm"
      variant="roleGreen"
      icon="eva:arrow-ios-forward-outline"
      position-icon="right"
    />
    <Button
      v-else
      @click="missionStore.openModal"
      :loading="false"
      text="Finish"
      size="sm"
      variant="roleGreen"
      icon="eva:arrow-ios-forward-outline"
      position-icon="right"
    />
  </div>
</template>

<style scoped>
.step-button {
  cursor: pointer !important;
}
.step-button:hover {
  cursor: pointer !important;
}
</style>
