import axiosInstance from "@/lib/axiosInstance";
import { PageQueryType, ResponseType } from "@/types";

import {
  MissionWatch,
  ParticipantWatch,
  SummaryWatch,
} from "@/types/mission-watch";
import moment from "moment";
import nProgress from "nprogress";

export async function getMissionDetail(
  MissionId: string,
): Promise<ResponseType<MissionWatch>> {
  const response = await axiosInstance.get(`/missions/${MissionId}/watch`);
  return response.data;
}

export async function getMissionSummary(
  MissionId: string,
): Promise<ResponseType<SummaryWatch>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/watch/summary`,
  );
  return response.data;
}

export async function getMissionParticipant(
  MissionId: string,
  PageQuery: PageQueryType,
): Promise<ResponseType<ParticipantWatch[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/watch/participants`,
    {
      params: {
        ...PageQuery,
      },
    },
  );
  return response.data;
}

export function downloadReport({
  missionId,
  name,
}: {
  missionId: string;
  name: string;
}): void {
  nProgress.start();
  axiosInstance
    .get(`/missions/${missionId}/watch/report`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // 230624_watch_video_(namamisi).csv
      link.setAttribute(
        "download",
        `${moment().format("DDMMYY")}_watch_video_${name}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}

// export async function getMissionContentLibrary(
//   pageQuery: PageQueryType<string>,
//   missionId: string,
// ): Promise<ResponseType<ProofPost[]>> {
//   const params = {
//     ...pageQuery,
//     mission_id: missionId,
//   };
//   const response = await axiosInstance.get(`/contents`, {
//     params: params,
//   });

//   return response.data;
// }

// export async function getMissionAnalytics(
//   missionId: string,
//   social?: MissionSubType,
// ): Promise<ResponseType<MissionShareAnalytics>> {
//   const response = await axiosInstance.get(
//     `/missions/${missionId}/post/analysis`,
//     {
//       params: {
//         social,
//       },
//     },
//   );

//   return response.data;
// }

// export async function getMissionParticipant(
//   MissionId: string,
//   PageQuery: PageQueryType,
//   social?: MissionSubType,
// ): Promise<ResponseType<ParticipantShare[]>> {
//   const response = await axiosInstance.get(
//     `/missions/${MissionId}/post/participants`,
//     {
//       params: {
//         ...PageQuery,
//         social,
//       },
//     },
//   );
//   return response.data;
// }

// export async function updateMission(
//   MissionId: string,
//   data: EditMissionEventValue,
// ): Promise<ResponseType<string>> {
//   const form = new FormData();

//   form.append("title", data.title);
//   form.append("description", data.description);
//   form.append("banner", data.banner);
//   form.append("start_at", data.start_at);
//   form.append("end_at", data.end_at);
//   form.append("lat", data.lat.toString());
//   form.append("long", data.long.toString());
//   form.append("address", data.address);

//   if (data.brief_file) {
//     form.append("brief_file", data.brief_file);
//   }

//   if (data.brief_text) {
//     form.append("brief_text", data.brief_text);
//   }

//   const response = await axiosInstance.put(
//     `/missions/${MissionId}/event`,
//     data,
//   );
//   return response.data;
// }
