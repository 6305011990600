import axiosInstance from "@/lib/axiosInstance";
import { ResponseType } from "@/types";
import {
  UpdateProfileValues,
  LoginValues,
  updatePasswordServiceValues,
  CreatePasswordValues,
  ForgotPasswordValues,
} from "@/constants/form/auth";
import { Admin } from "@/types/admin";
import { LoginResponse } from "@/types/auth";

export const loginService = async (
  data: LoginValues,
): Promise<ResponseType<LoginResponse>> => {
  const response = await axiosInstance.post("/auth/login", data);
  return response.data;
};
export const CreatePasswordService = async (
  data: CreatePasswordValues,
): Promise<ResponseType<LoginResponse>> => {
  const response = await axiosInstance.put("/admins/password", data);
  return response.data;
};

export const MemberVerification = async (
  data: CreatePasswordValues,
): Promise<ResponseType<LoginResponse>> => {
  const response = await axiosInstance.post("/members/mobile/password", data);
  return response.data;
};

export const MemberForgotPassword = async (
  data: CreatePasswordValues,
): Promise<ResponseType<LoginResponse>> => {
  const response = await axiosInstance.post("/members/mobile/forgot-password", data);
  return response.data;
};

export const ForgotPasswordService = async (
  // /auth/password/request
  data: ForgotPasswordValues,
): Promise<ResponseType<LoginResponse>> => {
  const response = await axiosInstance.post("/auth/password/request", data);
  return response.data;
};

export const refreshTokenService = async (
  token: string,
): Promise<ResponseType<string>> => {
  const response = await axiosInstance.get("/auth/refresh", {
    params: {
      token: token,
    },
  });
  return response.data;
};

export const updateProfileService = async (
  data: UpdateProfileValues,
): Promise<ResponseType<string>> => {
  const response = await axiosInstance.patch("/me", data);
  return response.data;
};

export const getProfileService = async (): Promise<ResponseType<Admin>> => {
  const response = await axiosInstance.get("/me");
  return response.data;
};

export const updateAvatarService = async (
  image: File,
): Promise<ResponseType<string>> => {
  const response = await axiosInstance.postForm("/me/avatar", {
    avatar: image,
  });
  return response.data;
};

export const updatePasswordService = async (
  data: updatePasswordServiceValues,
): Promise<ResponseType<string>> => {
  const response = await axiosInstance.patch("/me/password", data);
  return response.data;
};
export const logoutService = async (): Promise<ResponseType<string>> => {
  const response = await axiosInstance.post("/auth/logout");
  return response.data;
};
