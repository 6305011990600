import {
  AddActiveRewardValues,
  AddRewardValues,
} from "@/constants/form/reward";
import axiosInstance from "@/lib/axiosInstance";
import { PageQueryType, ResponseType } from "@/types";
import {
  ActiveReward,
  FilterVoucherCategory,
  FilterVoucherOperator,
  MemberActiveReward,
  MemberActiveRewardRedeemStatus,
  Reward,
  RewardDetail,
  RewardPurchased,
  RewardPurchasedDetail,
  RewardSummary,
  RewardVoucherStatus,
  Voucher,
  VoucherInBuyVoucher,
  VoucherSummary,
} from "@/types/reward";
import moment from "moment";
import nProgress from "nprogress";

export async function getRewardSummary(): Promise<ResponseType<RewardSummary>> {
  const response = await axiosInstance.get("/rewards/summary");
  return response.data;
}

export async function getAllRewards(
  pageQuery: PageQueryType,
): Promise<ResponseType<Reward[]>> {
  const params = { ...pageQuery, status: pageQuery.additionalQuery };
  const response = await axiosInstance.get("/rewards", { params });
  return response.data;
}
export async function getAllRewardsPurchased(
  pageQuery: PageQueryType,
): Promise<ResponseType<RewardPurchased[]>> {
  const response = await axiosInstance.get("/rewards/histories", {
    params: pageQuery,
  });
  return response.data;
}

export async function getRewardPurchasedDetail(
  id: string,
): Promise<ResponseType<RewardPurchasedDetail>> {
  const response = await axiosInstance.get(`/rewards/histories/${id}`);
  return response.data;
}

export async function updateRewardBanner(rewardId: string, banner: File) {
  const response = await axiosInstance.postForm(`/rewards/${rewardId}/banner`, {
    banner: banner,
  });
  return response.data;
}

export async function addReward(
  reward: AddRewardValues,
): Promise<ResponseType<Reward>> {
  const form = new FormData();

  form.append("title", reward.title);
  form.append("description", reward.description);
  form.append("terms", reward.terms);
  form.append("quota", reward.quota.toString());
  form.append("period_start", reward.period_start.toString());
  form.append("period_end", reward.period_end.toString());
  form.append("banner", reward.banner);

  const response = await axiosInstance.post("/rewards", form);
  return response.data;
}

export async function getVoucherInBuyVoucher({
  category,
  operator,
  price_start,
  price_end,
  is_active,
  pageQuery,
}: {
  category?: FilterVoucherCategory;
  operator?: FilterVoucherOperator;
  price_start?: number;
  price_end?: number;
  is_active?: boolean;
  pageQuery: PageQueryType;
}): Promise<ResponseType<VoucherInBuyVoucher[]>> {
  const response = await axiosInstance.get(`/vouchers/${category}`, {
    params: {
      ...pageQuery,
      operator,
      price_start,
      price_end,
      is_active,
    },
  });

  return response.data;
}

export async function addReward2(reward: any): Promise<ResponseType> {
  const response = await axiosInstance.post("/rewards", reward);
  return response.data;
}

export async function getActivesRewards(
  pageQuery: PageQueryType,
): Promise<ResponseType<ActiveReward[]>> {
  const response = await axiosInstance.get("/rewards/actives", {
    params: pageQuery,
  });

  return response.data;
}

export async function getMemberActiveReward(
  pageQuery: PageQueryType,
  status: MemberActiveRewardRedeemStatus,
  reward_active_id?: string,
): Promise<ResponseType<MemberActiveReward[]>> {
  const response = await axiosInstance.get("/rewards/actives/requests", {
    params: {
      ...pageQuery,
      status,
      reward_active_id,
    },
  });
  return response.data;
}

export async function approveOrRejectReward({
  id,
  status,
}: {
  id: string;
  status: "APPROVE" | "REJECT";
}): Promise<ResponseType<string>> {
  const response = await axiosInstance.patch(
    `/rewards/actives/requests/${id}`,
    {
      status,
    },
  );
  return response.data;
}

export async function getRewardDetail(
  id: string,
): Promise<ResponseType<RewardDetail>> {
  const response = await axiosInstance.get(`/rewards/${id}`);
  return response.data;
}

export async function getVoucherSummaryByRewardId(
  rewardId: string,
): Promise<ResponseType<VoucherSummary>> {
  const response = await axiosInstance.get(
    `/rewards/${rewardId}/vouchers/summary`,
  );
  return response.data;
}

export async function addActiveReward(data: AddActiveRewardValues) {
  const response = await axiosInstance.post("/rewards/actives", data);
  return response.data;
}

export async function getVouchersByRewardId(
  rewardId: string,
  pageQuery: PageQueryType<RewardVoucherStatus>,
): Promise<ResponseType<Voucher[]>> {
  const response = await axiosInstance.get(`/rewards/${rewardId}/vouchers`, {
    params: pageQuery,
  });

  return response.data;
}

export async function deleteReward(rewardId: string) {
  const response = await axiosInstance.delete(`/rewards/${rewardId}`);

  return response.data;
}

export function downloadReport(rewardId: string, name: string): void {
  nProgress.start();
  axiosInstance
    .get(`/rewards/${rewardId}/report`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment().format("DDMMYY")}_voucher_${name}_report.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}

export function downloadInvoice(invoiceId: string): void {
  nProgress.start();
  axiosInstance
    .get(`/rewards/histories/${invoiceId}/report`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment().format("DDMMYY")}_invoice.pdf`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}
