<script setup lang="ts">
import { cva } from "class-variance-authority";
// import { cn } from "lib/utils";
import Icon from "@/components/icon/index.vue";
import { ref } from "vue";
import { cn } from "@/lib/utils";

const inputVariants = cva(
  "block w-full rounded-lg text-xs duration-200 ease-linear focus:border focus:border-blue-500 focus:outline-none placeholder:text-primary_blur disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-primary_white",
  {
    variants: {
      variant: {
        default: "bg-white border-primary_slate border ",
        outline: "bg-white border-primary_gray border",
        bordered:
          "bg-white border border-primary_blur focus:border-primary_gray",
      },
      size: {
        default: "px-3 py-4 h-[40px] !text-sm",
        xs: "py-2 px-4 h-[36px]",
        icon: "px-1 py-2 h-[30px] text-primary_gray",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

interface Props {
  id?: string;
  variant?: NonNullable<Parameters<typeof inputVariants>[0]>["variant"];
  size?: NonNullable<Parameters<typeof inputVariants>[0]>["size"];

  type?: string;
  loading?: boolean;

  prependEnabled?: boolean;
  prependValue?: string;
  prependClass?: string;
  max?: number;

  label?: string;
  labelClass?: string;

  disabled?: boolean;

  mandatory?: boolean;

  autoComplete?: boolean;

  errors?: string | string[];
  icon?: string;
  iconClass?: string;
}

const model = defineModel();
const showPassword = ref(false);

const emit = defineEmits([
  "update:modelValue",
  "keydown",
  "blur",
  "keyup",
  "input",
]);

withDefaults(defineProps<Props>(), {
  icon: "",
  autoComplete: false,
  prependEnabled: false,
  disabled: false,
  loading: false,
  mandatory: false,
  prependValue: "+62",
  type: "text",
  labelClass: "text-xs font-bold capitalize",
  iconClass: "absolute left-4 top-1/2 -translate-y-1/2",
});

const normalizeErrors = (errors: Props["errors"]) => {
  if (Array.isArray(errors)) {
    return errors;
  } else if (typeof errors === "string") {
    if (errors === "") return [];
    return [errors];
  }
  return [];
};

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};
</script>

<template>
  <div class="flex w-full flex-col gap-1">
    <label
      v-if="label"
      :for="id"
      :class="cn(normalizeErrors(errors).length && '!text-red-400', labelClass)"
      >{{ label }} <span v-if="mandatory" class="text-red-400">*</span></label
    >

    <div class="relative flex items-center">
      <p
        v-if="prependEnabled"
        :class="`absolute left-0 flex h-full w-[50px] items-center justify-center text-sm ${$attrs.disabled ? 'text-primary_blur' : 'text-primary_black'} rounded-l-lg border border-primary_blur bg-white font-bold duration-200 ease-linear ${prependClass}`"
      >
        {{ prependValue }}
      </p>
      <input
        :autocomplete="autoComplete ? 'on' : 'off'"
        :id="id"
        :disabled="disabled"
        :maxlength="max"
        :max="max"
        :type="
          showPassword ? (type === 'password' ? 'text' : 'password') : type
        "
        ref="input"
        v-bind="$attrs"
        :class="[
          inputVariants({ variant, size }),

          {
            'border-red-400': normalizeErrors(errors).length,
            'pl-10': icon !== '',
            'pr-10': loading,
            'pl-[54px]': prependEnabled,
          },
        ]"
        v-model="model"
        @keydown="$emit('keydown', $event)"
        @blur="$emit('blur', $event)"
        @keyup="$emit('keyup', $event)"
        @input="$emit('input', $event)"
      />

      <slot v-if="$slots.default"></slot>

      <Icon
        v-else-if="loading"
        icon="line-md:loading-loop"
        class="absolute right-4 top-1/2 -translate-y-1/2 text-sm"
      />

      <Icon v-else-if="icon !== ''" :icon="icon" :class="iconClass" />

      <button
        v-if="type === 'password'"
        type="button"
        @click="togglePasswordVisibility"
        class="absolute right-4 top-1/2 -translate-y-1/2"
      >
        <Icon
          :icon="showPassword ? 'eva:eye-fill' : 'eva:eye-off-2-fill'"
          class="text-[24px] text-primary_gray"
        />
      </button>
    </div>

    <!-- <div
      v-else
      :class="[
        inputVariants({ variant, size }),
        $attrs.class,
        {
          'border-red-400': normalizeErrors(errors).length,
          'pl-10': icon !== '',
          'cursor-not-allowed disabled:opacity-50': $attrs.disabled,
          'flex flex-row items-center': prependEnabled,
        },
      ]"
    >
      <div class="border-r-2">{{ prependValue }}</div>
      <input
        :autocomplete="autoComplete ? 'on' : 'off'"
        :id="id"
        :type="type"
        ref="input"
        v-bind="$attrs"
        class="w-full"
        v-model="model"
      />
    </div> -->

    <div
      v-if="normalizeErrors(errors).length"
      class="flex flex-row items-center gap-2 text-[12px] text-primary_red"
    >
      <span v-for="(error, index) in normalizeErrors(errors)" :key="index">
        {{ error }}
      </span>
      <Icon
        v-if="normalizeErrors(errors).length"
        class="text-[12px] text-primary_red"
        icon="eva:alert-circle-outline"
      />
    </div>
  </div>
</template>
