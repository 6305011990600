import axiosInstance from "@/lib/axiosInstance";
import { ResponseType } from "@/types";
import { MyCompany } from "@/types/company";
import moment from "moment";

export async function getMyCompany(
  id: string,
): Promise<ResponseType<MyCompany>> {
  const response = await axiosInstance.get(`/companies/${id}`);
  return response.data;
}

export async function updateCompanyMissionFeatures({
  id,
  data,
}: {
  id: string;
  data: MyCompany["mission"];
}) {
  // /companies/{id}/missions
  const response = await axiosInstance.put(`/companies/${id}/mission`, {
    mission: data,
  });
  return response.data;
}

export async function updateCompanySubscription({
  id,
  data,
}: {
  id: string;
  data: {
    start_date: MyCompany["start_date"];
    end_date: MyCompany["end_date"];
    is_active: MyCompany["is_active"];
  };
}) {
  let form = {
    start_date: moment(data.start_date).format("YYYY-MM-DD HH:mm:ss"),
    end_date: moment(data.end_date).format("YYYY-MM-DD HH:mm:ss"),
    is_active: data.is_active,
  };
  const response = await axiosInstance.put(
    `/companies/${id}/subscription`,
    form,
  );

  return response.data;
}

export async function updateCompanyLogo(
  image: File,
  companyId: string,
): Promise<ResponseType<string>> {
  const formData = new FormData();
  formData.append("logo", image);
  const response = await axiosInstance.post(
    `/companies/${companyId}/logo`,
    formData,
  );
  return response.data;
}

export async function updateCompanyLogoCustom(
  is_custom_logo: boolean,
  companyId: string,
) {
  const response = await axiosInstance.patch(
    `/companies/${companyId}/logo/custom`,
    {
      is_custom_logo: is_custom_logo,
    },
  );
  return response.data;
}

export async function updateShowLogoOnMobile(
  is_custom_logo_mobile: boolean,
  companyId: string,
) {
  const response = await axiosInstance.patch(
    `/companies/${companyId}/logo/mobile`,
    {
      is_custom_logo_mobile: is_custom_logo_mobile,
    },
  );
  return response.data;
}
