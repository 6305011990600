import {
  getMyCompany,
  updateCompanyLogo,
  updateCompanyLogoCustom,
  updateCompanyMissionFeatures,
  updateCompanySubscription,
  updateShowLogoOnMobile,
} from "@/services/company";
import { ResponseType } from "@/types";
import { MyCompany } from "@/types/company";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { useMutationResources } from "./toast-query-client";
import nProgress from "nprogress";
import { ref, Ref } from "vue";

export const useGetMyCompany = (
  id: string,
  enabled: Ref<boolean> = ref(true),
) => {
  return useQuery<ResponseType<MyCompany>, Error>({
    queryKey: ["company", "me", id],
    queryFn: () => getMyCompany(id),
    enabled,
  });
};

export const useUpdateCompanyLogo = () => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: ({ image, companyId }: { image: File; companyId: string }) =>
      updateCompanyLogo(image, companyId),
    onMutate: async () => {
      nProgress.start();
      await queryClient.cancelQueries({ queryKey: ["company", "me"] });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company", "me"] });
      toast.success("Company Logo Updated");
    },
    onSettled: () => {
      nProgress.done();
    },
    onError: () => {
      console.error("Error Updating Company Logo");
      toast.error("Error Updating Company Logo");
    },
  });
};

export const useUpdateCompanyMissionFeatures = () => {
  const { toast } = useMutationResources();

  return useMutation({
    mutationFn: updateCompanyMissionFeatures,
    onMutate: () => {
      nProgress.start();
    },
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ["company"] });
      toast.success("Company Updated");
    },
    onError: () => {
      // console.error("Error Updating Company Logo Custom");
      toast.error("Error Updating Company");
    },
    onSettled: () => {
      nProgress.done();
      window.location.reload();
    },
  });
};
export const useUpdateCompanySubscription = () => {
  const { toast, queryClient } = useMutationResources();

  return useMutation({
    mutationFn: updateCompanySubscription,
    onMutate: () => {
      nProgress.start();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company"] });
      toast.success("Company Updated");
    },
    onError: () => {
      toast.error("Error Updating Company");
    },
    onSettled: () => {
      nProgress.done();
    },
  });
};

export const useUpdateCompanyLogoCustom = () => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: ({
      is_custom_logo,
      companyId,
    }: {
      is_custom_logo: boolean;
      companyId: string;
    }) => updateCompanyLogoCustom(is_custom_logo, companyId),

    onMutate: async ({
      is_custom_logo,
    }: {
      is_custom_logo: boolean;
      companyId: string;
    }) => {
      nProgress.start();
      await queryClient.cancelQueries({ queryKey: ["company", "me"] });
      return { is_custom_logo };
    },
    onSuccess: () => {
      // queryClient.setQueryData(["company", "me"], (old: any) => {
      //   return {
      //     ...old,
      //     data: { ...old.data, is_custom_logo: context },
      //   };
      // });

      queryClient.invalidateQueries({ queryKey: ["company", "me"] });

      toast.success("Company Logo Custom Updated");
    },
    onSettled: () => {
      nProgress.done();
    },
    onError: () => {
      console.error("Error Updating Company Logo Custom");
      toast.error("Error Updating Company Logo Custom");
    },
  });
};
export const useUpdateShowLogoOnMobile = () => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: ({
      is_custom_logo_mobile,
      companyId,
    }: {
      is_custom_logo_mobile: boolean;
      companyId: string;
    }) => updateShowLogoOnMobile(is_custom_logo_mobile, companyId),
    onMutate: async ({
      is_custom_logo_mobile,
    }: {
      is_custom_logo_mobile: boolean;
    }) => {
      nProgress.start();
      await queryClient.cancelQueries({ queryKey: ["company", "me"] });
      return { is_custom_logo_mobile };
    },
    onSuccess: (_, context) => {
      queryClient.setQueryData(["company", "me"], (old: any) => {
        if (!old) return old;
        return {
          ...old,
          data: { ...old.data, is_custom_logo_mobile: context },
        };
      });

      toast.success("Show Logo On Mobile Updated");
    },
    onSettled: () => {
      nProgress.done();
    },
    onError: () => {
      console.error("Error Updating Show Logo On Mobile");
      toast.error("Error Updating Show Logo On Mobile");
    },
  });
};
