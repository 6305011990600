<script setup lang="ts">
import { nextTick, ref, onMounted } from "vue";
import Layout from "@/layouts/index.vue";
import { useAuthStore } from "./store/auth-store";

import LoadingPages from "@/pages/loading/index.vue";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
import { useRoute } from "vue-router";
import router from "./config/route";
import { useLayoutStore } from "./store/layout";

const isInitialLoading = ref(true);

window.addEventListener("vite:preloadError", (event) => {
  console.error("Error in vite:preloadError", event);
  window.location.reload();
});

const route = useRoute();
const authStore = useAuthStore();
const layoutStore = useLayoutStore();

onMounted(async () => {
  await router.isReady();
  if (
    !route.meta.superdashboard &&
    !route.meta.public &&
    authStore.token &&
    !authStore.company_id &&
    authStore.level == "SUPER"
  ) {
    router.push({ name: "super-dashboard.home.index" });
  }

  if (authStore.token == "" && !route.meta.public) {
    router.push({ name: "login" });
  }
});

nextTick(async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  isInitialLoading.value = false;
});

window.addEventListener("resize", () => {
  layoutStore.innerWidth = window.innerWidth;

  layoutStore.setIsSidebarOpen(window.innerWidth > 768);
});
</script>

<template>
  <LoadingPages v-if="isInitialLoading" />

  <template v-else>
    <VueQueryDevtools />
    <Layout v-if="!$route.meta.public" :key="authStore.company_id ?? 'public'">
      <router-view />
    </Layout>

    <router-view v-else />
  </template>
</template>
