<script setup lang="ts">
import { cn } from "@/lib/utils";
import { SortOptionsType } from "@/types/components";

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    options: SortOptionsType<string | number>[];
    class?: string;
  }>(),
  {
    placeholder: "Select A Options",
    class: "",
  },
);

const model = defineModel();
const emit = defineEmits(["change"]);
</script>

<template>
  <select
    v-model="model"
    :class="
      cn(
        'flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-2 py-1 text-xs font-bold text-primary_black focus:outline-none disabled:cursor-not-allowed',
        props.class,
      )
    "
    name="select"
    :id="'select' + Math.random().toString(16).slice(2)"
    :placeholder="placeholder"
  >
    <option
      v-for="option in options"
      :value="option.value"
      :key="option.value"
      :label="option.label"
      class="text-sm"
    >
      {{ option.label }}
    </option>
  </select>
</template>
