import Maintenance from "@/pages/maintenance.vue";
import NotFound from "@/pages/not-found.vue";

export const OthersPages = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/auth/login.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/pages/auth/forgot-password.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "",
    component: () => import("@/pages/other/terms-and-conditions.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/pages/other/privacy-policy.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/pages/auth/forgot-password.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/create-password",
    name: "create-password",
    component: () => import("@/pages/auth/create-password.vue"),
    meta: {
      public: true,
    },
  },
  {
    // https://cuit.komune.id/mobile/account-verification?email=jlkjl@kjlkjlkj.jlkj&token=kjkllj
    path: "/mobile/account-verification",
    name: "account-verification",
    component: () => import("@/pages/auth/mobile-verification.vue"),
    meta: {
      public: true,
    },
  },
  
  {
    path: "/mobile/forgot-password",
    name: "mobile-forgot-password",
    component: () => import("@/pages/auth/mobile-forgot-password.vue"),
    meta: {
      public: true,
    },
  },

  {
    path: "/announcement",
    name: "announcement",
    children: [
      {
        path: "",
        name: "announcement.index",
        component: () => import("@/pages/announcement/index.vue"),
      },
    ],
    meta: {
      title: "Announcements",
      header: false,
    },
  },

  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "not-found",
    component: NotFound,
    meta: {
      public: true,
      header: false,
      sidebar: false,
    },
  },
  {
    // path: "*",
    path: "/maintenance",
    name: "maintenance",
    component: Maintenance,
    meta: {
      public: true,
      header: false,
      sidebar: false,
    },
  },
  {
    path: "/not-found",
    name: "not-found2",
    component: NotFound,
    meta: {
      public: true,
      header: false,
      sidebar: false,
    },
  },
  {
    path: "/research/upload-chunk",
    name: "upload-chunk",
    component: () => import("@/pages/research/upload-chunk.vue"),
    meta: {
      public: true,
    },
  },
];
