<script lang="ts" setup>
import { cn } from "@/lib/utils";
import { SortOptionsType } from "@/types/components";
import {
  DropdownMenuRoot,
  DropdownMenuPortal,
  DropdownMenuTrigger,
  DropdownMenuContent,
  // DropdownMenuArrow,
  // DropdownMenuCheckboxItem,
  DropdownMenuItem,
  // DropdownMenuItemIndicator,
  // DropdownMenuLabel,
  // DropdownMenuRadioGroup,
  // DropdownMenuRadioItem,
  // DropdownMenuSeparator,
  // DropdownMenuSub,
  // DropdownMenuSubContent,
  // DropdownMenuSubTrigger,
} from "radix-vue";
import { ref } from "vue";
interface Props {
  options?: SortOptionsType[] | string[] | number[];
  title?: string;
  class?: string;
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
});

const localLabel = ref("");
const updateValue = defineEmits(["update:modelValue"]);
const openState = ref(false);

const toggleOpen = () => {
  openState.value = !openState.value;
};

const handleSelect = (item: SortOptionsType | string | number) => {
  if (typeof item === "object" && "label" in item) {
    localLabel.value = item.label;
  } else {
    localLabel.value = item.toString();
  }
  updateValue("update:modelValue", item);
};
</script>

<template>
  <DropdownMenuRoot @update:open="toggleOpen">
    <DropdownMenuTrigger
      class="flex items-center justify-center"
      aria-label="Customise options"
    >
      <slot :open="openState" />
    </DropdownMenuTrigger>

    <DropdownMenuPortal>
      <DropdownMenuContent
        :class="
          cn(
            'data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-50 w-[200px] rounded-xl border border-primary_slate bg-white p-3 outline-none will-change-[opacity,transform]',
            props.class,
          )
        "
      >
        <slot name="content" v-if="$slots.content" />

        <div v-else-if="options.length > 0" class="space-y-2">
          <p v-if="title" class="text-[10px] text-primary_black">{{ title }}</p>
          <DropdownMenuItem
            class="cursor-pointer rounded-lg p-2 text-xs capitalize text-primary_black duration-200 ease-linear hover:bg-primary_transparant_blue"
            @click="handleSelect(option)"
            v-for="option in options"
          >
            {{
              typeof option === "object" && option.label ? option.label : option
            }}
          </DropdownMenuItem>
        </div>

        <div class="flex h-[40px] items-center justify-center" v-else>
          <p class="text-xs font-bold">No options available</p>
        </div>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenuRoot>
</template>
