import { RewardsPages } from "./reward";
import { SettingPages } from "./setting";

export const SuperDashboardPages = {
  path: "/super-dashboard",
  name: "super-dashboard.home",
  children: [
    {
      path: "/super-dashboard/home",
      name: "super-dashboard.home.index",
      component: () => import("@/pages/super-dashboard/home/index.vue"),
      meta: {
        allowedLevels: ["SUPER"],
        title: "Home",
        superdashboard: true,
      },
    },
    {
      path: "/super-dashboard/client-create",
      name: "super-dashboard.client.create",
      component: () => import("@/pages/super-dashboard/client/create.vue"),
      meta: {
        allowedLevels: ["SUPER"],
        title: "Home",
        sidebar: false,
        header: false,
        footer: true,
        superdashboard: true,
      },
    },
    SettingPages,
    RewardsPages,
  ],
};
