export const HomePages = {
  path: "/",
  name: "home",
  children: [
    {
      path: "",
      name: "home.index",
      component: () => import("@/pages/home/index.vue"),
      meta: {
        allowedLevels: ["SUPER", "MAIN"],
      },
    },
    {
      path: "award-level",
      name: "home.award-level",
      component: () => import("@/pages/home/award-level.vue"),
      meta: {
        allowedLevels: ["SUPER", "MAIN"],
      },
    },
    {
      path: "leaderboard",
      name: "home.leaderboard",
      component: () => import("@/pages/home/leaderboard.vue"),
      meta: {
        allowedLevels: ["SUPER", "MAIN"],
      },
    },
    {
      path: "content-library",
      name: "home.content-library",
      component: () => import("@/pages/home/content-library.vue"),
      meta: {
        allowedLevels: ["SUPER", "MAIN"],
      },
    },
  ],
  meta: {
    title: "Home",
  },
};
