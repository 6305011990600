<script lang="ts" setup>
import { cn } from "@/lib/utils";
import Navbar from "./navbar/public.vue";

interface Props {
  class?: string;
}

const props = defineProps<Props>();
</script>

<template>
  <div class="w-full items-center justify-center">
    <Navbar />
    <div :class="cn('bg-white px-4 py-4 lg:px-24 lg:py-16', props.class)">
      <slot />
    </div>
  </div>
</template>
