import axiosInstance from "@/lib/axiosInstance";
import { ResponseType } from "@/types";
import { SortOptionsRewardType, SortOptionsType } from "@/types/components";

export async function getGroupsOptions(): Promise<
  ResponseType<SortOptionsType[]>
> {
  const response = await axiosInstance.get("/groups/options");

  return response.data;
}
export async function getMembersOptions(
  unused_in?: string,
): Promise<ResponseType<SortOptionsType[]>> {
  const response = await axiosInstance.get("/members/options", {
    params: {
      unused_in: unused_in,
    },
  });
  return response.data;
}
export async function getCategoryCompanyOptions(): Promise<
  ResponseType<SortOptionsType[]>
> {
  const response = await axiosInstance.get("/clients/category/options");
  return response.data;
}

export async function getProgramsOptions(): Promise<
  ResponseType<SortOptionsType[]>
> {
  const response = await axiosInstance.get("/programs/options");
  return response.data;
}

export async function getMissionPostOptions(): Promise<
  ResponseType<SortOptionsType[]>
> {
  const response = await axiosInstance.get("/missions/options/" + "POST");
  return response.data;
}

export async function getAllRewardOptions(
  minQuota?: number,
): Promise<ResponseType<SortOptionsRewardType[]>> {
  const response = await axiosInstance.get("/rewards/options", {
    params: {
      min_quota: minQuota,
    },
  });
  return response.data;
}
export async function getAllRewardCategoryOptions(): Promise<
  ResponseType<SortOptionsRewardType[]>
> {
  const response = await axiosInstance.get("/rewards/category/options");
  return response.data;
}

export async function getProvinceOptions(): Promise<
  ResponseType<SortOptionsType[]>
> {
  const response = await axiosInstance.get("/provinces/options");
  return response.data;
}
