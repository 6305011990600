<script setup lang="ts">
import { computed } from "vue";
// import { useRoute, useRouter } from "vue-router";
import { useRoute } from "vue-router";
// import { useLayoutStore } from "@/store/layout";

import { useGetMissionDetail as usegetMissionDetailEvent } from "@/hooks/mission-event";
import { useGetMissionDetail as usegetMissionDetailPost } from "@/hooks/mission-post";
import { useGetMissionDetail as useGetMissionDetailShare } from "@/hooks/mission-share";
import { useGetMissionDetail as useGetMissionDetailSurvey } from "@/hooks/mission-survey";
import { useGetMissionDetail as useGetMissionDetailPolling } from "@/hooks/mission-polling";
import { useGetMissionDetail as useGetMissionDetailCrowdsourcing } from "@/hooks/mission-crowdsourcing";
import { useGetMissionDetail as useGetMissionDetailReact } from "@/hooks/mission-react";
import { useGetMissionDetail as useGetMissionDetailWatch } from "@/hooks/mission-watch";

import LinkBack from "@/components/button/link-back.vue";

const route = useRoute();
// const router = useRouter();
// const layoutStore = useLayoutStore();

const { data: dataEvent } = usegetMissionDetailEvent(
  route.params.id as string,
  route.name === "mission.detail.event",
);

const { data: dataPost } = usegetMissionDetailPost(
  route.params.id as string,
  route.name === "mission.detail.post",
);

const { data: dataShare } = useGetMissionDetailShare(
  route.params.id as string,
  route.name === "mission.detail.share",
);

const { data: dataSurvey } = useGetMissionDetailSurvey(
  route.params.id as string,
  route.name === "mission.detail.survey",
);

const { data: dataPolling } = useGetMissionDetailPolling(
  route.params.id as string,
  route.name === "mission.detail.polling",
);

const { data: dataCrowdsourcing } = useGetMissionDetailCrowdsourcing(
  route.params.id as string,
  route.name === "mission.detail.crowdsourcing",
);

const { data: dataReact } = useGetMissionDetailReact(
  route.params.id as string,
  route.name === "mission.detail.react",
);

const { data: dataWatch } = useGetMissionDetailWatch(
  route.params.id as string,
  route.name === "mission.detail.watch",
);

const data = computed(() => {
  if (route.name === "mission.detail.event") {
    return dataEvent.value;
  } else if (route.name === "mission.detail.post") {
    return dataPost.value;
  } else if (route.name === "mission.detail.share") {
    return dataShare.value;
  } else if (route.name === "mission.detail.crowdsourcing") {
    return dataCrowdsourcing.value;
  } else if (route.name === "mission.detail.react") {
    return dataReact.value;
  } else if (route.name === "mission.detail.survey") {
    return dataSurvey.value;
  } else if (route.name === "mission.detail.polling") {
    return dataPolling.value;
  } else if (route.name === "mission.detail.watch") {
    return dataWatch.value;
  }
});

// const handleClick = () => {
//   router.push({
//     name: layoutStore.fromPageToMissionDetail,
//     params: {
//       programId: route.params.programId,
//     },
//   });
// };
</script>

<template>
  <nav
    class="flex h-[90px] w-full flex-row items-center justify-between overflow-hidden border-b border-primary_slate bg-white px-3 py-3 pr-7"
  >
    <div class="flex w-full flex-row items-center gap-4">
      <LinkBack class="mr-3" />

      <h1 class="title-routing">{{ data?.data?.title }}</h1>
    </div>
  </nav>
</template>
