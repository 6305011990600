<script setup lang="ts">
import { useRoute } from "vue-router";
import { useGetBadge } from "@/hooks/badge";

import LinkBack from "@/components/button/link-back.vue";
import Loading from "@/components/loading/index.vue";

const route = useRoute();
const { data, isLoading } = useGetBadge(
  route.params.programId as string,
  route.params.badgeId as string,
);
</script>

<template>
  <nav
    class="flex h-[90px] w-full flex-row items-center justify-between overflow-hidden border-b border-primary_slate bg-white px-3 py-3 pr-7"
  >
    <div class="flex w-full flex-row items-center gap-4">
      <LinkBack />
      <Loading v-if="isLoading" />
      <h1 v-else class="title-routing">{{ data?.data?.title }}</h1>
    </div>
  </nav>
</template>
