<script setup lang="ts">
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useGetMyCompany } from "@/hooks/company";
import { useAuthStore } from "@/store/auth-store";
import Icon from "@/components/icon/index.vue";
import Loading from "@/components/loading/index.vue";
import PoweredBy from "./powered-by.vue";

const authStore = useAuthStore();
const route = useRoute();

const enabled = computed(
  () => authStore.getCompanyId !== null && route.meta.superdashboard !== true,
);

const { data, isLoading, isFetching } = useGetMyCompany(
  authStore.getCompanyId!,
  enabled,
);
</script>

<template>
  <div
    v-if="authStore.level == 'SUPER'"
    class="absolute bottom-16 left-0 z-20 w-full"
  >
    <div class="mx-auto flex w-4/5 flex-col items-start gap-4">
      <div
        class="flex w-full flex-row gap-3 rounded-lg bg-primary_white px-4 py-3"
      >
        <Loading v-if="isLoading || isFetching" />
        <template v-else>
          <Icon icon="eva:monitor-fill" class="text-4xl text-primary_blur" />
          <div class="flex flex-col gap-1">
            <p class="text-[10px] font-bold text-primary_blur">Company Name</p>
            <h3>{{ data?.data?.name }}</h3>
          </div>
        </template>
      </div>

      <PoweredBy />
    </div>
  </div>
</template>
