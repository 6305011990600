<script setup lang="ts">
import { PropType } from "vue";
import Button from "./index.vue";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  value: String,
  checked: Boolean,
  inactiveClass: String,
  text: String,
  icon: String,
  class: {
    type: String,
    default: "",
  },
  iconClass: String,
  hidden: Boolean,
  positionIcon: {
    type: String as PropType<"left" | "right" | "rightFar" | "leftFar" | null>,
    default: "left",
  },
  notificationCount: {
    type: Number,
    default: 0,
  },
});

const { text, icon, iconClass } = props;

const emit = defineEmits(["update:click"]);
</script>

<template>
  <Button
    v-if="!hidden"
    size="sm"
    @click="emit('update:click', value)"
    :variant="checked ? 'secondary' : 'outline'"
    :class="props.class"
    :icon="icon"
    :icon-class="iconClass"
    :notification-count="notificationCount"
    :text="text"
    :position-icon="positionIcon"
  />
</template>
