<script setup lang="ts">
import Button from "@/components/button/index.vue";
import Dropdown from "@/components/dropdown/index.vue";
import Icon from "@/components/icon/index.vue";
import { cn } from "@/lib/utils";
import { DropdownMenuItem } from "radix-vue";
interface Props {
  isDelete?: boolean;
  isView?: boolean;
  isUpdate?: boolean;
  isReminder?: boolean;
  class?: string;
  textClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  isDelete: true,
  isView: true,
  isUpdate: true,
  isReminder: false,
  class: "min-w-[200px]",
  textClass: "text-xs",
});

const emits = defineEmits(["update:modelValue"]);
</script>

<template>
  <Dropdown :class="cn(props.class)">
    <Button
      v-if="!$slots.default"
      :icon="'eva:more-vertical-fill'"
      variant="iconOri"
    />
    <slot v-else />

    <template #content>
      <DropdownMenuItem
        v-if="isView"
        :class="
          cn(
            props.textClass,
            'flex w-full cursor-pointer flex-row items-center gap-3 rounded-lg p-2 duration-200 ease-linear hover:bg-primary_transparant_blue hover:text-primary_blue',
          )
        "
        @select="() => emits('update:modelValue', 'view')"
      >
        <Icon icon="eva:eye-fill" class="text-xl text-primary_blue" />
        View
      </DropdownMenuItem>
      <DropdownMenuItem
        v-if="isUpdate"
        :class="
          cn(
            props.textClass,
            'flex w-full cursor-pointer flex-row items-center gap-3 rounded-lg p-2 duration-200 ease-linear hover:bg-primary_transparant_blue hover:text-primary_blue',
          )
        "
        @select="() => emits('update:modelValue', 'update')"
      >
        <Icon icon="eva:edit-2-fill" class="text-xl text-primary_blue" />
        Edit
      </DropdownMenuItem>
      <DropdownMenuItem
        v-if="isDelete"
        :class="
          cn(
            props.textClass,
            'flex w-full cursor-pointer flex-row items-center gap-3 rounded-lg bg-primary_red/20 p-2 text-primary_red duration-200 ease-linear hover:bg-primary_red hover:text-white',
          )
        "
        @select="() => emits('update:modelValue', 'delete')"
      >
        Delete
      </DropdownMenuItem>
      <DropdownMenuItem
        v-if="isReminder"
        :class="
          cn(
            props.textClass,
            'flex w-full cursor-pointer flex-row items-center gap-3 rounded-lg p-2 duration-200 ease-linear hover:bg-primary_transparant_blue hover:text-primary_blue',
          )
        "
        @select="() => emits('update:modelValue', 'reminder')"
      >
        Reminder
      </DropdownMenuItem>
    </template>
  </Dropdown>
</template>
