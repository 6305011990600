export const MemberPages = {
  path: "/member",
  name: "member",
  children: [
    {
      path: "",
      name: "member.index",
      component: () => import("@/pages/member/index.vue"),
    },
    {
      path: "list",
      name: "member.list",
      component: () => import("@/pages/member/list.vue"),
    },
    {
      path: "detail/:id",
      name: "member.detail",
      component: () => import("@/pages/member/detail/index.vue"),
    },
    {
      path: "detail/:id/social/:social_id",
      name: "member.social",
      component: () => import("@/pages/member/social/index.vue"),
      meta: {
        header: false,
      },
    },
    {
      path: "group",
      name: "member.group",
      component: () => import("@/pages/member/group.vue"),
    },
  ],
  meta: {
    title: "Member",
  },
};

// {
//   path: "member",
//   name: "program.detail.member",
//   component: () => import("@/pages/program/detail/member.vue"),
//   meta: {
//     header: false,
//     programDetail: true,
//     sidebarProgram: true,
//   },
// },

export const MemberPagesInPrograms = {
  path: "member",
  name: "program.detail.member",
  children: [
    {
      path: "",
      name: "program.detail.member.index",
      component: () => import("@/pages/program/detail/member.vue"),
      meta: {
        programDetail: true,
        sidebarProgram: true,
        header: false,
      },
    },
    {
      path: "detail/:id",
      name: "program.detail.member.detail",
      component: () => import("@/pages/member/detail/index.vue"),
      meta: {
        programDetail: true,
        sidebarProgram: true,
      },
    },
    {
      path: "detail/:id/social/:social_id",
      name: "program.detail.member.detail.social",
      component: () => import("@/pages/member/social/index.vue"),
      meta: {
        header: false,
        programDetail: true,
        sidebarProgram: true,
      },
    },
  ],
  meta: {
    title: "Member",
  },
};
