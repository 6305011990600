export const SettingsPages = {
  path: "/setting",
  name: "setting",
  children: [
    {
      path: "",
      name: "setting.index",
      component: () => import("@/pages/setting/index.vue"),
    },
    {
      path: "admin",
      name: "setting.admin",
      component: () => import("@/pages/setting/admin.vue"),
    },
    {
      path: "role",
      name: "setting.role",
      component: () => import("@/pages/setting/role.vue"),
    },
    {
      path: "company",
      name: "setting.company",
      component: () => import("@/pages/setting/company.vue"),
    },
    {
      path: "activity-point",
      name: "setting.activity-point",
      component: () => import("@/pages/setting/activity-point.vue"),
    },
    {
      path: "add-on",
      name: "setting.add-on",
      component: () => import("@/pages/setting/add-on.vue"),
    },
  ],
  meta: {
    title: "Setting",
  },
};
