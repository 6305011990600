import axios from "axios";
import { useAuthStore } from "@/store/auth-store";
import { useToast } from "vue-toastification";
import router from "@/config/route";

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value: string | null) => void;
  reject: (reason?: any) => void;
}> = [];

const processQueue = (error: any, token: string | null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL + "/v1",
  timeout: 120000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();
    const token = authStore.getToken;
    const company_id = authStore.getCompanyId;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else if (config.method !== "post") {
      window.location.href = "/login";
    }

    if (company_id) {
      config.headers.set("X-App-Company", company_id);
    }

    const baseUrl = authStore.server.value;
    const parsedUrl = new URL(baseUrl);

    const domain = `${parsedUrl.protocol}//${parsedUrl.host}`;
    const mockDomains = ["https://mock.apidog.com", "http://127.0.0.1:3658"];

    config.baseURL = mockDomains.includes(domain) ? baseUrl : baseUrl + "/v1";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const authStore = useAuthStore();
    const toast = useToast();

    // Handle maintenance mode
    if (error.response?.status === 503) {
      window.location.href = "/maintenance";
      return Promise.reject(error);
    }

    // Handle permission errors
    if (error.response?.status === 403) {
      toast.error("You do not have permission to access this resource.");
      router.push("/");
      return Promise.reject(error);
    }

    // Handle unauthorized and implement token refresh
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        // If already refreshing, queue this request
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        authStore
          .refreshToken()
          .then((newToken) => {
            // Update the authorization header
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

            // Process queued requests
            processQueue(null, newToken!);

            // Resolve with the original request using the new token
            resolve(axiosInstance(originalRequest));
          })
          .catch((refreshError) => {
            console.log(refreshError);
            processQueue(refreshError, null);

            authStore.$reset();
            window.location.href = "/login";

            reject(refreshError);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    // Handle other errors
    if (error.response) {
      const message =
        error.response.status !== 500
          ? error.response.data?.message || "Something went wrong"
          : "Something went wrong";

      if (error.response.status !== 401 && !originalRequest._retry) {
        toast.error(message);
      }

      console.error(message);
    } else {
      toast.error("Network error. Please check your connection.");
      console.error("Network error:", error);
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
