import { Ref } from "vue";
import { useQuery } from "@tanstack/vue-query";

import {
  getMissionDetail,
  getMissionParticipant,
  getMissionSummary,
} from "@/services/mission-survey";
import { PageQueryType, ResponseType } from "@/types";
import {
  MissionSurvey,
  ParticipantSurvey,
  SummarySurvey,
} from "@/types/mission-survey";

export const useGetMissionDetail = (
  missionId: string,
  enabled: boolean = true,
) => {
  return useQuery<ResponseType<MissionSurvey>, Error>({
    queryKey: ["mission", "detail", "survey", missionId],
    queryFn: () => getMissionDetail(missionId),
    enabled,
  });
};

export const useGetMissionSummary = (missionId: string) => {
  return useQuery<ResponseType<SummarySurvey>, Error>({
    queryKey: ["mission", "detail", "survey", , missionId, "summary"],
    queryFn: () => getMissionSummary(missionId),
  });
};

export const useGetMissionParticipant = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<ParticipantSurvey[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "survey",
      missionId,
      "participant",
      pageQuery,
    ],
    queryFn: () => getMissionParticipant(missionId, pageQuery.value),
  });
};
