import { createI18n } from "vue-i18n";
import en from "../locales/en.json";
import id from "../locales/id.json";

const messages = {
  en: en,
  id: id,
};
const getLocaleFromParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get("lang") || "id";

  if (lang == "en_US" || lang == "en") {
    return "en";
  }

  if (lang == "id_ID" || lang == "id") {
    return "id";
  }

  return lang;
};

const i18n = createI18n({
  legacy: false,
  locale: getLocaleFromParams(),
  warnHtmlMessage: false,
  fallbackLocale: "id",
  messages: messages,
});

export default i18n;
