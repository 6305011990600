import {
  FilterVoucherCategory,
  FilterVoucherOperator,
  VoucherInBuyVoucher,
} from "@/types/reward";
import moment from "moment";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";

export type AddRewardMethod = "CUSTOM" | "BUY";

export type BuyVoucher = {
  id: string;
  code: string;
  title: string;
  banner_path: string;
  banner_file: string;
  description: string;
  price: number;
  quantity: number;
};

export type PaymentMethod = "QRIS" | "VA";

const defaultStartDate = moment()
  .set("hour", 0)
  .set("minute", 0)
  .format("YYYY-MM-DD HH:mm");

const defaultEndDate = moment()
  .set("hour", 23)
  .set("minute", 59)
  .add(6, "month")
  .format("YYYY-MM-DD HH:mm");

export const useCreateRewardStore = defineStore("create-reward", {
  state: () => ({
    dirty: false,
    loading: false,
    directToPayment: false,
    step: 0,
    period: 6 as number,
    invoice_url: null as string | null,
    stepFulfilled: {
      0: false,
      1: false,
      2: false,
    },
    maintenance: false,
    method: "" as AddRewardMethod | "",
    form: {
      custom: {
        title: "",
        quota: 0,
        description: "",
        terms: "",
        category: "",
        banner: {
          path: "/default/banner",
          file: "bg1.png",
          is_default: true,
        },
      },
      buy: {
        payment_method: "QRIS" as PaymentMethod,
        payment_total: 0,
        period_start: defaultStartDate,
        period_end: defaultEndDate,
        vouchers: [] as VoucherInBuyVoucher[],
        redirect_url: window.location.origin + "/reward",
      },
    },
    filter_custom: {
      category: "all" as FilterVoucherCategory,
      operator: "ALL" as FilterVoucherOperator,
      price_min: 1000,
      price_max: 50000,
    },
  }),
  getters: {
    getStep(state) {
      return state.step;
    },
    isXenditSection(state) {
      return state.step == 2 && state.invoice_url !== null;
    },
    getPriceFilterStart(state) {
      return state.filter_custom.price_min;
    },
    getPriceFilterEnd(state) {
      return state.filter_custom.price_max;
    },
    getErrorsStep0(state) {
      if (!state.dirty) return {};
      return {
        title: state.form.custom.title === "" ? "Title is required" : "",
        quota: state.form.custom.quota === 0 ? "Quota is required" : "",
        description:
          state.form.custom.description === "" ? "Description is required" : "",
        terms: state.form.custom.terms === "" ? "Terms is required" : "",
        category:
          state.form.custom.category === "" ? "Category is required" : "",
        banner:
          state.form.custom.banner.path === "" ||
          state.form.custom.banner.file === ""
            ? "Banner is required"
            : "",
      };
    },
    getQuantityAll(state) {
      return state.form.buy.vouchers.reduce((total, voucher) => {
        return total + voucher.quantity;
      }, 0);
    },
    getSelectedProduct(state) {
      return state.form.buy.vouchers.filter((voucher) => voucher.quantity > 0);
    },
    getTotalProductPrice(state) {
      return state.form.buy.vouchers.reduce((total, voucher) => {
        return total + voucher.price * voucher.quantity;
      }, 0);
    },
    getTaxProductPrice(state) {
      return (
        state.form.buy.vouchers.reduce((total, voucher) => {
          return total + voucher.price * voucher.quantity;
        }, 0) * 0.11
      );
    },
  },
  actions: {
    nextStep() {
      if (this.method == "CUSTOM") return;

      this.setDirty(true);

      switch (this.step) {
        case 0:
          this.validateStep0();
          break;
        case 1:
          this.validateStep1();
          break;
        case 2:
          this.validateStep2();
          break;
      }
    },

    backStep() {
      this.step -= 1;
    },

    validateStep0() {
      if (this.form.buy.vouchers.length == 0) {
        toast.error("Please select at least one voucher");
        this.stepFulfilled[0] = false;
        return;
      }

      this.step = 1;
      this.setDirty(false);
      this.stepFulfilled[0] = true;
    },
    validateStep1() {
      if (this.form.buy.vouchers.length == 0) {
        toast.error("Please select at least one voucher");
        this.stepFulfilled[1] = false;
        this.step = 0;
        return;
      }

      this.step = 2;
      this.stepFulfilled[1] = true;
      this.setDirty(false);
    },
    validateStep2() {},
    setDirty(value: boolean) {
      this.dirty = value;
    },
    setPaymentMethod(value: PaymentMethod) {
      this.form.buy.payment_method = value;
    },
    setLoading(value: boolean) {
      this.loading = value;
    },
    setMethod(value: AddRewardMethod) {
      if (this.method === value) {
        this.method = "";
        return;
      }
      this.method = value;
    },
    resetBanner() {
      this.form.custom.banner.path = "/default/banner";
      this.form.custom.banner.file = "bg1.png";
      this.form.custom.banner.is_default = true;
    },
    setPriceFilter({
      price_start,
      price_end,
    }: {
      price_start: number;
      price_end: number;
    }) {
      if (this.loading) return;
      if (this.filter_custom.price_min === price_start) {
        this.filter_custom.price_min = 0;
      } else {
        this.filter_custom.price_min = price_start;
      }

      if (this.filter_custom.price_max === price_end) {
        this.filter_custom.price_max = 0;
      } else {
        this.filter_custom.price_max = price_end;
      }
    },
    setCategoryFilter(category: FilterVoucherCategory) {
      this.filter_custom.category = category;
      this.filter_custom.operator = "ALL";
    },
    setOperatorFilter(operator: FilterVoucherOperator) {
      this.filter_custom.operator = operator;
    },
    checkProductSelected(id: string) {
      return this.form.buy.vouchers.some((voucher) => voucher.id === id);
    },

    addProduct(voucher: VoucherInBuyVoucher) {
      let voucherWithQuantity = {
        ...voucher,
        quantity: 1,
      };
      this.form.buy.vouchers.push(voucherWithQuantity);
    },
    setPeriod(period: number) {
      this.period = period;

      this.form.buy.period_end = moment(this.form.buy.period_start)
        .set("hour", 23)
        .set("minute", 59)
        .add(period, "month")
        .format("YYYY-MM-DD HH:mm");
    },
    removeProduct(id: string) {
      this.form.buy.vouchers = this.form.buy.vouchers.filter(
        (voucher) => voucher.id !== id,
      );
    },
    decreaseQuantity(id: string) {
      this.form.buy.vouchers = this.form.buy.vouchers.filter((voucher) => {
        if (voucher.id === id) {
          voucher.quantity -= 1;
          return voucher.quantity > 0;
        }
        return true;
      });
    },
    increaseQuantity(id: string) {
      this.form.buy.vouchers = this.form.buy.vouchers.map((voucher) => {
        if (voucher.id === id) voucher.quantity += 1;
        return voucher;
      });
    },
  },
  // persist: true,
});
