import "./assets/styles/index.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./config/route";
import { createPinia, PiniaVuePlugin } from "pinia";
import { VueQueryPlugin } from "@tanstack/vue-query";
import vueQueryPluginOptions from "./config/queryClient";
import "vue3-toastify/dist/index.css";

import Card from "./components/card/index.vue";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";

import { CkeditorPlugin } from "@ckeditor/ckeditor5-vue";

import * as Sentry from "@sentry/vue";
import { replayIntegration } from "@sentry/browser";

import "../node_modules/nprogress/nprogress.css";
import Skeleton from "@/components/skeleton/index.vue";
import i18n from "./config/i18n";

import simplebar from "simplebar-vue";
import "simplebar-vue/dist/simplebar.min.css";

const app = createApp(App);

app.component("simplebar", simplebar);

const options: PluginOptions = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 5,
  newestOnTop: true,
};

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    // browserTracingIntegration({
    //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   // tracingOrigins: ["localhost"],
    // }),
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(Toast, options);
app.use(i18n);

app.use(VueQueryPlugin, vueQueryPluginOptions);
app.component("skeleton", Skeleton);
app.component("Card", Card);
app.use(CkeditorPlugin);
const pinia = createPinia();
app.use(VueSweetalert2);
pinia.use(piniaPluginPersistedstate);
app.use(router);
app.use(pinia);
app.use(PiniaVuePlugin);

app.mount("#app");
