<script lang="ts" setup>
import { useLayoutStore } from "@/store/layout";
import Link from "@/components/button/link-pinia.vue";
import { MemberDetailPage } from "@/types/member";
import { computed, ComputedRef } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const link: ComputedRef<
  {
    value: MemberDetailPage;
    text: string;
    icon: string;
    hidden: boolean;
  }[]
> = computed(() => [
  {
    value: "overview",
    text: "Overview",
    icon: "eva:map-outline",
    hidden: false,
  },
  {
    value: "history",
    text: "Mission History",
    icon: "eva:folder-outline",
    hidden: route.name === "member.detail",
  },
  {
    value: "program-history",
    text: "Program History",
    icon: "eva:folder-outline",
    hidden: route.name !== "member.detail",
  },
  {
    value: "benefits",
    text: "Collected Benefits",
    icon: "eva:gift-outline",
    hidden: false,
  },
  {
    value: "content-library",
    text: "Content Library",
    icon: "eva:grid-outline",
    hidden: false,
  },
  {
    value: "point-history",
    text: "Point History",
    icon: "eva:radio-button-on-fill",
    hidden: route.name === "member.detail",
  },
]);

const layoutStore = useLayoutStore();
</script>

<template>
  <div class="flex flex-row items-center gap-3 overflow-x-auto">
    <Link
      v-for="link in link"
      :value="link.value"
      :hidden="link.hidden"
      :text="link.text"
      :icon="link.icon"
      :checked="layoutStore.getMemberPage === link.value"
      @update:click="layoutStore.setMemberPage"
    />
  </div>
</template>
