export const BalancePages = {
  path: "/balance",
  name: "balance",
  children: [
    {
      path: "",
      name: "balance.index",
      component: () => import("@/pages/balance/index.vue"),
    },
    {
      path: "budget-mission",
      name: "balance.budget-mission",
      component: () => import("@/pages/balance/budget-mission.vue"),
    },
    {
      path: "withdrawal",
      name: "balance.withdrawal",
      component: () => import("@/pages/balance/withdrawal.vue"),
    },
  ],
  meta: {
    title: "balance",
  },
};
