import axiosInstance from "@/lib/axiosInstance";
// import { delay, fakeReturn } from "@/lib/debug";
import { PageQueryType, ResponseType } from "@/types";
import { MissionSubType, ProofCrowdsourcing } from "@/types/mission";
import {
  ApprovalDataCrowdsourcing,
  MissionCrowdsourcing,
  MissionCrowdsourcingAnalytics,
  ParticipantCrowdsourcing,
  SubmissionCrowdsourcingHistories,
  SummaryCrowdsourcing,
} from "@/types/mission-crowdsourcing";
import moment from "moment";
import nProgress from "nprogress";

export async function getMissionDetail(
  MissionId: string,
): Promise<ResponseType<MissionCrowdsourcing>> {
  const response = await axiosInstance.get(`/missions/${MissionId}/article`);
  return response.data;
}

export async function getMissionSummary(
  MissionId: string,
): Promise<ResponseType<SummaryCrowdsourcing>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/article/summary`,
  );
  return response.data;
}

export async function getMissionContentLibrary(
  pageQuery: PageQueryType<string>,
  missionId: string,
): Promise<ResponseType<ProofCrowdsourcing[]>> {
  const params = {
    ...pageQuery,
    mission_id: missionId,
  };
  const response = await axiosInstance.get(`/contents`, {
    params: params,
  });

  return response.data;
}

export async function getMissionAnalytics(
  missionId: string,
  social?: MissionSubType,
): Promise<ResponseType<MissionCrowdsourcingAnalytics>> {
  const response = await axiosInstance.get(
    `/missions/${missionId}/article/analysis`,
    {
      params: {
        social,
      },
    },
  );

  return response.data;
}

export async function getMissionParticipant(
  MissionId: string,
  PageQuery: PageQueryType,
  social?: MissionSubType,
): Promise<ResponseType<ParticipantCrowdsourcing[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/article/participants`,
    {
      params: {
        ...PageQuery,
        social,
      },
    },
  );
  return response.data;
}

export async function getMissionSubmission(
  MissionId: string,
  PageQuery: PageQueryType,
): Promise<ResponseType<SubmissionCrowdsourcingHistories[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/article/histories`,
    {
      params: PageQuery,
    },
  );
  return response.data;
}

export async function getApproval(
  missionId: string,
): Promise<ResponseType<ApprovalDataCrowdsourcing>> {
  const response = await axiosInstance.get(
    `/missions/${missionId}/article/approvals`,
  );
  return response.data;
}

export async function changeStatusSubmissionCrowdsourcing({
  t_mission_id,
  status,
  message,
  attachment,
}: {
  t_mission_id: string;
  status: "APPROVED" | "REJECTED" | "REVIEW" | "REVISION";
  message?: string;
  attachment?: File;
}): Promise<ResponseType> {
  const form = new FormData();
  form.append("message", message || "");
  form.append("status", status);
  if (attachment) {
    form.append("attachment", attachment);
  }
  let url = `/missions/article/approvals/${t_mission_id}`;
  const response = await axiosInstance.post(url, form);
  return response.data;
}

export async function reminderMissionArticle(
  tMissionArticleId: string,
): Promise<ResponseType> {
  const response = await axiosInstance.post(
    `/missions/article/reminder/${tMissionArticleId}`,
  );
  return response.data;
}

export function downloadReport({
  missionId,
  name,
}: {
  missionId: string;
  name: string;
}): void {
  nProgress.start();
  axiosInstance
    .get(`/missions/${missionId}/article/report`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment().format("DDMMYY")}_crowdsourcing_report_${name}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}

// export async function getApprovalEvent(
//   missionId: string,
// ): Promise<ResponseType<ApprovalDataEvent[]>> {
//   const response = await axiosInstance.get(
//     `/missions/${missionId}/event/approvals`,
//   );
//   return response.data;
// }
