<template>
  <div :class="cn('w-full space-y-1', disabled && 'cursor-not-allowed')">
    <label
      v-if="label"
      :class="cn(labelClass, normalizeErrors(errors).length && 'text-red-400')"
    >
      {{ label }} <span v-if="mandatory" class="text-red-500">*</span>
    </label>
    <p
      v-if="subLabel"
      :class="
        cn(subLabelClass, normalizeErrors(errors).length && 'text-red-400')
      "
    >
      {{ subLabel }}
    </p>

    <VueSelect
      v-model="selected"
      :options="options"
      :is-multi="isMulti"
      :style="selectStyle"
      :is-disabled="disabled"
      :is-clearable="isClearable"
    />
    <p v-if="normalizeErrors(errors).length" class="text-xs text-red-400">
      {{ normalizeErrors(errors)[0] }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { cn } from "@/lib/utils";
import { SortOptionsType } from "@/types/components";
import { computed } from "vue";
import VueSelect from "vue3-select-component";

interface Props {
  label?: string;
  subLabel?: string;
  subLabelClass?: string;
  labelClass?: string;
  options: SortOptionsType<string | number>[];
  isMulti?: boolean;
  mandatory?: boolean;
  //   isSearchable?: boolean;
  errors?: string[] | string;
  disabled?: boolean;
  isClearable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isMulti: false,
  disabled: false,
  mandatory: false,
  //   isSearchable: false,
  labelClass: "text-xs font-bold capitalize",
  subLabelClass: "block text-gray-700 text-[10px]",
  isClearable: true,
});

const normalizeErrors = (errors: Props["errors"]) => {
  if (Array.isArray(errors)) {
    return errors;
  } else if (typeof errors === "string") {
    if (errors === "") return [];
    return [errors];
  }
  return [];
};

const selected = defineModel<string | string[] | number | number[]>();

const selectStyle = computed(() => {
  const hasErrors = normalizeErrors(props.errors).length > 0;
  return {
    "--vs-border": hasErrors ? "1px solid #f87171" : "1px solid #E1E3E6",
    // "--vs-text-color": hasErrors ? "#f87171" : "#3d3d3d",
  };
});
</script>

<style scoped>
:deep(.vue-select) {
  --vs-input-bg: #fff;
  --vs-input-outline: #3b82f6;
  --vs-input-placeholder-color: #52525b;
  --vs-padding: 12px 16px;

  /* --vs-border: ; */

  --vs-font-size: 12px;
  --vs-font-weight: 400;
  --vs-font-family: inherit;
  --vs-border-radius: 8px;

  /* --vs-text-color: #3d3d3d; */
  --vs-line-height: 1.5;
  --vs-menu-offset-top: 8px;
  --vs-menu-height: 200px;
  --vs-menu-padding: 0;
  --vs-menu-border: 1px solid #e4e4e7;
  --vs-menu-bg: #fff;
  --vs-menu-box-shadow: none;
  --vs-menu-z-index: 2;
  --vs-option-padding: 8px 12px;
  --vs-option-font-size: var(--vs-font-size);
  --vs-option-font-weight: var(--vs-font-weight);
  --vs-option-text-color: var(--vs-text-color);
  --vs-option-bg: var(--vs-menu-bg);
  --vs-option-hover-color: #dbeafe;
  --vs-option-focused-color: var(--vs-option-hover-color);
  --vs-option-selected-color: #93c5fd;
  --vs-option-disabled-color: #f4f4f5;
  --vs-option-disabled-text-color: #52525b;
  --vs-multi-value-gap: 0px;
  --vs-multi-value-padding: 4px;
  --vs-multi-value-margin: 4px 0px 4px 6px;
  --vs-multi-value-font-size: 12px;
  --vs-multi-value-font-weight: 400;
  --vs-multi-value-line-height: 1;
  --vs-multi-value-text-color: #3d3d3d;
  --vs-multi-value-bg: #f6f6f6;
  --vs-multi-value-xmark-size: 16px;
  --vs-multi-value-xmark-color: var(--vs-multi-value-text-color);
  --vs-indicators-gap: 4px;
  --vs-icon-size: 20px;
  --vs-icon-color: var(--vs-text-color);
  --vs-spinner-color: var(--vs-text-color);
  --vs-spinner-size: 20px;
  --vs-dropdown-transition: transform 0.25s ease-out;
}
</style>
