import { FilterDateType } from "@/types";
import { MemberDetailPage } from "@/types/member";
import { MissionDetailPage } from "@/types/mission";
import { ProgramDetailPage } from "@/types/program";
import { RewardDetailPage } from "@/types/reward";
import moment from "moment";
import { defineStore } from "pinia";

const start_date = moment().subtract(7, "days").toDate();
const end_date = moment().toDate();

export const useLayoutStore = defineStore("layout-store", {
  state: () => ({
    title: "Dashboard",
    // fromPageToMissionDetail: "program.detail.mission.index" as string,
    MemberDetailPage: "overview" as MemberDetailPage,
    RewardDetailPage: "index" as RewardDetailPage,
    ProgramDetailPage: "index" as ProgramDetailPage,
    MissionDetailPage: "participants" as MissionDetailPage,
    start_date: start_date as Date | undefined,
    end_date: end_date as Date | undefined,
    label_date: "Last 7 Days" as string | null,
    isSidebarOpen: false,
    innerWidth: window.innerWidth,
  }),
  getters: {
    getTitle(state) {
      return state?.title;
    },
    getMemberPage(state) {
      return state?.MemberDetailPage;
    },
    getRewardPage(state) {
      return state?.RewardDetailPage;
    },
    getMissionDetailPage(state) {
      return state?.MissionDetailPage;
    },
    getProgramDetailPage(state) {
      return state?.ProgramDetailPage;
    },
    getLabelDate(state) {
      return state?.label_date;
    },
    getStartDate(state) {
      return state?.start_date;
    },
    getEndDate(state) {
      return state?.end_date;
    },
    getDate(state): FilterDateType {
      return {
        start_date: state.start_date,
        end_date: state.end_date,
      };
    },
    isMobile(state) {
      return state.innerWidth < 1024;
    },
  },
  actions: {
    setTitle(title: string) {
      this.title = title;
    },
    setMemberPage(page: MemberDetailPage) {
      this.MemberDetailPage = page;
    },
    setMissionDetailPage(page: MissionDetailPage) {
      this.MissionDetailPage = page;
    },
    setRewardDetailPage(page: RewardDetailPage) {
      this.RewardDetailPage = page;
    },
    setProgramDetailPage(page: ProgramDetailPage) {
      this.ProgramDetailPage = page;
    },
    setLabelDate(label: string) {
      this.label_date = label;
    },
    // setfromPageToMissionDetail(page: string) {
    //   this.fromPageToMissionDetail = page;
    // },
    setDate(start_date: Date | undefined, end_date: Date | undefined) {
      this.start_date = start_date;
      this.end_date = end_date;
    },
    setIsSidebarOpen(isOpen: boolean) {
      this.isSidebarOpen = isOpen;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
  persist: true,
});
