// import { Ref } from "vue";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/vue-query";

import {
  getMemberAchievementSummary,
  getMemberAreas,
  getMemberConnection,
  getMemberContentLibrary,
  getMemberDailyLogin,
  getMemberDetail,
  getMemberList,
  getMemberMissionHistory,
  getMemberPointHistory,
  getPostPerformanceType,
  getMemberProgramSummary,
  getMemberQuota,
  getReactPerformanceType,
  getMemberReward,
  getMembersActivity,
  getMemberSocialMediaConnected,
  getSocialMediaSummary,
  getSocialMediaPosts,
  getMemberGrowth,
  CreateMember,
  UpdateMember,
  deleteMember,
  changeMemberStatus,
  getPreviewBulkMember,
  bulkReminderMember,
  getMemberProgramHistory,
  getBadgeMemberInProgram,
} from "@/services/member";
import { PageQueryType, ResponseType } from "@/types";
import {
  Member,
  MemberAchievement,
  MemberActivity,
  MemberArea,
  MemberConnection,
  MemberDailyLogin,
  MemberDetail,
  MemberMissionHistory,
  MemberPointHistory,
  PostPerformanceType,
  MemberQuota,
  MemberRanks,
  ReactPerformanceType,
  MemberReward,
  MemberSocials,
  SocialSummary,
  SocialPosts,
  MemberGrowth,
  MemberProgramHistory,
  BadgeMemberInProgram,
} from "@/types/member";
import { ref, Ref } from "vue";
import { Mission, ProofPost } from "@/types/mission";
import { useMutationResources } from "./toast-query-client";
import { UpdateMemberValues } from "@/constants/form/member";
import { useRoute } from "vue-router";
import { getCompanyId } from "@/composables/get-company-id";

export const useGetMemberQuota = () => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<MemberQuota>, Error>({
    queryKey: ["member", "quota", authCompany],
    queryFn: () => getMemberQuota(),
  });
};

export const useGetMemberGrowth = (year: Ref<number>) => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<MemberGrowth[]>, Error>({
    queryKey: ["member", "growth", authCompany, year],
    queryFn: () => getMemberGrowth(year.value),
  });
};

export const useGetBadgeMemberInProgram = (id: string) => {
  const route = useRoute();
  return useQuery<ResponseType<BadgeMemberInProgram[]>, Error>({
    queryKey: ["member", "program", route.params.programId, "badge", id],
    queryFn: () => getBadgeMemberInProgram(id),
  });
};

export const useGetMembersActivity = () => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<MemberActivity>, Error>({
    queryKey: ["member", "activity", authCompany],
    queryFn: () => getMembersActivity(),
  });
};

export const useGetMembersAreas = () => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<MemberArea[]>, Error>({
    queryKey: ["member", "areas", authCompany],
    queryFn: () => getMemberAreas(),
  });
};

export const useGetMemberConnection = () => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<MemberConnection>, Error>({
    queryKey: ["member", "connection", authCompany],
    queryFn: () => getMemberConnection(),
  });
};

export const useGetMemberLists = (PageQuery: Ref<PageQueryType>) => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<Member[]>, Error>({
    queryKey: ["member", "list", PageQuery, authCompany],
    queryFn: () => getMemberList(PageQuery.value),
    refetchOnMount: true,
  });
};

export const useChangeMemberStatus = () => {
  const { queryClient, toast } = useMutationResources();
  return useMutation({
    mutationFn: changeMemberStatus,
    onSuccess: async () => {
      const queryKey = ["member", "list"];
      queryClient.invalidateQueries({ queryKey: queryKey });
      toast.success("Member status updated successfully");
    },
  });
};
export const useGetMemberListsInfiniteScroll = (
  pageQuery: Ref<PageQueryType>,
  is_pro?: Ref<boolean>,
  unused_in?: Ref<string>,
) => {
  const route = useRoute();
  const authCompany = getCompanyId();
  return useInfiniteQuery<ResponseType<Member[]>, Error>({
    queryKey: [
      "member",
      "list",
      authCompany,
      route.params?.programId,
      pageQuery,
      is_pro,
      unused_in,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getMemberList(
        {
          ...pageQuery.value,
          page: pageNumber,
        },
        is_pro?.value,
        unused_in?.value,
      );
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: pageQuery.value.page as number | undefined,
    staleTime: 0,
  });
};

export const useGetMemberDetail = (
  id: string,
  enabled: Ref<boolean> = ref(true),
) => {
  return useQuery<ResponseType<MemberDetail>, Error>({
    queryKey: ["member", id, "detail"],
    queryFn: () => getMemberDetail(id),
    enabled,
  });
};

export const useGetReactPerformanceType = (id: string, month: Ref<string>) => {
  return useQuery<ResponseType<ReactPerformanceType>, Error>({
    queryKey: ["member", id, "performance", "react", month],
    queryFn: () => getReactPerformanceType(id, month.value),
  });
};

export const useGetPostPerformanceType = (id: string, month: Ref<string>) => {
  return useQuery<ResponseType<PostPerformanceType>, Error>({
    queryKey: ["member", id, "performance", "post", month],
    queryFn: () => getPostPerformanceType(id, month.value),
  });
};

export const useGetMemberSummaryAchievement = (id: string) => {
  return useQuery<ResponseType<MemberAchievement>, Error>({
    queryKey: ["member", id, "achievement"],
    queryFn: () => getMemberAchievementSummary(id),
  });
};

export const useGetMemberProgramSummary = (
  id: string,
  programId: Ref<string>,
) => {
  return useQuery<ResponseType<MemberRanks>, Error>({
    queryKey: ["member", id, "program", programId, "rank"],
    queryFn: () => getMemberProgramSummary(id, programId.value),
  });
};

export const useGetMemberDailyLogin = (id: string, month: Ref<string>) => {
  return useQuery<ResponseType<MemberDailyLogin[]>, Error>({
    queryKey: ["member", id, "daily-login", month],
    queryFn: () => getMemberDailyLogin(id, month.value),
  });
};

export const useGetMemberSocialMediaConnected = (id: string) => {
  return useQuery<ResponseType<MemberSocials[]>, Error>({
    queryKey: ["member", id, "socials"],
    queryFn: () => getMemberSocialMediaConnected(id),
  });
};

export const useGetMemberReward = (
  id: string,
  PageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<MemberReward[]>, Error>({
    queryKey: ["member", id, "reward", PageQuery.value],
    queryFn: () => getMemberReward(id, PageQuery.value),
  });
};

export const useGetMemberPointHistory = (
  id: string,
  PageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<MemberPointHistory[]>, Error>({
    queryKey: ["member", id, "point-history", PageQuery.value],
    queryFn: () => getMemberPointHistory(id, PageQuery.value),
  });
};

export const useGetMemberMissionHistory = (
  id: string,
  PageQuery: Ref<PageQueryType<Mission>>,
) => {
  return useQuery<ResponseType<MemberMissionHistory[]>, Error>({
    queryKey: ["member", id, "mission-history", PageQuery.value],
    queryFn: () => getMemberMissionHistory(id, PageQuery.value),
  });
};

export const useGetMemberProgramHistory = (
  id: string,
  PageQuery: Ref<PageQueryType>,
) => {
  return useInfiniteQuery<ResponseType<MemberProgramHistory[]>, Error>({
    queryKey: ["member", id, "program-history", PageQuery],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getMemberProgramHistory(id, {
        ...PageQuery.value,
        page: pageNumber,
      });
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: PageQuery.value.page as number | undefined,
  });
};

export const useGetMemberContentLibrary = (
  PageQuery: Ref<PageQueryType<string>>,
) => {
  return useQuery<ResponseType<ProofPost[]>, Error>({
    queryKey: [
      "member",
      PageQuery.value.additionalQuery, // as id member
      "content-library",
      PageQuery.value,
    ],
    queryFn: () => getMemberContentLibrary(PageQuery.value),
  });
};

export const useGetSocialMediaSummary = (socialId: string) => {
  return useQuery<ResponseType<SocialSummary>, Error>({
    queryKey: ["social", socialId, "summary"],
    queryFn: () => getSocialMediaSummary(socialId),
  });
};

export const useGetSocialMediaPosts = (socialId: string) => {
  return useQuery<ResponseType<SocialPosts[]>, Error>({
    queryKey: ["social", socialId, "posts"],
    queryFn: () => getSocialMediaPosts(socialId),
  });
};

export const useCreateMember = () => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: CreateMember,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["member"] });
      toast.success("Member added successfully");
    },
    onError: () => {
      console.error("Error adding item");
    },
  });
};

export const useUpdateMember = (memberId: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: (data: UpdateMemberValues) => UpdateMember(memberId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["member"] });
      toast.success("Member updated successfully");
    },
    onError: () => {
      console.error("Error updating item");
    },
  });
};

export const useDeleteMember = () => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: (ids: Array<string>) => deleteMember(ids),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["member", "list"] });
      queryClient.invalidateQueries({ queryKey: ["member", "quota"] });
      toast.success("Member deleted successfully");
    },
    onError: () => {
      console.error("Error Deleting item");
    },
  });
};

export const useGetPreviewBulkMember = () => {
  return useMutation({
    mutationFn: getPreviewBulkMember,
    onError: () => {
      console.error("Error Deleting item");
    },
  });
};

export const useBulkReminderMember = (endpoint: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: (data: Array<string>) => bulkReminderMember(data, endpoint),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["member"] });
      toast.success("Member reminder successfully");
    },
    onError: () => {
      console.error("Error Deleting item");
    },
  });
};
