<script setup lang="ts">
import axios from "axios";
import { onMounted, watch } from "vue";
import { useQuery } from "@tanstack/vue-query";

import MaintenanceImage from "@/assets/images/maintenance.svg";
import MaintenanceIcons from "@/assets/images/tools.png";
import { useAuthStore } from "@/store/auth-store";
// import { watch } from "v";

onMounted(() => {
  document.title = "Maintenance";
  const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
  if (favicon) {
    favicon.href = MaintenanceIcons;
  }
});

const authStore = useAuthStore();

const fetchMaintenanceStatus = async () => {
  try {
    let baseUrl = authStore.server.value;
    const parsedUrl = new URL(baseUrl);

    const domain = `${parsedUrl.protocol}//${parsedUrl.host}`;
    const mockDomains = ["https://mock.apidog.com", "http://127.0.0.1:3658"];

    if (baseUrl.includes("/be")) {
      baseUrl = baseUrl.replace("/be", "");
    }

    axios.defaults.baseURL = mockDomains.includes(domain) ? baseUrl : baseUrl;

    const response = await axios.get("/healthz.php");
    if (response.status == 200) {
      window.location.href = "/login";
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

const { isError, isLoading, isSuccess } = useQuery({
  queryKey: ["maintenanceStatus"],
  queryFn: fetchMaintenanceStatus,
  retry: false,
  refetchOnWindowFocus: false,
});

watch(
  () => isSuccess.value,
  () => {
    if (isSuccess.value) {
      // window.location.href = "/login";
    }
  },
);
</script>

<template>
  <div class="h-screen w-full py-48">
    <div class="mx-auto my-auto flex max-w-2xl flex-col items-center gap-3">
      <h1 class="text-5xl text-primary_gray">Maintenance</h1>
      <component :is="MaintenanceImage" />

      <div v-if="isLoading" class="text-center text-lg text-primary_black">
        Memeriksa status server...
      </div>

      <p v-else class="text-center text-lg text-primary_black">
        Haii! Kami sedang melakukan perawatan pada aplikasi kami. Jadi, kami
        mungkin tidak tersedia untuk sementara. Tapi, jangan khawatir, kami
        sedang bekerja keras untuk memberikan pengalaman yang lebih baik bagi
        kalian semua! Terima kasih sudah bersabar ya! 😊✨
      </p>

      <div v-if="isError" class="text-center text-red-500">
        Terjadi kesalahan saat memeriksa status server.
      </div>
    </div>
  </div>
</template>
