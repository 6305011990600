export const GroupPages = {
  path: "/group/detail/:id",
  name: "group.detail",
  children: [
    {
      path: "",
      name: "group.detail.index",
      component: () => import("@/pages/group/detail.vue"),
      meta: {
        header: false,
      },
    },
    {
      path: "member",
      name: "group.detail.member",
      component: () => import("@/pages/group/detail-member.vue"),
    },
  ],
};
