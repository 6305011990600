<script lang="ts" setup>
import { useAttrs } from "vue";
import {
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from "radix-vue";
import Icon from "@/components/icon/index.vue";
import type { HTMLAttributes } from "vue";
import { VisuallyHidden } from "radix-vue";
import { cn } from "@/lib/utils";

interface Props {
  title?: string;
  titleClass?: HTMLAttributes["class"];
  separateBottom?: boolean;
  modalClass?: HTMLAttributes["class"];
}

const props = withDefaults(defineProps<Props>(), {
  title: "",
  titleClass: "text-xl font-bold text-primary_black",
  separateBottom: false,
  modalClass: "",
});
const modalOpen = defineModel<boolean | undefined>();
const attrs = useAttrs();

const emit = defineEmits(["close"]);
const handleOpenChange = (open: boolean) => {
  modalOpen.value = open;
  if (!open) {
    emit("close");
  }
};
</script>

<template>
  <div v-bind="attrs">
    <DialogRoot :open="modalOpen" @update:open="handleOpenChange">
      <DialogTrigger v-if="$slots.trigger">
        <slot name="trigger" />
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay
          class="data-[state=open]:animate-overlayShow fixed inset-0 z-[60] bg-primary_gray/60"
        />

        <DialogContent
          :class="
            cn(
              'fixed left-[50%] top-[50%] z-[70] max-h-fit w-[90vw] max-w-[620px] translate-x-[-50%] translate-y-[-50%] space-y-3 overflow-visible rounded-xl bg-white p-3 font-DmSans focus:outline-none',
              modalClass,
            )
          "
        >
          <div
            v-if="!$slots.header"
            :class="
              cn(
                'flex w-full items-center justify-between',
                separateBottom && 'border-b border-primary_slate pb-3',
              )
            "
          >
            <DialogTitle as="h1" :class="props.titleClass">{{
              props.title
            }}</DialogTitle>
            <DialogClose>
              <Icon
                class="text-[20px] text-primary_gray duration-200 ease-linear hover:text-primary_gray/50"
                icon="eva:close-circle-fill"
              />
            </DialogClose>
          </div>
          <div
            v-else
            :class="
              cn(
                'flex flex-row items-center justify-between',
                separateBottom && 'border-b border-primary_slate pb-3',
              )
            "
          >
            <VisuallyHidden asChild>
              <DialogTitle as="h1" :class="props.titleClass">{{
                props.title
              }}</DialogTitle>
            </VisuallyHidden>
            <slot name="header" />
            <DialogClose>
              <Icon
                class="text-[20px] text-primary_gray duration-200 ease-linear hover:text-primary_gray/50"
                icon="eva:close-circle-fill"
              />
            </DialogClose>
          </div>
          <slot />

          <slot v-if="$slots.footer" name="footer" />
        </DialogContent>
        <!-- </transition> -->
      </DialogPortal>
    </DialogRoot>
  </div>
</template>
