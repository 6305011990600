export const MissionPages = {
  path: "/mission",
  name: "mission",
  children: [
    {
      path: "",
      name: "mission.index",
      component: () => import("@/pages/mission/index.vue"),
    },
    {
      path: "create",
      name: "mission.create",
      component: () => import("@/pages/mission/create/index.vue"),
      meta: { header: false, sidebar: false, footer: true },
    },
    {
      path: "need-verification",
      name: "mission.need-verification",
      component: () => import("@/pages/mission/need-verification.vue"),
    },
    {
      path: "content-proposal",
      name: "mission.content-proposal",
      component: () => import("@/pages/mission/content-proposal.vue"),
    },
    {
      path: "all-mission-history",
      name: "mission.all-mission-history",
      component: () => import("@/pages/mission/all-mission-history.vue"),
    },
  ],
  meta: {
    title: "Mission",
  },
};
