import { ref, Ref } from "vue";
import { useMutation, useQuery } from "@tanstack/vue-query";

import { PageQueryType, ResponseType } from "@/types";
import {
  ApprovalPostResponse,
  MissionPost,
  MissionPostAnalytics,
  ParticipantPost,
  PostType,
  SubmissionPostHistories,
  SummaryPost,
} from "@/types/mission-post";
import {
  changeStatusSubmissionPost,
  getApprovalPost,
  getMissionAnalytics,
  getMissionContentLibrary,
  getMissionDetail,
  getMissionParticipant,
  getMissionSubmission,
  getMissionSummary,
  reminderMissionPost,
} from "@/services/mission-post";
import { MissionSubType, ProofPost } from "@/types/mission";
import { useMutationResources } from "./toast-query-client";
import { SubmissionShare } from "@/types/proposal";

export const useGetMissionDetail = (
  missionId: string,
  enabled: boolean = true,
) => {
  return useQuery<ResponseType<MissionPost>, Error>({
    queryKey: ["mission", "detail", "post", missionId],
    queryFn: () => getMissionDetail(missionId),
    enabled,
  });
};

export const useGetMissionAnalytics = (
  missionId: string,
  social?: Ref<MissionSubType> | undefined,
) => {
  return useQuery<ResponseType<MissionPostAnalytics>, Error>({
    queryKey: ["mission", "detail", "post", missionId, "analytics", social],
    queryFn: () => getMissionAnalytics(missionId, social?.value),
    placeholderData: (prev) => prev,
  });
};

export const useGetMissionSummary = (missionId: string) => {
  return useQuery<ResponseType<SummaryPost>, Error>({
    queryKey: ["mission", "detail", "event", , missionId, "summary"],
    queryFn: () => getMissionSummary(missionId),
  });
};

export const useGetMissionParticipant = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
  social?: Ref<MissionSubType>,
) => {
  return useQuery<ResponseType<ParticipantPost[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "post",
      missionId,
      "participant",
      pageQuery,
      social,
    ],
    queryFn: () =>
      getMissionParticipant(missionId, pageQuery.value, social?.value),
  });
};

export const useGetMissionContentLibrary = (
  pageQuery: Ref<PageQueryType<string>>,
  missionId: string,
) => {
  return useQuery<ResponseType<ProofPost[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "post",
      missionId,
      "participant",
      pageQuery,
    ],
    queryFn: () => getMissionContentLibrary(pageQuery.value, missionId),
  });
};

export const useGetApprovalPost = (missionId: string) => {
  return useQuery<ResponseType<ApprovalPostResponse>, Error>({
    queryKey: ["mission", "detail", "post", "approval", missionId],
    queryFn: () => getApprovalPost(missionId),
  });
};

export const useChangeStatusSubmissionPost = (
  missionId: string,
  type?: Ref<PostType>,
  validateApproval: Ref<boolean> = ref(true),
) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: changeStatusSubmissionPost,
    onMutate: async ({ t_mission_post_id }) => {
      await queryClient.cancelQueries({
        queryKey: ["mission", "detail", "post", "approval", missionId],
      });

      const previousPostData = queryClient.getQueryData([
        "mission",
        "detail",
        "post",
        "approval",
        missionId,
      ]);

      queryClient.setQueryData(
        ["mission", "detail", "post", "approval", missionId],
        (old: ResponseType<ApprovalPostResponse>) => {
          if (!old || !old.data) {
            console.error("Old data is missing or undefined:", old);
            return old;
          }

          const submissionType = type?.value!;
          if (!old.data[submissionType]) {
            console.error(
              `Submission type "${submissionType}" does not exist in data.`,
            );
            return old;
          }
          return {
            ...old,
            data: {
              ...old.data,
              [submissionType]: old.data[submissionType].filter(
                (data): data is SubmissionShare =>
                  (data as SubmissionShare).submissions.id !==
                  t_mission_post_id,
              ),
            },
          };
        },
      );

      return { previousPostData };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["mission", "detail", "post", "submission", missionId],
      });

      if (validateApproval?.value) {
        queryClient.invalidateQueries({
          queryKey: ["mission", "detail", "post", "approval", missionId],
        });
      }
      queryClient.invalidateQueries({
        queryKey: ["mission", "detail", "post", missionId],
      });

      toast.success("Change Status Success");
    },
    onError: (err, _, context) => {
      if (context?.previousPostData) {
        queryClient.setQueryData(
          ["mission", "detail", "post", "approval", missionId],
          context.previousPostData,
        );
      }

      console.log(err);
    },
  });
};

export const useGetMissionSubmission = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<SubmissionPostHistories[]>, Error>({
    queryKey: ["mission", "detail", "post", "submission", missionId, pageQuery],
    queryFn: () => getMissionSubmission(missionId, pageQuery.value),
  });
};

export const useReminderSubmission = (missionId: string) => {
  const { queryClient, toast } = useMutationResources();
  return useMutation({
    mutationFn: reminderMissionPost,
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["mission", "detail", "post", "submission", missionId],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["mission", "detail", "post", "submission", missionId],
      });
      toast.success("Reminder Success");
    },
    onError: () => {
      console.error("Error Reminder");
    },
  });
};

// export const useupdateMission = (missionId: string) => {
//   const { queryClient, toast } = useMutationResources();

//   return useMutation({
//     mutationFn: (data: EditMissionEventValue) =>
//       updateMission(missionId, data),
//     onSuccess: () => {
//       queryClient.invalidateQueries({
//         queryKey: ["mission", "detail", "event", missionId],
//       });
//       toast.success("Mission Updated");
//     },
//     onError: () => {
//       console.error("Error Updating Mission");
//       toast.error("Error Updating Mission");
//     },
//   });
// };
