<script lang="ts" setup>
import { useLayoutStore } from "@/store/layout";
import Link from "@/components/button/link-pinia.vue";
import { RewardDetailPage } from "@/types/reward";

const link: { value: RewardDetailPage; text: string; icon: string }[] = [
  {
    value: "index",
    text: "Overview",
    icon: "eva:gift-outline",
  },
  {
    value: "about",
    text: "About Reward",
    icon: "eva:shopping-bag-outline",
  },
];

const layoutStore = useLayoutStore();
</script>

<template>
  <div class="flex flex-row items-center gap-3">
    <Link
      v-for="link in link"
      :value="link.value"
      :text="link.text"
      :icon="link.icon"
      :checked="layoutStore.getRewardPage === link.value"
      @update:click="layoutStore.setRewardDetailPage"
    />
  </div>
</template>
