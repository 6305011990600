export const RewardsPages = {
  path: "/reward",
  name: "reward",
  children: [
    {
      path: "",
      name: "reward.index",
      component: () => import("@/pages/reward/index.vue"),
    },
    {
      path: "purchased-history",
      name: "reward.purchased-history",
      component: () => import("@/pages/reward/purchased-history.vue"),
    },
    {
      path: "create",
      name: "reward.create",
      component: () => import("@/pages/reward/create.vue"),
      meta: { header: false, sidebar: false, footer: true },
    },
    {
      path: "benefit",
      name: "reward.benefit",
      component: () => import("@/pages/reward/benefits.vue"),
    },
    {
      path: "award",
      name: "reward.award",
      component: () => import("@/pages/reward/award.vue"),
    },

    {
      path: "leaderboard",
      name: "reward.leaderboard",
      component: () => import("@/pages/reward/leaderboard.vue"),
    },
    {
      path: "detail/:id",
      name: "reward.detail",
      component: () => import("@/pages/reward/detail/index.vue"),
    },
    {
      path: "purchased/detail/:id",
      name: "reward.purchased.detail",
      component: () => import("@/pages/reward/purchased/detail.vue"),
      meta: {
        header: false,
      },
    },
  ],
  meta: {
    title: "Reward",
  },
};
