import axiosInstance from "@/lib/axiosInstance";
import { FilterDateType, PageQueryType, ResponseType } from "@/types";
import { Client, Summary } from "@/types/super-dashboard/client";

export const getSummary = async (
  date: FilterDateType,
): Promise<ResponseType<Summary>> => {
  const response = await axiosInstance.get("/clients/summary", {
    params: date,
  });
  return response.data;
};
export const getList = async (
  data: PageQueryType,
): Promise<ResponseType<Client[]>> => {
  const response = await axiosInstance.get("/clients", {
    params: data,
  });

  return response.data;
};

export const storeClient = async (data: any): Promise<ResponseType> => {
  const response = await axiosInstance.post("/clients", data);
  return response.data;
};
