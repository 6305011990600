<script setup lang="ts">
import Button from "@/components/button/index.vue";
import Input from "@/components/input/index.vue";
import Modal from "@/components/modal/index.vue";
import Loading from "@/components/loading/index.vue";
import Select from "@/components/select/index.vue";

import { Ref, toRefs, watch } from "vue";
import {
  updateMemberSchema,
  UpdateMemberValues,
} from "@/constants/form/member";
import { useForm } from "vee-validate";
import {
  useGetAllGroupOptions,
  useGetAllProvinceOptions,
} from "@/hooks/form-resources";
import { MemberDetail } from "@/types/member";
import { useUpdateMember } from "@/hooks/member";

interface Props {
  data?: MemberDetail;
  memberId: string;
}

const props = defineProps<Props>();
const open = defineModel<boolean>();
const { data } = toRefs(props);

const { mutateAsync, isPending, isSuccess } = useUpdateMember(props.memberId);
const {
  data: dataGroup,
  isFetching: isFetchingGroup,
  isLoading: isLoadingGroup,
} = useGetAllGroupOptions(open as Ref<boolean>);
const {
  data: dataProvince,
  isFetching: isFetchingProvince,
  isLoading: isLoadingProvince,
} = useGetAllProvinceOptions(open as Ref<boolean>);

const { errors, defineField, handleSubmit, resetForm } = useForm({
  validationSchema: updateMemberSchema,
  initialValues: {
    name: "",
    email: "",
    phone: "",
    gender: "",
    province_id: "",
    group_ids: [] as string[],
  },
});

const [name] = defineField("name");
const [email] = defineField("email");
const [phone] = defineField("phone");
const [gender] = defineField("gender");
const [province_id] = defineField("province_id");
const [group_ids] = defineField("group_ids");

const submit = handleSubmit(async (values) => {
  await mutateAsync(values as UpdateMemberValues);
  if (isSuccess.value) {
    resetForm();
    open.value = false;
  }
});

const genderOptions = [
  {
    label: "Male",
    value: "MALE",
  },
  {
    label: "Female",
    value: "FEMALE",
  },
];

const attachData = () => {
  if (!data.value?.name) return;

  resetForm({
    values: {
      name: data.value.name,
      email: data.value.email,
      phone: data.value.phone ? removeChars(data.value.phone?.toString()) : "",
      gender: data.value.gender,
      province_id: data.value.province.id,
      group_ids: formatGroups() || [],
    },
  });
};

const formatGroups = () => {
  if (!data.value || !dataGroup.value?.data) return;

  return (
    data.value?.groups
      .filter((item) =>
        dataGroup.value?.data?.some((group) => group.value === item.id),
      )
      .map((item) => item.id) || []
  );
};

function removeChars(number: string) {
  return number.replace(/62|\+/g, "");
}

watch(
  () => data.value,
  () => {
    attachData();
  },
  { immediate: true },
);

watch(
  () => dataGroup.value,
  () => {
    let groups = formatGroups();
    if (groups) {
      group_ids.value = groups;
    }
  },
  { immediate: true },
);
</script>

<template>
  <Modal title="Update Member" v-model="open" modal-class="max-w-[700px]">
    <form
      @submit.prevent="submit"
      class="flex w-full flex-col items-center gap-3"
    >
      <div class="my-3 grid w-full grid-cols-2 gap-3">
        <Input
          :errors="errors.name"
          v-model="name"
          placeholder="John Doe"
          label="Full Name"
          mandatory
        />
        <Input
          :errors="errors.phone"
          v-model="phone"
          type="number"
          placeholder="8239839201"
          label="Phone"
        />
        <Input
          :errors="errors.email"
          v-model="email"
          placeholder="jhondoe@gmail.com"
          label="Email"
          mandatory
        />
        <Select
          label="Gender"
          :errors="errors.gender"
          v-model="gender"
          placeholder="Select Gender"
          :options="genderOptions"
          :is-clearable="false"
        />
        <Loading v-if="isLoadingProvince || isFetchingProvince" />
        <Select
          v-else-if="dataProvince?.data"
          :errors="errors.province_id"
          label="Select Location"
          v-model="province_id"
          placeholder="Select Location"
          :options="dataProvince?.data!"
        />

        <Loading v-if="isLoadingGroup || isFetchingGroup" />

        <Select
          v-else-if="dataGroup?.data"
          :errors="errors.group_ids"
          v-model="group_ids"
          label="Group"
          placeholder="Select Group"
          :options="dataGroup?.data!"
          is-multi
        />
      </div>
      <Button
        class="ml-auto"
        text="Save Edit"
        icon="eva:edit-2-fill"
        type="submit"
        :loading="isPending"
      />
    </form>
  </Modal>
</template>
