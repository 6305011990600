import { Ref } from "vue";
import {
  getList,
  getSummary,
  storeClient,
} from "@/services/super-dashboard/client";
import { FilterDateType, PageQueryType, ResponseType } from "@/types";
import { Client, Summary } from "@/types/super-dashboard/client";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/vue-query";
import { useMutationResources } from "../toast-query-client";
import { useCreateClientStore } from "@/store/create-client";

export const useGetSummary = (data: Ref<FilterDateType>) => {
  return useQuery<ResponseType<Summary>, Error>({
    queryKey: ["super-dashboard", "client", "summary", data],
    queryFn: () => getSummary(data.value),
  });
};

export const useGetList = (pageQuery: Ref<PageQueryType>) => {
  return useInfiniteQuery<ResponseType<Client[]>, Error>({
    queryKey: ["super-dashboard", "client", "lists", pageQuery],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getList({
        ...pageQuery.value,
        page: pageNumber,
      });
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: pageQuery.value.page as number | undefined,
  });
};

export function useStoreClient() {
  const { queryClient, toast } = useMutationResources();
  const clientStore = useCreateClientStore();

  return useMutation({
    mutationFn: storeClient,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ["client"] });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client"] });
      clientStore.$reset();
      toast.success("New Client Created");
    },
    // onError: (error, variables, context) => {
    //   // console.log(error);
    //   console.log(variables);
    //   console.log(context);
    //   // console.error("Error adding Client", error);
    // },
    onSettled: (data, error, variables, context) => {
      console.log(data, "settled");
      console.log(error?.message, "settled");
      console.log(variables, "settled");
      console.log(context, "settled");
    },
  });
}
