import { EditMissionEventValue } from "@/constants/form/mission";
import axiosInstance from "@/lib/axiosInstance";
import { PageQueryType, ResponseType } from "@/types";
import {
  ApprovalDataEvent,
  MissionEvent,
  ParticipantEvent,
  SubmissionEventHistories,
  SummaryEvent,
} from "@/types/mission-event";
import moment from "moment";
import nProgress from "nprogress";

export async function getMissionDetail(
  MissionId: string,
): Promise<ResponseType<MissionEvent>> {
  const response = await axiosInstance.get(`/missions/${MissionId}/event`);
  return response.data;
}

export async function getMissionSummary(
  MissionId: string,
): Promise<ResponseType<SummaryEvent>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/event/summary`,
  );
  return response.data;
}

export async function getMissionParticipant(
  MissionId: string,
  PageQuery: PageQueryType,
): Promise<ResponseType<ParticipantEvent[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/event/participants`,
    {
      params: PageQuery,
    },
  );
  return response.data;
}

export async function updateMission(
  MissionId: string,
  data: EditMissionEventValue,
): Promise<ResponseType<string>> {
  const form = new FormData();

  form.append("title", data.title);
  // form.append("description", data.description);

  form.append("start_at", moment(data.start_at).format("YYYY-MM-DD HH:mm:ss"));
  form.append("end_at", moment(data.end_at).format("YYYY-MM-DD HH:mm:ss"));
  form.append("lat", data.lat.toString());
  form.append("long", data.long.toString());
  form.append("address", data.address);
  form.append("radius", data.radius.toString());

  if (data.banner_file) {
    form.append("banner", data.banner_file);
  }
  if (data.brief_file) {
    form.append("brief_file", data.brief_file);
  }

  if (data.brief_text) {
    form.append("brief", data.brief_text);
  }

  const response = await axiosInstance.post(
    `/missions/${MissionId}/event`,
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response.data;
}

export async function getMissionSubmission(
  MissionId: string,
  PageQuery: PageQueryType,
): Promise<ResponseType<SubmissionEventHistories[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/event/histories`,
    {
      params: PageQuery,
    },
  );
  return response.data;
}

export async function changeStatusSubmissionEvent({
  t_mission_id,
  status,
  message,
}: {
  t_mission_id: string;
  status: "APPROVED" | "REJECTED" | "REVIEW";
  message?: string;
}): Promise<ResponseType> {
  let url = `/missions/event/approvals/${t_mission_id}`;

  const form = {
    message,
    status,
  };

  const response = await axiosInstance.put(url, form);

  return response.data;
}

export async function getApprovalEvent(
  missionId: string,
): Promise<ResponseType<ApprovalDataEvent[]>> {
  const response = await axiosInstance.get(
    `/missions/${missionId}/event/approvals`,
  );
  return response.data;
}

export function downloadReport({
  missionId,
  name,
}: {
  missionId: string;
  name: string;
}): void {
  nProgress.start();
  axiosInstance
    .get(`/missions/${missionId}/event/report`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment().format("YYYYMMDD")}_event_attendance_${name}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}
