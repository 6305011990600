<script lang="ts" setup>
import { cn } from "@/lib/utils";

const props = defineProps({
  title: {
    type: String,
    // required: true,
  },

  previewClass: {
    type: String,
  },
});
</script>

<template>
  <div class="flex w-full flex-col gap-2">
    <h3 v-if="props.title" class="text-xs font-bold capitalize">
      {{ props.title }}
    </h3>
    <div
      :class="
        cn(
          'w-full rounded-lg border border-primary_slate bg-white p-2 text-xs text-primary_gray',
          props.previewClass,
        )
      "
    >
      <slot />
    </div>
  </div>
</template>
