export const isValidYouTubeURL = (url: string) => {
  const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  return regex.test(url);
};

export const convertToEmbedURL = (url: string) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);

  if (match && match[2]) {
    return `https://www.youtube.com/embed/${match[2]}`;
  }

  return null; // Return null jika URL tidak valid
};

export const linkRules = {
  INSTAGRAM: ["www.instagram.com", "instagram.com"],
  TIKTOK: ["www.tiktok.com", "tiktok.com", "vt.tiktok.com", "vm.tiktok.com"],
  TWITTER: [
    "www.twitter.com",
    "www.x.com",
    "x.com",
    "twitter.com",
    "vt.twitter.com",
    "vm.twitter.com",
  ],
  CUSTOM: ["www.", "http://", "https://"],
  LINKEDIN: ["www.linkedin.com", "linkedin.com"],
  YOUTUBE: ["www.youtube.com", "youtube.com", "youtu.be"],
  REVIEW_APP: ["play.google.com", "apps.apple.com"],
  PLAYSTORE: ["play.google.com"],
  APPSTORE: ["apps.apple.com"],
};

const isSocialMediaValid = (link: string, type: keyof typeof linkRules) => {
  try {
    const parsedURL = new URL(link);

    if (type === "CUSTOM") {
      return linkRules[type].some((rule) => link.includes(rule));
    } else {
      return linkRules[type].includes(parsedURL.host);
    }
  } catch (err) {
    return false;
  }
};

export default isSocialMediaValid;
