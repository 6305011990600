export const SettingPages = {
  path: "/super-dashboard/setting",
  name: "super-dashboard.setting",
  children: [
    {
      path: "",
      name: "super-dashboard.setting.index",
      component: () => import("@/pages/super-dashboard/setting/index.vue"),
      meta: {
        allowedLevels: ["SUPER"],
        title: "Setting",
        superdashboard: true,
      },
    },
    {
      path: "admin",
      name: "super-dashboard.setting.admin",
      component: () => import("@/pages/super-dashboard/setting/admin.vue"),
      meta: {
        allowedLevels: ["SUPER"],
        // title: "Home",
        title: "Setting Admin",
        superdashboard: true,
      },
    },
  ],
  meta: {
    // title: "Home",
  },
};
