import { ref, Ref } from "vue";
import { useMutation, useQuery } from "@tanstack/vue-query";

import { PageQueryType, ResponseType } from "@/types";
import {
  getMissionDetail,
  getMissionSummary,
  getMissionAnalytics,
  getMissionParticipant,
  getMissionSubmission,
  reminderMissionReact,
  changeStatusSubmissionReact,
  getApprovalReact,
  // getMissionContentLibrary,
} from "@/services/mission-react";
import {
  ApprovalReactResponse,
  MissionReact,
  MissionReactAnalytics,
  MissionReactType,
  ParticipantReact,
  SubmissionReactHistories,
  SummaryReact,
} from "@/types/mission-react";
import { useMutationResources } from "./toast-query-client";

export const useGetMissionDetail = (
  missionId: string,
  enabled: boolean = true,
) => {
  return useQuery<ResponseType<MissionReact>, Error>({
    queryKey: ["mission", "detail", "react", missionId],
    queryFn: () => getMissionDetail(missionId),
    enabled,
  });
};

export const useGetMissionSummary = (missionId: string) => {
  return useQuery<ResponseType<SummaryReact>, Error>({
    queryKey: ["mission", "detail", "react", , missionId, "summary"],
    queryFn: () => getMissionSummary(missionId),
  });
};

export const useGetMissionAnalytics = (
  missionId: string,
  social?: Ref<MissionReactType> | undefined,
  enabled: Ref<boolean> = ref(true),
) => {
  return useQuery<ResponseType<MissionReactAnalytics>, Error>({
    queryKey: ["mission", "detail", "react", missionId, "analytics", social],
    queryFn: () => getMissionAnalytics(missionId, social?.value),
    enabled: enabled.value && social !== undefined,
    placeholderData: (prev) => prev,
  });
};

export const useGetMissionParticipant = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
  social?: Ref<MissionReactType>,
) => {
  return useQuery<ResponseType<ParticipantReact[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "react",
      missionId,
      "participant",
      pageQuery,
      social,
    ],
    queryFn: () =>
      getMissionParticipant(missionId, pageQuery.value, social?.value),
  });
};

export const useGetMissionSubmission = (
  missionId: string,
  pageQuery: Ref<PageQueryType>,
) => {
  return useQuery<ResponseType<SubmissionReactHistories[]>, Error>({
    queryKey: [
      "mission",
      "detail",
      "react",
      "submission",
      missionId,
      pageQuery,
    ],
    queryFn: () => getMissionSubmission(missionId, pageQuery.value),
  });
};

export const useReminderSubmission = (missionId: string) => {
  const { queryClient, toast } = useMutationResources();
  return useMutation({
    mutationFn: reminderMissionReact,
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["mission", "detail", "react", "submission", missionId],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["mission", "detail", "react", "submission", missionId],
      });
      toast.success("Reminder Success");
    },
    onError: () => {
      console.error("Error Reminder");
    },
  });
};

export const useChangeStatusSubmissionReact = (missionId: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: changeStatusSubmissionReact,
    onMutate: async () => {
      // await queryClient.cancelQueries({
      //   // queryKey: ["mission", "detail", "react", "submission", missionId],
      // });
      // await queryClient.cancelQueries({
      //   // queryKey: ["mission", "detail", "react", "approval", missionId],
      // });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["mission", "detail", "react", "submission", missionId],
      });
      // queryClient.invalidateQueries({
      //   // queryKey: ["mission", "detail", "react", "approval", missionId],
      // });

      queryClient.invalidateQueries({
        queryKey: ["mission", "detail", "react", missionId],
      });

      toast.success("Change Status Success");
    },
    onError: () => {
      console.error("Error Change Status");
    },
  });
};

export const useGetApprovalReact = (missionId: string) => {
  return useQuery<ResponseType<ApprovalReactResponse>, Error>({
    queryKey: ["mission", "detail", "react", "approval", missionId],
    queryFn: () => getApprovalReact(missionId),
  });
};

// export const useGetMissionContentLibrary = (
//   pageQuery: Ref<PageQueryType<string>>,
//   missionId: string,
// ) => {
//   return useQuery<ResponseType<ProofPost[]>, Error>({
//     queryKey: [
//       "mission",
//       "detail",
//       "react",
//       missionId,
//       "participant",
//       pageQuery,
//     ],
//     queryFn: () => getMissionContentLibrary(pageQuery.value, missionId),
//   });
// };

// export const useupdateMission = (missionId: string) => {
//   const { queryClient, toast } = useMutationResources();

//   return useMutation({
//     mutationFn: (data: EditMissionEventValue) =>
//       updateMission(missionId, data),
//     onSuccess: () => {
//       queryClient.invalidateQueries({
//         queryKey: ["mission", "detail", "event", missionId],
//       });
//       toast.success("Mission Updated");
//     },
//     onError: () => {
//       console.error("Error Updating Mission");
//       toast.error("Error Updating Mission");
//     },
//   });
// };
