<script lang="ts" setup>
import Link from "@/components/button/link.vue";
import { useAuthStore } from "@/store/auth-store";
// import ModalCreateRole from "@/components/modal/create/role.vue";

const authStore = useAuthStore();
</script>

<template>
  <div class="flex flex-row items-center gap-3 divide-x-2 divide-primary_slate">
    <div class="flex flex-row items-center gap-3">
      <Link
        v-if="authStore.level === 'MAIN'"
        to="/setting"
        text="Account Detail"
        icon="eva:person-fill"
      />
      <Link to="/setting/admin" text="Admin" icon="eva:briefcase-fill" />
      <Link to="/setting/company" text="Company" icon="eva:monitor-fill" />
    </div>
    <!-- <div class="flex flex-row items-center gap-3 pl-3"> -->
    <!-- <Link
        to="/setting/activity-point"
        text="Activity Point"
        icon="eva:plus-circle-fill"
      /> -->
    <!-- <Link
        to="/setting/mission-point"
        text="Mission Point"
        icon="eva:settings-2-fill"
      /> -->
    <!-- <Link to="/setting/add-on" text="Add On" icon="eva:briefcase-fill" /> -->
    <!-- </div> -->
  </div>

  <div v-if="$route.name === 'role'" class="pr-4">
    <!-- <ModalCreateRole /> -->
  </div>
</template>
