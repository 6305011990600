<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { useDeleteReward, useGetRewardDetail } from "@/hooks/reward";
import Swal from "sweetalert2";

import DropdownCrud from "@/components/dropdown/crud.vue";
import LinkBack from "@/components/button/link-back.vue";
import Button from "@/components/button/index.vue";

const route = useRoute();
const router = useRouter();

const { data } = useGetRewardDetail(route.params.id as string);

const { isSuccess, mutateAsync } = useDeleteReward(route.params.id as string);

const handleDropdown = async (e: string) => {
  if (e === "delete") {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await mutateAsync();
        if (isSuccess) {
          router.push({
            name: "reward.index",
          });
        }
      }
    });
  }
};
</script>

<template>
  <nav
    class="flex h-[90px] w-full flex-row items-center justify-between overflow-hidden border-b border-primary_slate bg-white px-3 py-3 pr-7"
  >
    <div class="flex w-full flex-row items-center gap-4">
      <LinkBack class="mr-3" />
      <h1 class="title-routing">{{ data?.data?.title }}</h1>
    </div>

    <DropdownCrud
      v-if="!data?.data?.is_voucher"
      class="mr-4 w-[200px] p-3"
      text-class="text-sm"
      :is-update="false"
      :is-view="false"
      @update:model-value="(e) => handleDropdown(e)"
    >
      <Button
        variant="outline"
        size="base"
        icon="eva:settings-outline"
        icon-class="text-xl text-primary_blue"
      />
    </DropdownCrud>
  </nav>
</template>
