// import { EditMissionEventValue } from "@/constants/form/mission";
import axiosInstance from "@/lib/axiosInstance";
import { PageQueryType, ResponseType } from "@/types";

import {
  ApprovalReactResponse,
  MissionReact,
  MissionReactAnalytics,
  MissionReactType,
  ParticipantReact,
  SubmissionReactHistories,
  SummaryReact,
} from "@/types/mission-react";
import moment from "moment";
import nProgress from "nprogress";

export async function getMissionDetail(
  MissionId: string,
): Promise<ResponseType<MissionReact>> {
  const response = await axiosInstance.get(`/missions/${MissionId}/react`);
  return response.data;
}

export async function getMissionSummary(
  MissionId: string,
): Promise<ResponseType<SummaryReact>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/react/summary`,
  );
  return response.data;
}

export async function getMissionSubmission(
  MissionId: string,
  PageQuery: PageQueryType,
): Promise<ResponseType<SubmissionReactHistories[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/react/histories`,
    {
      params: PageQuery,
    },
  );
  return response.data;
}

// export async function getMissionContentLibrary(
//   pageQuery: PageQueryType<string>,
//   missionId: string,
// ): Promise<ResponseType<ProofPost[]>> {
//   const params = {
//     ...pageQuery,
//     mission_id: missionId,
//   };
//   const response = await axiosInstance.get(`/contents`, {
//     params: params,
//   });

//   return response.data;
// }

export async function getMissionAnalytics(
  missionId: string,
  social?: MissionReactType,
): Promise<ResponseType<MissionReactAnalytics>> {
  const response = await axiosInstance.get(
    `/missions/${missionId}/react/analysis`,
    {
      params: {
        platform: social,
      },
    },
  );

  return response.data;
}

export async function getMissionParticipant(
  MissionId: string,
  PageQuery: PageQueryType,
  social?: MissionReactType,
): Promise<ResponseType<ParticipantReact[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/react/participants`,
    {
      params: {
        ...PageQuery,
        social,
      },
    },
  );
  return response.data;
}

export async function reminderMissionReact(
  tMissionReactId: string,
): Promise<ResponseType> {
  const response = await axiosInstance.post(
    `/missions/react/reminder/${tMissionReactId}`,
  );
  return response.data;
}

// APPROVED
// REJECTED
// REVIEW
// REVISION
export async function changeStatusSubmissionReact({
  t_mission_react_id,
  mission_react_id,
  participant_id,
  status,
  message,
  attachment,
}: {
  t_mission_react_id?: string;
  mission_react_id?: string;
  participant_id?: string;
  status: "APPROVED" | "REJECTED" | "REVIEW" | "REVISION";
  message?: string;
  attachment?: File;
}): Promise<ResponseType> {
  const form = new FormData();

  form.append("mission_react_id", mission_react_id || "");
  form.append("participant_id", participant_id || "");
  form.append("status", status);
  if (message) {
    form.append("message", message);
  }
  if (attachment) {
    form.append("attachment", attachment);
  }

  let url = `/missions/react/approvals`;

  if (t_mission_react_id) {
    url = `/missions/react/approvals/${t_mission_react_id}`;
  }

  const response = await axiosInstance.post(url, form);

  return response.data;
}

export async function getApprovalReact(
  missionId: string,
): Promise<ResponseType<ApprovalReactResponse>> {
  const response = await axiosInstance.get(
    `/missions/${missionId}/react/approvals`,
  );
  return response.data;
}

export function downloadReport({
  missionId,
  name,
}: {
  missionId: string;
  name: string;
}): void {
  nProgress.start();
  axiosInstance
    .get(`/missions/${missionId}/react/report`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment().format("DDMMYY")}_react_report_${name}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}
