<script setup lang="ts">
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

import Icon from "@/components/icon/index.vue";
import Badge from "@/components/badge/index.vue";

const buttonVariants = cva(
  "inline flex items-center gap-2 justify-center text-xs font-medium hover:ring-2 disabled:opacity-50 duration-200 ease-linear ",
  {
    variants: {
      variant: {
        default:
          "bg-primary_yellow hover:bg-primary/90 text-white disabled:bg-primary_gray",
        destructive: "bg-primary_red text-white hover:border-white",
        disabled: "bg-primary_white opacity-50 border border-primary_slate",
        secondary: "bg-primary_blue hover:bg-primary_blue/90 text-white",
        red: "bg-primary_red/20 hover:border-primary_red text-primary_black",
        blueTransparant:
          "bg-primary_transparant_blue hover:bg-primary_transparant_blue/90 text-primary_blue",
        purple:
          "bg-primary_purple hover:border-primary_purple/20 text-primary_white",
        sky: "bg-sky-500 hover:border-sky-500/20 text-white",
        blue: "bg-blue-500 hover:border-blue-500/20 text-white",
        yellow:
          "bg-primary_yellow/20 hover:border-primary_yellow text-primary_black",
        slate:
          "bg-primary_slate hover:bg-primary_slate/90 text-primary_gray font-bold",
        outlineThigh:
          "text-primary_black border border-primary_gray rounded-md",
        stepFullfiled:
          "text-primary_black bg-primary_greed font-bold font-bold !cursor-default",
        primary_greed:
          "bg-primary_greed hover:bg-primary_greed/90 text-primary_green",
        stepFulfilledYellow:
          "bg-primary_yellow/20 hover:border-primary_yellow text-primary_black font-bold",
        outline:
          "bg-primary_slate hover:bg-primary_slate/90 !text-primary_gray !hover:ring-white",
        coco: "bg-primary_blur !text-white",
        point:
          "bg-primary_white border border-primary_blur rounded-lg text-primary_black pointer-events-none justify-start select-none",
        whiteNoBorder:
          "bg-primary_white hover:bg-primary_white/90 !text-primary_black !border-none !font-bold",
        outlineRed:
          "border border-primary_red hover:bg-primary_red/90 hover:border-primary_red/90 text-primary_red hover:text-primary_white !hover:ring-0",
        white: "bg-white hover:bg-primary_white/90 text-primary_gray",
        input: "bg-white border-primary_slate border",
        iconOri: " ",
        roleGreen:
          "bg-primary_green hover:bg-primary_green/90 text-white !cursor-default",
        roleRed: "bg-primary_red hover:bg-primary_red/90 text-white",
      },
      size: {
        default: "p-2 rounded-md",
        iconOri: "p-0",
        mini: "h-[25px] p-2 rounded-md !text-[10px]",
        mini2: "h-[32px] w-[32px] p-1 rounded-md !text-[10px]",
        xs: "h-[30px] rounded-lg px-2 py-1 !text-[10px] !gap-2",
        sm: "h-[30px] rounded-md p-2 text-xs",
        base: "rounded-md py-2 px-3",
        sidebar:
          "rounded-lg py-3 px-4 text-lg !text-primary_sky justify-start !gap-4",
        lg: "h-11 rounded-md px-8",
        xl: " rounded-lg px-3 py-2",
        large: "h-12 rounded-lg w-full py-2",
        largeRoundedBottom: "h-12 rounded-b-lg w-full py-2",
        date: "h-10 px-2 py-3 rounded-lg w-full !justify-between",
        icon: "h-10 w-10",
      },
      positionIcon: {
        left: "flex-row",
        right: "flex-row-reverse",
        rightFar: "flex-row-reverse justify-between",
        leftFar: "flex-row justify-between",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

interface Props {
  variant?: NonNullable<Parameters<typeof buttonVariants>[0]>["variant"];
  size?: NonNullable<Parameters<typeof buttonVariants>[0]>["size"];
  positionIcon?: NonNullable<
    Parameters<typeof buttonVariants>[0]
  >["positionIcon"];
  loading?: boolean;
  text?: string | number;
  notificationCount?: number;
  disabled?: boolean;
  icon?: string;
  as?: string;
  iconClass?: string;
}
export interface ButtonVariantProps
  extends VariantProps<typeof buttonVariants> {}

withDefaults(defineProps<Props>(), {
  as: "button",
  iconClass: "text-lg",
  text: "",
  notificationCount: 0,
  loading: false,
  disabled: false,
  positionIcon: "left",
});
</script>

<template>
  <component
    :is="as"
    :disabled="disabled || loading"
    :class="
      cn(
        buttonVariants({ variant, size, positionIcon }),
        disabled || loading ? '!cursor-not-allowed' : 'cursor-pointer',
        $attrs.class ?? '',
      )
    "
  >
    <Icon v-if="loading" icon="line-md:loading-loop" :class="iconClass" />
    <template v-else>
      <Icon v-if="icon" :icon="icon" :class="iconClass" />
      <slot v-if="text == ''" />
      <span v-else class="line-clamp-1 max-w-full truncate">{{ text }}</span>
      <Badge
        v-if="notificationCount > 0"
        :text="`${notificationCount} New`"
        variant="notification"
        class="w-fit"
      />
    </template>
  </component>
</template>
