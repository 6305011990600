import { useAuthStore } from "@/store/auth-store";
import { computed } from "vue";

export const getCompanyId = () => {
  const authStore = useAuthStore();
  const companyId = computed(() => authStore.company_id);
  // const companyId = authStore.company_id;

  return companyId;
};

export const enabledQueryWithCompanyId = computed(() => {
  const authStore = useAuthStore();

  return authStore.company_id !== null;
});
