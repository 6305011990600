<script setup lang="ts">
import Button from "./index.vue";
import { RouterLink } from "vue-router";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  to: {
    type: [String, Object],
    required: true,
  },
  inactiveClass: String,
  text: String,
  icon: String,
  iconClass: String,
  active: Boolean,
  notificationCount: {
    type: Number,
    default: 0,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const { text, icon, iconClass } = props;
</script>

<template>
  <router-link :to="to" custom v-slot="{ href, navigate }">
    <Button
      as="a"
      size="sm"
      :href="href"
      :loading="loading"
      @click="navigate"
      :variant="href === $route.path || active ? 'secondary' : 'outline'"
      :notification-count="notificationCount"
      :icon="icon"
      :icon-class="iconClass"
      :text="text"
    />
  </router-link>
</template>
