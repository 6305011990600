import { useAuthStore } from "@/store/auth-store";
import { RouteLocationNormalized } from "vue-router";
import { useToast } from "vue-toastification";
import { checkAccess } from "./auth";
import type { LoginStore } from "@/types/auth";
import { useLayoutStore } from "@/store/layout";
import moment from "moment";
import axiosInstance from "./axiosInstance";
import { useRedirectHome } from "@/composables/redirect-home";

const handleNavigation = (to: RouteLocationNormalized, next: Function) => {
  const authStore = useAuthStore();
  const toast = useToast();
  const token = authStore.getToken;
  const userAccess = authStore.getUserAccess as LoginStore;
  const layoutStore = useLayoutStore();

  if (to.name == "login") {
    const redirectHome = useRedirectHome();

    if (!redirectHome) {
      return next();
    }
  }

  if (to.name === "super-dashboard.home.index") {
    layoutStore.setLabelDate("All");
    layoutStore.setDate(undefined, undefined);
  } else {
    layoutStore.setLabelDate("Last 30 Days");

    layoutStore.setDate(
      moment().subtract(30, "days").toDate(),
      moment().toDate(),
    );
  }

  if (to.query.hideFooter === "true") {
    to.meta.footer = false;
  }
  if (to.meta.superdashboard) {
    authStore.setCompanyId(null);
  }

  // Check if route requires authentication
  if (to.meta.public === false && !token) {
    toast.error("Please login first");
    return next({ name: "login" });
  }

  // Authentication and access checks
  if (!to.meta.public && token) {
    const { canAccessProgram, isProgram } = checkAccess(userAccess);

    // Check user level permissions
    if (
      to.meta.allowedLevels &&
      Array.isArray(to.meta.allowedLevels) &&
      !to.meta.allowedLevels.includes(userAccess.level)
    ) {
      // toast.error("You don't have permission to access this page");

      if (
        !to.meta.superdashboard &&
        !authStore.company_id &&
        authStore.level == "SUPER"
      ) {
        return next({ name: "super-dashboard.home.index" });
      }

      if (authStore.programs.length == 0) {
        toast.error("You don't have any program");
        return next({ name: "not-found2" });
      }

      return next({
        name: "program.detail.index",
        params: { programId: authStore.programs[0].id },
      });
    }

    if (to.meta.programDetail !== true) {
      axiosInstance.defaults.headers.common["X-App-Program"] = null;
    } else {
      axiosInstance.defaults.headers.common["X-App-Program"] = to.params
        .programId as string;
    }

    // Check program access
    if (to.meta.programDetail && isProgram) {
      const programId = to.params.programId as string;

      axiosInstance.defaults.headers.common["X-App-Program"] = programId;
      // console.log(programId);

      if (!canAccessProgram(programId)) {
        toast.error("You don't have access to this program");

        if (authStore.programs.length == 0) {
          toast.error("You don't have any program");
          return next({ name: "not-found2" });
        }

        return next({
          name: "program.detail.index",
          params: { programId: authStore.programs[0].id },
        });
      }
    }
  }

  next();
};

export default handleNavigation;
