import { useAuthStore } from "@/store/auth-store";
import { useRouter } from "vue-router";
export const useRedirectHome = () => {
  const authStore = useAuthStore();

  if (!authStore.isAuthenticated) return false;

  const router = useRouter();

  if (authStore.level == "PROGRAM")
    router.push({
      name: "program.detail.index",
      params: { programId: authStore.programs[0].id },
    });

  if (authStore.level == "SUPER")
    router.push({ name: "super-dashboard.home.index" });

  if (authStore.level == "MAIN") router.push({ name: "home.index" });

  return true;
};
