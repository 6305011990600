import Logo from "@/assets/images/logo.png";

export const image_url =
  import.meta.env.VITE_IMAGE_URL ||
  "https://storage.googleapis.com/venturo-komune";
export const company_logo = Logo;
export const teamwork = image_url + "/default/teamwork.svg";
export const coming_soon = image_url + "/default/coming-soon.svg";
export const not_found_light = image_url + "/default/404-light.svg";
export const not_found_dark = image_url + "/default/404-dark.svg";
export const man_with_computer =
  image_url + "/illustration/man-with-computer.svg";
export const rectangle_default = image_url + "/default/rectangle.png";

export const defaultProfile = image_url + "/default/profile.png";
