<template>
  <img
    :src="src"
    :alt="alt"
    :class="props.class"
    @error="handleError"
    :loading="loading"
  />
</template>

<script setup lang="ts">
import type { HTMLAttributes } from "vue";

interface Props {
  src?: string | any;
  alt?: string;
  loading?: HTMLImageElement["loading"];
  class?: HTMLAttributes["class"];
}

const props = withDefaults(defineProps<Props>(), {
  src: "",
  alt: "",
  class: "",
  loading: "lazy",
});

const handleError = (e: any) => {
  e.target.src = "https://placehold.co/600x400";
};
</script>
