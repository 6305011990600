<script setup lang="ts">
import {
  SidebarMenuProgram,
  SidebarOtherProgram,
  SidebarProgram,
} from "@/constants";
import { Icon } from "@iconify/vue";

import { useAuthStore } from "@/store/auth-store";
import { cn } from "@/lib/utils";

import Button from "@/components/button/index.vue";
import Logo from "../fragments/logo.vue";
import { useGetServer } from "@/composables/get-server";
import BadgeCompany from "../fragments/badge-company.vue";
import { Environment } from "@/constants";
import { useLayoutStore } from "@/store/layout";

// import { useGetNotificationsByType } from "@/hooks/notification";

const authStore = useAuthStore();
const layoutStore = useLayoutStore();
const badgeLabel = useGetServer();
</script>

<template>
  <div
    v-if="layoutStore.isSidebarOpen"
    class="fixed inset-0 z-50 bg-black/50 md:hidden"
    @click="layoutStore.toggleSidebar"
  ></div>

  <aside
    :class="
      cn(
        'fixed left-0 top-0 z-20 flex h-full min-h-screen flex-col gap-3 border-r border-primary_slate bg-white transition-all duration-300 ease-in-out',
        layoutStore.isSidebarOpen
          ? 'z-[60] w-[250px] translate-x-0 px-4 md:px-2'
          : '-translate-x-full md:w-[200px] md:translate-x-0 md:px-2 lg:w-[250px] lg:px-4',
      )
    "
  >
    <Logo />

    <Button
      v-if="authStore.level !== 'PROGRAM'"
      @click="$router.push({ name: 'program.index' })"
      variant="whiteNoBorder"
      size="sidebar"
      text="All Program"
      icon="eva:arrow-ios-back-outline"
      icon-class="text-xl text-primary_gray"
    />
    <simplebar class="flex h-[50vh] flex-col gap-3 overflow-y-auto p-0.5">
      <p class="mt-3">Main Menu</p>
      <div class="flex flex-col gap-3">
        <template v-for="item in SidebarMenuProgram" :key="item.id">
          <router-link
            v-if="!item.hidden"
            :class="
              item.name === 'Home' &&
              $route.path.split('/').includes('client') &&
              !$route.fullPath.includes('payment')
                ? 'border-r-4 border-primary_blue bg-gradient-to-r from-white from-50% via-slate-200 via-90% to-slate-300 to-100% font-bold !text-primary_blue'
                : ''
            "
            activeClass="bg-gradient-to-r from-white from-50% via-slate-200 via-90% to-slate-300 border-r-4 border-primary_blue to-100% font-bold !text-primary_blue"
            class="relative flex flex-row items-center gap-3 rounded-lg px-2 py-1.5 text-primary_gray duration-150 ease-in"
            :to="{
              name: item.link,
              params: { programId: $route.params.programId },
            }"
            v-slot="{ isActive }"
          >
            <component
              v-if="item.customIcon"
              :is="item.customIcon"
              :class="cn('w-auto', isActive && 'fill-primary_blue')"
            />
            <Icon v-else-if="item.icon" class="text-2xl" :icon="item.icon" />

            <h3
              :class="
                isActive ||
                (item.name === 'Home' &&
                  $route.path.split('/').includes('client'))
                  ? 'relative text-base font-bold text-primary_blue'
                  : 'relative text-base font-normal text-primary_gray'
              "
            >
              {{ item.name }}
            </h3>
          </router-link>
        </template>
      </div>
      <p class="mt-3">Program</p>
      <div class="flex flex-col gap-3">
        <template v-for="item in SidebarProgram" :key="item.id">
          <router-link
            v-if="!item.hidden"
            :class="
              item.name === 'Home' &&
              $route.path.split('/').includes('client') &&
              !$route.fullPath.includes('payment')
                ? 'border-r-4 border-primary_blue bg-gradient-to-r from-white from-50% via-slate-200 via-90% to-slate-300 to-100% font-bold !text-primary_blue'
                : ''
            "
            activeClass="bg-gradient-to-r from-white from-50% via-slate-200 via-90% to-slate-300 border-r-4 border-primary_blue to-100% font-bold !text-primary_blue"
            class="relative flex flex-row items-center gap-3 rounded-lg px-2 py-1.5 text-primary_gray duration-150 ease-in"
            :to="{
              name: item.link,
              params: { programId: $route.params.programId },
            }"
            v-slot="{ isActive }"
          >
            <component
              v-if="item.customIcon"
              :is="item.customIcon"
              :class="cn('w-auto', isActive && 'fill-primary_blue')"
            />
            <Icon v-else-if="item.icon" class="text-2xl" :icon="item.icon" />

            <h3
              :class="
                isActive ||
                (item.name === 'Home' &&
                  $route.path.split('/').includes('client'))
                  ? 'relative text-base font-bold text-primary_blue'
                  : 'relative text-base font-normal text-primary_gray'
              "
            >
              {{ item.name }}
            </h3>
          </router-link>
        </template>
      </div>
      <p class="mt-3">Other</p>
      <div class="flex flex-col gap-3">
        <template v-for="item in SidebarOtherProgram" :key="item.id">
          <router-link
            v-if="!item.hidden"
            :class="
              item.name === 'Home' &&
              $route.path.split('/').includes('client') &&
              !$route.fullPath.includes('payment')
                ? 'border-r-4 border-primary_blue bg-gradient-to-r from-white from-50% via-slate-200 via-90% to-slate-300 to-100% font-bold !text-primary_blue'
                : ''
            "
            activeClass="bg-gradient-to-r from-white from-50% via-slate-200 via-90% to-slate-300 border-r-4 border-primary_blue to-100% font-bold !text-primary_blue"
            class="relative flex flex-row items-center gap-3 rounded-lg px-2 py-1.5 text-primary_gray duration-150 ease-in"
            :to="{
              name: item.link,
              params: { programId: $route.params.programId },
            }"
            v-slot="{ isActive }"
          >
            <Icon class="text-2xl" :icon="item.icon" />

            <h3
              :class="
                isActive ||
                (item.name === 'Home' &&
                  $route.path.split('/').includes('client'))
                  ? 'relative text-base font-bold text-primary_blue'
                  : 'relative text-base font-normal text-primary_gray'
              "
            >
              {{ item.name }}
            </h3>
          </router-link>
        </template>
      </div>
    </simplebar>
    <BadgeCompany />
  </aside>

  <div
    v-if="Environment !== 'production'"
    class="fixed left-[-68px] top-[18px] z-20"
  >
    <div
      :class="`w-[200px] -rotate-45 bg-${authStore.server.color} py-1 text-center text-sm text-white`"
    >
      {{ badgeLabel }}
    </div>
  </div>
</template>
