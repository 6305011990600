import {
  addBadgeToProgram,
  getAllBadges,
  getBadge,
  getBadgeInProgram,
} from "@/services/badge";
import { BadgeInAddBadge, useAddBadgeStore } from "@/store/add-badge";
import { ResponseType } from "@/types";
import { Badge, BadgeDetail, BadgeInProgram, BadgeType } from "@/types/badges";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { ref, Ref } from "vue";
import { useMutationResources } from "./toast-query-client";
import nProgress from "nprogress";

export const useGetAllBadges = ({
  type,
  enabled = ref(true),
  unused_in,
}: {
  type?: BadgeType;
  enabled?: Ref<boolean>;
  unused_in?: string;
}) => {
  return useQuery<ResponseType<Badge[]>, Error>({
    queryKey: ["badge", type, unused_in],
    queryFn: () => getAllBadges(type, unused_in),
    enabled,
  });
};

export const useGetBadge = (
  programId: string,
  BadgeId: string,
  enabled: boolean = true,
) => {
  return useQuery<ResponseType<BadgeDetail>, Error>({
    queryKey: ["badge", "program", programId, BadgeId],
    queryFn: () => getBadge(programId, BadgeId),
    enabled,
  });
};

export const useGetBadgeInProgram = (
  programId: string,
  enabled: boolean = true,
) => {
  return useQuery<ResponseType<BadgeInProgram[]>, Error>({
    queryKey: ["badge", "program", programId],
    queryFn: () => getBadgeInProgram(programId),
    enabled,
  });
};

export const useAddBadgeToProgram = (programId: string) => {
  const { router, toast, queryClient } = useMutationResources();
  const addBadgeStore = useAddBadgeStore();
  return useMutation({
    mutationFn: (data: BadgeInAddBadge[]) =>
      addBadgeToProgram({ programId, data }),
    onMutate: () => {
      addBadgeStore.setLoading(true);
      nProgress.start();
    },
    onSuccess: () => {
      addBadgeStore.setLoading(false);

      queryClient.invalidateQueries({
        queryKey: ["badge", "program", programId],
      });

      toast.success("Badge Added");

      router.push({
        name: "program.detail.badge.index",
        params: { programId: programId },
      });
    },
    onSettled: () => {
      addBadgeStore.setLoading(false);
      nProgress.done();
    },
  });
};
