<script setup lang="ts">
import { useRoute } from "vue-router";
import { useGetGroupDetail } from "@/hooks/group";
// import Swal from "sweetalert2";
// import { ref } from "vue";

// import DropdownCrud from "@/components/dropdown/crud.vue";
import LinkBack from "@/components/button/link-back.vue";
// import Button from "@/components/button/index.vue";
// import ModalAddMember from "@/organism/group/modal-add-member.vue";
// import Loading from "@/components/loading/index.vue";

const route = useRoute();
// const router = useRouter();

const { data } = useGetGroupDetail(route.params.id as string);
// const { mutateAsync, isPending, isSuccess } = useDeleteGroup();

// const modalAddMember = ref(false);
// const modalEditGroup = ref(false);

// const handleAddMember = () => {
//   modalAddMember.value = true;
// };

// const handleView = (e: string) => {
//   if (isFetching.value || isLoading.value) {
//     return;
//   }
//   if (e === "update") {
//     modalEditGroup.value = true;
//   } else if (e === "delete") {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         mutateAsync([route.params.id as string]);
//         if (!isPending.value && isSuccess.value) {
//           router.push("member.group");
//         }
//       }
//     });
//   }
// };
//
</script>

<template>
  <nav
    class="flex h-[90px] w-full flex-row items-center justify-between overflow-hidden border-b border-primary_slate bg-white px-3 py-3 pr-7"
  >
    <div class="flex w-full flex-row items-center gap-4">
      <LinkBack class="mr-3" />
      <h1 class="title-routing">
        {{ data?.data?.title }}
      </h1>
    </div>

    <!-- <div class="flex flex-row items-center gap-3">
      <Loading v-if="isPending" />
      <Button icon="eva:plus-outline" text="Member" @click="handleAddMember" />
      <DropdownCrud
        v-if="!data?.data?.is_general"
        class="mr-4 w-[200px] p-3"
        text-class="text-sm"
        :is-view="false"
        @update:model-value="(e) => handleView(e)"
      >
        <Button
          variant="outline"
          size="base"
          icon="eva:settings-outline"
          icon-class="text-xl text-primary_blue"
        />
      </DropdownCrud>
    </div> -->
  </nav>
</template>
