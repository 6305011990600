// import { QueryClient } from '@tanstack/vue-query';

import { VueQueryPluginOptions } from "@tanstack/vue-query";

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60,
        retry: false,
        // refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
        // refetchOnMount: false,
        // retry() {
        //   return false;
        // },
      },
    },
  },
  enableDevtoolsV6Plugin: import.meta.env.VITE_APP_MODE !== "production",
};

export default vueQueryPluginOptions;
