<script setup lang="ts">
import Button from "@/components/button/index.vue";
import {
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
} from "radix-vue";
import { ref } from "vue";

const open = ref(false);
</script>

<template>
  <PopoverRoot v-model:open="open">
    <PopoverTrigger as-child aria-label="Update dimensions">
      <Button
        variant="outline"
        size="base"
        icon="eva:bell-fill"
        icon-class="text-primary_blue text-2xl"
      />
    </PopoverTrigger>
    <PopoverPortal>
      <PopoverContent
        side="bottom"
        align="end"
        :side-offset="5"
        class="data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade z-30 flex w-[300px] flex-col gap-3 rounded-xl border border-primary_slate bg-white p-3 will-change-[transform,opacity]"
      >
        <span class="text-primary_gray">Nothing to see here</span>
      </PopoverContent>
    </PopoverPortal>
  </PopoverRoot>
</template>
