<script setup lang="ts">
import { useAuthStore } from "@/store/auth-store";
import { useRoute, useRouter } from "vue-router";
import Images from "@/components/images/index.vue";

const authStore = useAuthStore();

const route = useRoute();
const router = useRouter();

const handleClick = () => {
  if (authStore.level == "MAIN") {
    router.push({ name: "setting.index" });
  }

  if (authStore.level == "PROGRAM") {
    router.push({
      name: "setting.index",
      params: { programId: route.params.programId },
    });
  }

  if (authStore.level == "SUPER") {
    window.location.href = "/super-dashboard/setting";
  }
};
</script>

<template>
  <div
    @click="handleClick"
    class="relative flex h-12 w-12 cursor-pointer items-center justify-center overflow-hidden rounded-full border border-primary_slate"
  >
    <Images :src="authStore.getAvatarUrl" class="object-contain" />
  </div>
</template>
