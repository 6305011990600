<script setup lang="ts">
import Button from "@/components/button/index.vue";
import { useRouter } from "vue-router";

interface Props {
  to?: string;
  noRedirect?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  noRedirect: false,
});
const router = useRouter();

defineEmits(["update:click"]);

const handleClick = () => {
  if (props.to) {
    console.log("routerback");
    return;
  }

  if (router.options.history.state.back !== null) {
    router.back();
  } else {
    router.push({ name: "program.index" });
  }
};
</script>

<template>
  <Button
    @click="noRedirect ? $emit('update:click') : handleClick()"
    variant="outline"
    size="sm"
    icon="eva:corner-up-left-fill"
  >
    Back
  </Button>
</template>
