import axiosInstance from "@/lib/axiosInstance";
import { PageQueryType, ResponseType } from "@/types";
import {
  MissionPolling,
  ParticipantPolling,
  SummaryPolling,
  PopularPolling,
  PollingResult,
} from "@/types/mission-polling";

export async function getMissionDetail(
  MissionId: string,
): Promise<ResponseType<MissionPolling>> {
  const response = await axiosInstance.get(`/missions/${MissionId}/polling`);
  return response.data;
}

export async function getMissionSummary(
  MissionId: string,
): Promise<ResponseType<SummaryPolling>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/polling/summary`,
  );
  return response.data;
}

export async function getMissionParticipant(
  MissionId: string,
  PageQuery: PageQueryType,
): Promise<ResponseType<ParticipantPolling[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/polling/participants`,
    {
      params: PageQuery,
    },
  );
  return response.data;
}

export async function getMissionPopular(
  MissionId: string,
  type: PollingResult,
): Promise<ResponseType<PopularPolling[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/polling/results`,
    {
      params: { type },
    },
  );
  return response.data;
}
