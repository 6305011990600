import axiosInstance from "@/lib/axiosInstance";
import { BadgeInAddBadge } from "@/store/add-badge";
import { ResponseType } from "@/types";
import { Badge, BadgeDetail, BadgeInProgram, BadgeType } from "@/types/badges";

export const getAllBadges = async (
  type?: BadgeType,
  unused_in?: string,
): Promise<ResponseType<Badge[]>> => {
  const response = await axiosInstance.get("/badges", {
    params: {
      type: type,
      unused_in: unused_in,
    },
  });
  return response.data;
};

export const getBadgeInProgram = async (
  programId: string,
): Promise<ResponseType<BadgeInProgram[]>> => {
  const response = await axiosInstance.get(`/programs/${programId}/badges`);
  return response.data;
};

export const getBadge = async (
  programId: string,
  badgeId: string,
): Promise<ResponseType<BadgeDetail>> => {
  const response = await axiosInstance.get(
    `programs/${programId}/badges/${badgeId}`,
  );
  return response.data;
};

export const addBadgeToProgram = async ({
  programId,
  data,
}: {
  programId: string;
  data: BadgeInAddBadge[];
}) => {
  const response = await axiosInstance.post(`/programs/${programId}/badges`, {
    badges: data,
  });
  return response.data;
};
