<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useGetProgramById } from "@/hooks/program";
import { useAuthStore } from "@/store/auth-store";

import Avatar from "@/components/avatar/index.vue";
import Loading from "@/components/loading/index.vue";

import SelectWithoutBorder from "@/components/select/select-without-border.vue";
// import { useMutationResources } from "@/hooks/toast-query-client";
import Icon from "@/components/icon/index.vue";
import { useLayoutStore } from "@/store/layout";

const layoutStore = useLayoutStore();

const route = useRoute();
// const router = useRouter();

const authStore = useAuthStore();
// const { queryClient } = useMutationResources();

const { data, isLoading, isFetching } = useGetProgramById(
  route.params.programId as string,
);
const fakeProgramId = ref(route.params.programId as string);

const programsOptions = computed(() => {
  const programsOptions = authStore.programs.map((p) => ({
    label: p.name,
    value: p.id,
  }));

  if (
    data.value?.data &&
    !programsOptions.find((p) => p.value === data.value?.data?.id)
  ) {
    programsOptions.push({
      label: data.value?.data?.name,
      value: data.value?.data?.id,
    });
  }

  return programsOptions;
});

watch(
  () => fakeProgramId.value,
  () => {
    // router.replace({
    //   name: route.name,
    //   params: {
    //     programId: fakeProgramId.value,
    //   },
    // });

    let url = route.fullPath.split("/");
    url[3] = fakeProgramId.value;

    window.location.href = url.join("/");
  },
);
</script>

<template>
  <nav
    class="flex h-[90px] w-full flex-row items-center justify-between overflow-hidden border-b border-primary_slate bg-white px-3 py-3 pr-7"
  >
    <div class="flex w-full flex-row items-center gap-4">
      <template v-if="authStore.level !== 'PROGRAM'">
        <Icon
          icon="eva:menu-outline"
          class="cursor-pointer text-2xl md:hidden"
          @click="layoutStore.toggleSidebar()"
        />
        <Loading v-if="isLoading || isFetching" />
        <h1
          v-else
          class="title-routing max-w-[200px] overflow-hidden truncate text-ellipsis md:max-w-[400px]"
        >
          {{ data?.data?.name }}
        </h1>
      </template>
      <SelectWithoutBorder
        v-else
        class="w-fit border-none text-xl font-bold"
        v-model="fakeProgramId"
        :options="programsOptions"
      />
    </div>

    <!-- <Notification /> -->
    <Avatar />
  </nav>
</template>
