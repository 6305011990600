// utils/auth.ts
import type { LoginStore } from "@/types/auth";

export const checkAccess = (userAccess: LoginStore) => {
  const isSuper = userAccess.level === "SUPER";
  const isMain = userAccess.level === "MAIN";
  const isProgram = userAccess.level === "PROGRAM";

  const canAccessProgram = (programId: string) => {
    if (isSuper) return true;
    if (isMain || isProgram) {
      return userAccess.programs.some((program) => program.id === programId);
    }
    return false;
  };

  return {
    isSuper,
    isMain,
    isProgram,
    canAccessProgram,
  };
};
