import { defineStore } from "pinia";
import { useAuthStore } from "./auth-store";
import router from "@/config/route";
import { Badge } from "@/types/badges";

export type BadgeInAddBadge = {
  badge_id: string;
  reward_id: string;
  quota: number;
  checked: boolean;
};

export const useAddBadgeStore = function () {
  const companyId = useAuthStore().getCompanyId;
  const programId = router.currentRoute.value.params?.programId;

  const store = defineStore("add-badge" + companyId + "-" + programId, {
    state: () => ({
      dirty: false,
      loading: false,
      badges: [] as null | BadgeInAddBadge[],
    }),
    actions: {
      setBadge(badge: Badge) {
        if (this.badges?.some((item) => item.badge_id === badge.id)) return;

        this.badges?.push({
          badge_id: badge.id,
          quota: 1,
          reward_id: "",
          checked: false,
        });
      },
      setDirty(value: boolean) {
        this.dirty = value;
      },
      setLoading(value: boolean) {
        this.loading = value;
      },
    },
  });

  return store();
};
