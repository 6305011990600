import { defineStore } from "pinia";
import { logoutService, refreshTokenService } from "@/services/auth";
import { Role } from "@/types/admin";
import { DefaultServer } from "@/constants";
import { LoginStore } from "@/types/auth";

export const useAuthStore = defineStore("auth-store", {
  state: (): LoginStore => ({
    token: "",
    email: "",
    role: null,
    company_id: "",
    server: DefaultServer,
    avatar_url: "",
    app_type: "internal",
    level: "" as "SUPER" | "MAIN" | "PROGRAM",
    programs: [],
  }),

  getters: {
    getToken(state) {
      return state.token;
    },
    getEmail(state) {
      return state.email;
    },
    getAvatarUrl(state) {
      return state.avatar_url;
    },
    getRole(state) {
      return state.role;
    },
    getCompanyId(state) {
      return state.company_id;
    },
    // Tambahan getter yang berguna
    isAuthenticated(state) {
      return !!state.token && state.token !== "";
    },
    getUserAccess(state): LoginStore {
      return {
        token: state.token,
        email: state.email,
        role: state.role,
        company_id: state.company_id,
        avatar_url: state.avatar_url,
        app_type: state.app_type,
        level: state.level,
        programs: state.programs,
        server: state.server,
      };
    },
    canAccessProgram: (state) => (programId: string) => {
      if (state.level === "SUPER") return true;
      if (state.level === "MAIN" || state.level === "PROGRAM") {
        return state.programs.some((program) => program.id === programId);
      }
      return false;
    },
  },

  actions: {
    setToken(token: string) {
      this.token = token;
    },
    setEmail(email: string) {
      this.email = email;
    },
    setAvatarUrl(url: string) {
      this.avatar_url = url;
    },
    setCompanyId(id: string | null) {
      this.company_id = id;
    },
    setRole(role: Role) {
      this.role = role;
    },
    setLevel(level: "SUPER" | "MAIN" | "PROGRAM") {
      this.level = level;
    },
    setPrograms(programs: { id: string; name: string }[]) {
      this.programs = programs;
    },
    setUserAccess(data: Partial<LoginStore>) {
      if (data.token) this.token = data.token;
      if (data.email) this.email = data.email;
      if (data.role) this.role = data.role;
      if (data.company_id) this.company_id = data.company_id;
      if (data.avatar_url) this.avatar_url = data.avatar_url;
      if (data.level) this.level = data.level;
      if (data.programs) this.programs = data.programs;
      if (data.app_type) this.app_type = data.app_type;
      if (data.server) this.server = data.server;
    },

    async refreshToken() {
      try {
        if (this.getToken !== null) {
          const response = await refreshTokenService(this.getToken);
          console.log(response, "response refresh token");
          this.setToken(response.data!);
          return response?.data;
        } else {
          console.error(
            "Refresh token not available or user is not authenticated.",
          );
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
    },

    async logout() {
      try {
        const response = await logoutService();

        if (response.status_code === 200) {
          this.$reset();
        }
      } catch (error) {
        console.error("Error logging out:", error);
        this.$reset();
        window.location.href = "/login";
      }
    },

    // Helper methods for access control
    hasAccess(requiredLevel: "SUPER" | "MAIN" | "PROGRAM"): boolean {
      if (this.level === "SUPER") return true;
      if (this.level === "MAIN" && requiredLevel !== "SUPER") return true;
      if (this.level === "PROGRAM" && requiredLevel === "PROGRAM") return true;
      return false;
    },

    // Initialize from stored data if available
    initializeFromStorage() {
      try {
        const stored = localStorage.getItem("auth-store");
        if (stored) {
          const data = JSON.parse(stored) as LoginStore;
          this.setUserAccess(data);
        }
      } catch (error) {
        console.error("Error loading auth from storage:", error);
        this.logout();
      }
    },
  },

  persist: true,
});
