<script lang="ts" setup>
import { Icon } from "@iconify/vue";

const props = withDefaults(
  defineProps<{
    icon?: string;
    class?: string;
  }>(),
  {
    icon: "mdi:home",
    class: "text-[20px] text-primary_gray",
  },
);
</script>

<template>
  <Icon :icon="props.icon" :class="props.class + ' duration-200 ease-linear'" />
</template>
