import { Ref } from "vue";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/vue-query";
import {
  addActiveReward,
  addReward,
  addReward2,
  approveOrRejectReward,
  deleteReward,
  getActivesRewards,
  getAllRewards,
  getAllRewardsPurchased,
  getMemberActiveReward,
  getRewardDetail,
  getRewardPurchasedDetail,
  getRewardSummary,
  getVoucherInBuyVoucher,
  getVouchersByRewardId,
  getVoucherSummaryByRewardId,
} from "@/services/reward";
import { PageQueryType, ResponseType } from "@/types";
import {
  ActiveReward,
  FilterVoucherCategory,
  FilterVoucherOperator,
  MemberActiveReward,
  MemberActiveRewardRedeemStatus,
  Reward,
  RewardDetail,
  RewardPurchased,
  RewardPurchasedDetail,
  RewardSummary,
  RewardVoucherStatus,
  Voucher,
  VoucherInBuyVoucher,
  VoucherSummary,
} from "@/types/reward";
import { useMutationResources } from "./toast-query-client";
import nProgress from "nprogress";
import { getCompanyId } from "@/composables/get-company-id";

export const useGetRewardSummary = () => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<RewardSummary>, Error>({
    queryKey: ["reward", "summary", authCompany],
    queryFn: () => getRewardSummary(),
  });
};

export const useGetAllRewards = (pageQuery: Ref<PageQueryType>) => {
  const authCompany = getCompanyId();
  return useQuery<ResponseType<Reward[]>, Error>({
    queryKey: ["reward", authCompany, pageQuery],
    queryFn: () => getAllRewards(pageQuery.value),
  });
};
export const useGetAllRewardsInfiniteScroll = (
  pageQuery: Ref<PageQueryType>,
) => {
  const authCompany = getCompanyId();
  return useInfiniteQuery<ResponseType<Reward[]>, Error>({
    queryKey: ["reward", "list", authCompany, pageQuery],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getAllRewards({
        ...pageQuery.value,
        page: pageNumber,
      });
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: pageQuery.value.page as number | undefined,
  });
};
export const useGetAllRewardsPurchasedInfiniteScroll = (
  pageQuery: Ref<PageQueryType>,
) => {
  const authCompany = getCompanyId();
  return useInfiniteQuery<ResponseType<RewardPurchased[]>, Error>({
    queryKey: ["reward", "purchased", authCompany, pageQuery],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getAllRewardsPurchased({
        ...pageQuery.value,
        page: pageNumber,
      });
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: pageQuery.value.page as number | undefined,
  });
};

export const useGetRewardPurchasedDetail = (rewardId: string) => {
  return useQuery<ResponseType<RewardPurchasedDetail>, Error>({
    queryKey: ["reward", "purchased", "detail", rewardId],
    queryFn: () => getRewardPurchasedDetail(rewardId),
    refetchOnMount: "always",
    refetchOnWindowFocus: "always",
  });
};

export const useAddReward = () => {
  const { queryClient, toast } = useMutationResources();
  return useMutation({
    mutationFn: addReward,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reward"] });
      toast.success("Reward Created");
    },
    onError: () => {
      console.error("Error adding item");
    },
  });
};
export const useAddReward2 = () => {
  const { queryClient, toast } = useMutationResources();
  return useMutation({
    mutationFn: addReward2,
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ["reward"] });
      nProgress.start();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reward"] });
      toast.success("Reward Created");
    },
    onError: () => {
      console.error("Error adding item");
    },
    onSettled: () => {
      nProgress.done();
    },
  });
};

export const useGetActiveRewards = (pageQuery: Ref<PageQueryType>) => {
  const authCompany = getCompanyId();
  return useInfiniteQuery<ResponseType<ActiveReward[]>, Error>({
    queryKey: ["reward", "actives", authCompany, pageQuery.value],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getActivesRewards({
        ...pageQuery.value,
        page: pageNumber,
      });
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: pageQuery.value.page as number | undefined,
  });
};

export const useAddActiveReward = () => {
  const { queryClient, toast } = useMutationResources();
  return useMutation({
    mutationFn: addActiveReward,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reward", "actives"] });
      toast.success("Reward Created");
    },
    onError: () => {
      console.error("Error adding item");
    },
  });
};

export const useGetRewardDetail = (reward_id: string) => {
  return useQuery<ResponseType<RewardDetail>, Error>({
    queryKey: ["reward", reward_id],
    queryFn: () => getRewardDetail(reward_id),
  });
};

export const useGetVoucherSummaryByRewardId = (reward_id: string) => {
  return useQuery<ResponseType<VoucherSummary>, Error>({
    queryKey: ["reward", reward_id, "vouchers", "summary"],
    queryFn: () => getVoucherSummaryByRewardId(reward_id),
  });
};

export const useGetVouchersByRewardId = (
  reward_id: string,
  pageQuery: Ref<PageQueryType<RewardVoucherStatus>>,
) => {
  return useQuery<ResponseType<Voucher[]>, Error>({
    queryKey: ["reward", reward_id, "vouchers", pageQuery],
    queryFn: () => getVouchersByRewardId(reward_id, pageQuery.value),
  });
};

export const useGetVoucherInBuyVoucher = ({
  filter,
  pageQuery,
}: {
  filter: Ref<{
    category?: FilterVoucherCategory;
    operator?: FilterVoucherOperator;
    price_start?: number;
    price_end?: number;
    is_active?: boolean;
  }>;
  pageQuery: Ref<PageQueryType>;
}) => {
  return useInfiniteQuery<ResponseType<VoucherInBuyVoucher[]>, Error>({
    queryKey: ["reward", "vouchers", "buy", filter, pageQuery],
    queryFn: async ({ pageParam = 1 }) => {
      const pageNumber = pageParam as number | undefined;
      return getVoucherInBuyVoucher({
        category: filter.value?.category,
        operator: filter.value?.operator,
        price_start: filter.value?.price_start,
        price_end: filter.value?.price_end,
        is_active: filter.value?.is_active,
        pageQuery: {
          ...pageQuery.value,
          page: pageNumber,
        },
      });
    },
    getNextPageParam: (data) => {
      return data.meta?.page! < data!.meta?.last_page!
        ? data!.meta?.page! + 1
        : undefined;
    },
    initialPageParam: pageQuery.value.page as number | undefined,
  });
};

export const useGetMemberActiveReward = (
  pageQuery: Ref<PageQueryType>,
  status: MemberActiveRewardRedeemStatus,
  reward_active_id?: Ref<string>,
) => {
  return useQuery<ResponseType<MemberActiveReward[]>, Error>({
    queryKey: [
      "reward",
      "actives",
      "member",
      pageQuery,
      status,
      reward_active_id,
    ],
    queryFn: () =>
      getMemberActiveReward(pageQuery.value, status, reward_active_id?.value),
  });
};

export const useApproveOrRejectReward = (pageQuery: Ref<PageQueryType>) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: approveOrRejectReward,

    // Optimistic update implementation
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: ["reward", "actives", "member", pageQuery.value],
      });

      nProgress.start();

      const previousData = queryClient.getQueryData([
        "reward",
        "actives",
        "member",
        pageQuery.value,
      ]);

      queryClient.setQueryData(
        ["reward", "actives", "member", pageQuery.value, "PENDING", null],
        (old: ResponseType<MemberActiveReward[]>) => {
          return old?.data?.filter(
            (reward: MemberActiveReward) => reward.id !== variables.id,
          );
        },
      );

      return { previousData };
    },

    onError: (error, _, context) => {
      queryClient.setQueryData(
        ["reward", "actives", "member", pageQuery.value, "PENDING", null],
        context?.previousData,
      );
      console.error("Error approving/rejecting reward:", error);
    },

    onSuccess: (_, variables) => {
      toast.success(`Successfully ${variables?.status.toLocaleLowerCase()}`);
      queryClient.invalidateQueries({
        queryKey: ["reward", "actives", "member", pageQuery.value],
      });
    },
    onSettled: () => {
      nProgress.done();
    },
  });
};

export const useDeleteReward = (rewardId: string) => {
  const { queryClient, toast } = useMutationResources();

  return useMutation({
    mutationFn: async () => deleteReward(rewardId),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ["reward", rewardId] });
      nProgress.start();
    },
    onSuccess: () => {
      queryClient.cancelQueries({ queryKey: ["reward", rewardId] });
      queryClient.invalidateQueries({ queryKey: ["reward", "list"] });
      queryClient.invalidateQueries({ queryKey: ["reward", "summary"] });
      toast.success("Reward Deleted");
    },
    onError: () => {
      console.error("Error Deleting Reward");
    },
    onSettled: () => {
      nProgress.done();
    },
  });
};
