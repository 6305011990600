<script setup lang="ts">
import InputPreview from "@/components/input/preview.vue";
import Modal from "@/components/modal/index.vue";
import { MemberDetail } from "@/types/member";

const open = defineModel<boolean>();

interface Props {
  data?: MemberDetail;
}

const props = defineProps<Props>();
</script>

<template>
  <Modal title="View Member" v-model="open" modal-class="max-w-[700px]">
    <div class="my-3 grid w-full grid-cols-2 gap-3">
      <InputPreview title="Full Name">{{ props.data?.name }}</InputPreview>
      <InputPreview title="Phone">{{ props.data?.phone ?? "-" }}</InputPreview>
      <InputPreview title="Email">{{ props.data?.email }}</InputPreview>
      <InputPreview title="Gender">{{
        props.data?.gender ? props.data?.gender : "-"
      }}</InputPreview>
      <InputPreview title="Location">{{
        props.data?.province?.name
      }}</InputPreview>
      <InputPreview title="Groups">
        {{ props.data?.groups?.map((group) => group.name).join(", ") }}
      </InputPreview>
    </div>
  </Modal>
</template>
