<script setup lang="ts">
import { useRouter } from "vue-router";
import { useCreateClientStore } from "@/store/create-client";
import { useStoreClient } from "@/hooks/super-dashboard/client";

import Button from "@/components/button/index.vue";

const clientStore = useCreateClientStore();
const router = useRouter();

const { mutateAsync, isPending, isSuccess } = useStoreClient();

const handleSubmit = async () => {
  clientStore.isDirty = true;
  if (
    clientStore.getErrorStep2?.admin_client.email !== "" ||
    clientStore.getErrorStep2?.admin_client.name !== "" ||
    clientStore.getErrorStep2?.admin_internal.email !== "" ||
    clientStore.getErrorStep2?.admin_internal.name !== "" ||
    clientStore.getErrorStep2?.email_quota !== "" ||
    clientStore.getErrorStep2?.whatsapp_quota !== "" ||
    clientStore.form.admin_client.taken ||
    clientStore.form.admin_internal.taken
  )
    return;

  clientStore.isDirty = false;
  await mutateAsync(clientStore.form);

  if (isSuccess.value) {
    router.push({ name: "super-dashboard.home.index" });
    // clientStore.$reset();
  }
};
</script>

<template>
  <div class="flex flex-row flex-wrap items-center gap-3 lg:flex-nowrap">
    <p>Step</p>
    <Button
      v-if="clientStore.stepFulfilled[0] || clientStore.step == 0"
      text="Client Detail "
      size="sm"
      :icon="
        clientStore.stepFulfilled[0] && clientStore.step !== 0
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="clientStore.step == 0 ? 'stepFulfilledYellow' : 'roleGreen'"
    />
    <Button
      v-if="clientStore.stepFulfilled[1] || clientStore.step == 1"
      text="Mission Objectives"
      size="sm"
      :icon="
        clientStore.stepFulfilled[1] && clientStore.step !== 1
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="clientStore.step == 1 ? 'stepFulfilledYellow' : 'roleGreen'"
    />
    <Button
      v-if="clientStore.stepFulfilled[2] || clientStore.step == 2"
      text="Admin & Notification"
      size="sm"
      :icon="
        clientStore.stepFulfilled[2] && clientStore.step !== 2
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="clientStore.step == 2 ? 'stepFulfilledYellow' : 'roleGreen'"
    />
  </div>
  <div class="flex flex-row flex-wrap items-center gap-3 lg:flex-nowrap">
    <Button
      v-if="clientStore.step > 0"
      :loading="isPending"
      @click="clientStore.backStep"
      text="Previous"
      size="sm"
      variant="outline"
      icon="eva:arrow-ios-back-outline"
    />
    <Button
      v-if="clientStore.step < 2"
      @click="clientStore.nextStep"
      :loading="isPending"
      text="Continue"
      size="sm"
      variant="roleGreen"
      icon="eva:arrow-ios-forward-outline"
      position-icon="right"
      :disabled="clientStore.isNextDisabled"
    />
    <Button
      v-else
      @click="handleSubmit"
      :loading="isPending"
      text="Invite Admin & Publish"
      size="sm"
      variant="roleGreen"
      icon="eva:arrow-ios-forward-outline"
      position-icon="right"
      :disabled="clientStore.isSubmitDisabled"
    />
  </div>
</template>
