<script setup lang="ts">
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

import Button from "@/components/button/index.vue";
import { useAddReward2 } from "@/hooks/reward";
import { useCreateRewardStore } from "@/store/create-reward";
import { useBuyVoucher } from "@/hooks/super-dashboard/reward";

const rewardStore = useCreateRewardStore();
const toast = useToast();
const router = useRouter();

const {
  mutateAsync: mutateAsyncRewardCustom,
  isSuccess: isSuccessRewardCustom,
  isPending: isPendingRewardCustom,
} = useAddReward2();

const {
  mutateAsync: buyVoucherMutate,
  // isSuccess: isSuccessBuy,
  isPending: isPendingBuy,
} = useBuyVoucher();

const submitCustom = async () => {
  rewardStore.setDirty(true);

  if (
    rewardStore.form.custom.title == "" ||
    rewardStore.form.custom.quota == 0 ||
    rewardStore.form.custom.category == "" ||
    rewardStore.form.custom.description == "" ||
    rewardStore.form.custom.terms == ""
  ) {
    toast.error("All fields are required");
    return;
  }

  if (
    rewardStore.form.custom.banner.file == "" ||
    rewardStore.form.custom.banner.path == ""
  ) {
    toast.error("Banner is required");
    return;
  }

  await mutateAsyncRewardCustom(rewardStore.form.custom);

  if (isSuccessRewardCustom.value) {
    router.push({ name: "reward.index" });
    rewardStore.$reset();
  }
};

const nextStep = () => {
  rewardStore.setDirty(true);

  if (rewardStore.getStep == 0 && rewardStore.method == "CUSTOM") {
    submitCustom();
  }
};

const buyVoucher = async () => {
  await buyVoucherMutate(rewardStore.form.buy);
  // if (isSuccessBuy.value) {
  //   // router.push({ name: "reward.index" });
  //   // rewardStore.$reset();
  // }
};
</script>

<template>
  <div class="flex flex-row flex-wrap items-center gap-3 lg:flex-nowrap">
    <p>Step</p>
    <Button
      v-if="rewardStore.stepFulfilled[0] || rewardStore.getStep == 0"
      text="Reward Method"
      size="sm"
      :icon="
        rewardStore.stepFulfilled[0] && rewardStore.getStep !== 0
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="rewardStore.getStep == 0 ? 'stepFullfiled' : 'roleGreen'"
    />
    <Button
      v-if="rewardStore.stepFulfilled[1] || rewardStore.getStep == 1"
      text="Order Details"
      size="sm"
      :icon="
        rewardStore.stepFulfilled[1] && rewardStore.getStep !== 1
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="rewardStore.getStep == 1 ? 'stepFullfiled' : 'roleGreen'"
    />
    <Button
      v-if="rewardStore.stepFulfilled[2] || rewardStore.getStep == 2"
      text="Payment"
      size="sm"
      :icon="
        rewardStore.stepFulfilled[2] && rewardStore.getStep !== 2
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="rewardStore.getStep == 2 ? 'stepFullfiled' : 'roleGreen'"
    />
  </div>
  <div
    v-if="!rewardStore.isXenditSection"
    class="flex flex-row flex-wrap items-center gap-3 lg:flex-nowrap"
  >
    <Button
      v-if="rewardStore.method == 'CUSTOM'"
      @click="nextStep"
      :loading="isPendingRewardCustom"
      text="Create Reward"
      size="sm"
      variant="slate"
      icon="eva:arrow-ios-forward-outline"
      position-icon="right"
    />

    <template v-else-if="rewardStore.method == 'BUY'">
      <Button
        v-if="rewardStore.getStep > 0"
        @click="rewardStore.backStep"
        :loading="isPendingBuy"
        text="Back"
        size="sm"
        variant="slate"
        icon="eva:arrow-ios-back-outline"
      />
      <Button
        v-if="rewardStore.getStep == 2"
        @click="buyVoucher"
        :loading="isPendingBuy"
        text="Payment"
        size="sm"
        variant="slate"
        icon="eva:arrow-ios-forward-outline"
        position-icon="right"
      />
      <Button
        v-else
        @click="rewardStore.nextStep"
        :loading="isPendingBuy"
        text="Continue"
        size="sm"
        variant="slate"
        icon="eva:arrow-ios-forward-outline"
        position-icon="right"
      />
    </template>
  </div>
</template>
