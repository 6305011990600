import { AddVoucherValues } from "@/constants/form/reward";
import axiosInstance from "@/lib/axiosInstance";
import { FilterDateType, PageQueryType, ResponseType } from "@/types";
import { chartDateType } from "@/types/analytics";
import {
  RevenueAnalytics,
  Reward,
  Summary,
  TopPurchase,
  TopPurchaseFilter,
} from "@/types/super-dashboard/reward";

export const getTopPurhaseReward = async (
  by: TopPurchaseFilter,
): Promise<ResponseType<TopPurchase[]>> => {
  const response = await axiosInstance.get(`/rewards/purchases/top/${by}`);
  return response.data;
};
export const getSummary = async (
  date: FilterDateType,
): Promise<ResponseType<Summary>> => {
  const response = await axiosInstance.get("/rewards/purchases/summary", {
    params: date,
  });
  return response.data;
};

export const getRewardSoldAnalytics = async (
  date: FilterDateType,
): Promise<ResponseType<chartDateType[]>> => {
  const response = await axiosInstance.get(
    "/rewards/purchases/analytics/sold",
    {
      params: date,
    },
  );
  return response.data;
};
export const getRevenueAnalytics = async (
  date: FilterDateType,
): Promise<ResponseType<RevenueAnalytics[]>> => {
  const response = await axiosInstance.get(
    "/rewards/purchases/analytics/revenue",
    {
      params: date,
    },
  );
  return response.data;
};

export const buyVoucher = async (
  data: any,
): Promise<ResponseType<{ invoice_url: string }>> => {
  const response = await axiosInstance.post("/rewards/purchase", {
    ...data,
  });

  return response.data;
};
export const getList = async (
  data: PageQueryType,
  status: Reward["status"],
): Promise<ResponseType<Reward[]>> => {
  const response = await axiosInstance.get("/rewards/purchases", {
    params: {
      ...data,
      status,
    },
  });

  return response.data;
};

export const approveReward = async ({
  id,
  deposit_id,
  proof_file,
}: {
  id: string;
  deposit_id: string;
  proof_file: File;
}): Promise<ResponseType> => {
  const response = await axiosInstance.postForm(
    `/rewards/purchases/${id}/approve`,
    {
      deposit_id,
      proof_file,
    },
  );

  return response.data;
};

export async function addVoucher(data: AddVoucherValues) {
  const response = await axiosInstance.postForm("/rewards/vouchers", data);

  return response.data;
}

export async function EditVoucher(data: AddVoucherValues, id: string) {
  const response = await axiosInstance.postForm(
    `/rewards/vouchers/${id}`,
    data,
  );

  return response.data;
}
