<script setup lang="ts">
import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";

defineProps<Props>();

const badgeVariants = cva(
  "inline-flex items-center gap-x-1.5 rounded-md border px-2 py-1 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 w-full",
  {
    variants: {
      variant: {
        purple: "bg-primary_purple/20 text-primary_black",
        green: "bg-primary_greed text-primary_black",
        yellow: "bg-primary_yellow/20 text-primary_black",
        red: "bg-primary_red/20 text-primary_black",
        blue: "bg-primary_transparant_blue text-primary_black",
        notification:
          "bg-primary_red text-white rounded-full text-nowrap w-fit !text-[10px] !border-none",
        sky: "bg-sky-500/20 text-sky-500",
        default: "bg-primary_slate text-primary_black",
        pro: "bg-primary_yellow/20 text-primary_yellow",
        white: "bg-primary_white text-primary_black",
        whiteNoBorder: "bg-primary_white text-primary_black !border-none",
        transparant: "bg-white text-primary_black",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface BadgeVariantProps extends VariantProps<typeof badgeVariants> {}

interface Props {
  variant?: BadgeVariantProps["variant"];
  text?: string;
}
</script>

<template>
  <div :class="cn(badgeVariants({ variant }), $attrs.class ?? '')">
    <slot v-if="$slots.default" />
    <span v-else class="capitalize">{{ text }}</span>
  </div>
</template>
