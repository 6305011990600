<script setup lang="ts">
import { useAuthStore } from "@/store/auth-store";
import { Icon } from "@iconify/vue";
import { cn } from "@/lib/utils";
import { SidebarMenu, SidebarOthers } from "@/constants";
import { useGetServer } from "@/composables/get-server";

import Logo from "../fragments/logo.vue";

import Button from "@/components/button/index.vue";
import BadgeCompany from "../fragments/badge-company.vue";
import { Environment } from "@/constants";
import { useLayoutStore } from "@/store/layout";

const authStore = useAuthStore();
const badgeLabel = useGetServer();
const layoutStore = useLayoutStore();

const handleClickBack = () => {
  window.location.href = "/super-dashboard/home";
};
</script>

<template>
  <div
    v-if="layoutStore.isSidebarOpen"
    class="fixed inset-0 z-50 bg-black/50 md:hidden"
    @click="layoutStore.toggleSidebar"
  ></div>

  <aside
    :class="
      cn(
        'fixed left-0 top-0 z-20 flex h-full min-h-screen flex-col gap-3 border-r border-primary_slate bg-white transition-all duration-300 ease-in-out',
        layoutStore.isSidebarOpen
          ? 'z-[60] w-[250px] translate-x-0 px-4 md:px-2'
          : '-translate-x-full md:w-[200px] md:translate-x-0 md:px-2 lg:w-[250px] lg:px-4',
      )
    "
  >
    <Logo />
    <Button
      v-if="authStore.level == 'SUPER'"
      @click="handleClickBack"
      variant="whiteNoBorder"
      size="sidebar"
      text="All Client"
      icon="eva:arrow-ios-back-outline"
      icon-class="text-xl text-primary_gray"
    />

    <p>Main Menu</p>
    <div class="flex flex-col gap-3">
      <template v-for="item in SidebarMenu" :key="item.id">
        <router-link
          v-if="!item.hidden"
          activeClass="bg-gradient-to-r from-white from-50% via-slate-200 via-90% to-slate-300 border-r-4 border-primary_blue to-100% font-bold !text-primary_blue"
          class="relative flex flex-row items-center gap-3 rounded-lg px-2 py-1.5 text-primary_gray duration-150 ease-in"
          :to="item.link"
          v-slot="{ isActive }"
        >
          <component
            v-if="item.customIcon"
            :is="item.customIcon"
            :class="cn(isActive && 'fill-primary_blue')"
          />

          <Icon v-else-if="item.icon" class="text-2xl" :icon="item.icon" />

          <h3
            :class="
              isActive ||
              (item.name === 'Home' &&
                $route.path.split('/').includes('client'))
                ? 'relative text-base font-bold text-primary_blue'
                : 'relative text-base font-normal text-primary_gray'
            "
          >
            <!-- <span
              class="absolute -right-3 top-1 h-2 w-2 rounded-full bg-primary_red"
              v-if="
                item.name == 'Mission' &&
                proposalNotif?.data &&
                reviewNotif?.data &&
                (proposalNotif.data > 0 || reviewNotif?.data > 0)
              "
            ></span> -->
            {{ item.name }}
          </h3>
        </router-link>
      </template>
    </div>
    <p class="mt-3">Others</p>
    <div class="flex flex-col gap-3">
      <template v-for="item in SidebarOthers" :key="item.id">
        <router-link
          v-if="!item.hidden"
          activeClass="bg-gradient-to-r from-white from-50% via-slate-200 via-90% to-slate-300 border-r-4 border-primary_blue to-100% font-bold !text-primary_blue"
          :class="
            cn(
              'relative flex flex-row items-center gap-3 rounded-lg px-2 py-1.5 text-primary_gray duration-150 ease-in',
              $route.path.split('/').includes('setting') &&
                'border-r-4 border-primary_blue bg-gradient-to-r from-white from-50% via-slate-200 via-90% to-slate-300 to-100% font-bold !text-primary_blue',
            )
          "
          :to="item.link"
          v-slot="{ isActive }"
        >
          <Icon class="text-2xl" :icon="item.icon" />

          <h3
            :class="
              isActive || $route.path.split('/').includes('setting')
                ? 'relative text-base font-bold text-primary_blue'
                : 'relative text-base font-normal text-primary_gray'
            "
          >
            {{ item.name }}
          </h3>
        </router-link>
      </template>
    </div>

    <BadgeCompany />
  </aside>

  <div
    v-if="Environment !== 'production' && layoutStore.isSidebarOpen"
    class="fixed left-[-68px] top-[18px] z-20"
  >
    <div
      :class="`w-[200px] -rotate-45 bg-${authStore.server.color} py-1 text-center text-sm text-white`"
    >
      {{ badgeLabel }}
    </div>
  </div>
</template>
