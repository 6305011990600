import { defineStore } from "pinia";
import { useAuthStore } from "./auth-store";
import { Group } from "@/types/group";
import { Member } from "@/types/member";
import { toast } from "vue3-toastify";
import moment from "moment";
import {
  Mission,
  MissionAdditionalReward,
  MissionApprovalType,
  MissionObjective,
  MissionResearchType,
  PointResearchType,
  MissionPostContentType,
} from "@/types/mission";
import { image_url } from "@/constants/image";
import { MissionLabel } from "@/constants/mission";
import router from "@/config/route";
import { MissionCrowdsourcingType } from "@/types/mission-crowdsourcing";
import { MissionReactType } from "@/types/mission-react";
import isSocialMediaValid from "@/lib/checkLink";
import { useScrollToTop } from "@/composables/scroll-to-top";

export type ParticipantType = "ALL" | "MEMBER" | "GROUP";
export type ReferenceType = "IMAGE" | "VIDEO" | "DOCUMENT";

export type MissionEventType = "OFFLINE" | "ONLINE";

export type ReactDataType = {
  type: MissionReactType;
  link: string;
  is_like?: boolean;
  is_comment?: boolean;
  is_share?: boolean;
  is_retweet?: boolean;
  is_subscribe?: boolean;
  playstore_link: string | null;
  appstore_link: string | null;
  like_point: number;
  comment_point: number;
  share_point: number;
  retweet_point: number;
  subscribe_point: number;
  playstore_point: number;
  appstore_point: number;
};

export type SurveyDataType = {
  question: string;
  is_required: boolean;
};

export type PollingDataType = {
  question: string;
  is_required: boolean;
  options: string[];
};

const defaultStartDate = moment()
  .set("hour", 0)
  .set("minute", 0)
  .format("YYYY-MM-DD HH:mm");
const defaultEndDate = moment()
  .set("hour", 23)
  .set("minute", 59)
  .add(1, "days")
  .format("YYYY-MM-DD HH:mm");

export const useCreateMissionStore = function () {
  const companyId = useAuthStore().getCompanyId;
  const programId = router.currentRoute.value.params?.programId;
  const store = defineStore(
    "create-mission-store-" + companyId + "-" + programId,
    {
      state: () => ({
        openModalExit: false,
        loadingFetch: false,
        step: 0 as number,
        maxReachedStep: 0 as number,
        isSubmitted: false,
        dirty: false,
        objective: null as MissionObjective | null,
        missionType: null as Mission | null,
        stepFulfilled: {
          0: false,
          1: false,
          2: false,
        },
        filter: {
          member_pro: false,
        },
        form: {
          title: "" as string,
          brief: {
            text: "" as string,
            path: "" as string,
            file: "" as string,
          },
          banner: {
            path: "/default/banner" as string,
            file: "bg1.png" as string,
            is_default: true,
          },
          additional_point: [] as MissionAdditionalReward[],
          additional_reward: [] as MissionAdditionalReward[],
          start_at: defaultStartDate as string,
          end_at: defaultEndDate as string,
          approval: "MANUAL" as MissionApprovalType,
          notif_app: true,
          notif_wa: false,
          notif_email: false,
          participant_type: "ALL" as ParticipantType,
          participantsGroups: [] as Group[],
          participantsMembers: [] as Member[],
          event: {
            type: "" as MissionEventType | string,
            modal: false,
            reference: {
              type: "IMAGE" as ReferenceType,
              path: "" as string,
              file: "" as string,
            },
            point: 0,
            long: 0,
            lat: 0,
            address: "",
            radius: 50,
          },
          post_content: {
            type: "" as MissionPostContentType,
            caption: "" as string,
            reference: {
              type: "VIDEO" as ReferenceType,
              path: "" as string,
              file: "" as string,
            },
            duration: 0,
            is_caption: false,
            is_script: false,
            is_instagram: false,
            is_instagram_story: false,
            is_twitter: false,
            is_tiktok: false,
            is_youtube: false,
            is_instagram_auto: false,
            is_twitter_auto: false,
            is_tiktok_auto: false,
            post_point: {
              point_script: 0,
              point_post: 0,
              point_instagram: 0,
              point_instagram_story: 0,
              point_twitter: 0,
              point_tiktok: 0,
            },
            share_point: {
              point_instagram: 0,
              point_instagram_story: 0,
              point_twitter: 0,
              point_tiktok: 0,
            },
            approval_script: "MANUAL" as MissionApprovalType,
            approval_share: "MANUAL" as MissionApprovalType,
          },
          watch: {
            youtube_url: "",
            youtube_duration: 0,
            valid_url: false,
            duration: 30,
            point: 0,
          },
          crowdsourcing: {
            type: null as MissionCrowdsourcingType | null,
            reference: {
              type: "DOCUMENT" as ReferenceType,
              path: "" as string,
              file: "" as string,
            },
            point: 10,
          },
          react: [] as ReactDataType[],
          defaultReactData: {
            instagram: {
              like_point: 999,
              comment_point: 999,
              share_point: 999,
            },
            twitter: {
              like_point: 999,
              comment_point: 999,
              share_point: 999,
            },
            tiktok: {
              like_point: 999,
              comment_point: 999,
              share_point: 999,
            },
            youtube: {
              like_point: 999,
              comment_point: 999,
              share_point: 999,
            },
            linkedin: {
              like_point: 999,
              comment_point: 999,
              share_point: 999,
            },
            custom: {
              like_point: 999,
              comment_point: 999,
              share_point: 999,
            },
          },
          research: {
            type: "" as MissionResearchType,
            survey: {
              point: 0,
              point_answer: 0,
              point_type: "PER_MISSION" as PointResearchType,
              surveys: [
                {
                  question: "",
                  is_required: false,
                },
              ] as SurveyDataType[],
            },
            polling: {
              point: 0,
              point_answer: 0,
              point_type: "PER_MISSION" as PointResearchType,
              pollings: [
                {
                  question: "",
                  is_required: false,
                  options: ["", ""],
                },
              ] as PollingDataType[],
            },
          },
        },
      }),
      getters: {
        getDirty(state) {
          return state.dirty;
        },
        getLoadingFetch(state) {
          return state.loadingFetch;
        },
        getStep(state) {
          return state.step;
        },
        getObjective(state) {
          return state.objective;
        },
        getStep0Errors(state) {
          const errors = {
            title: "",
            brief: {
              text: "",
            },
            watch: {
              youtube_url: "",
              youtube_duration: "",
            },
            crowdsourcing: {
              reference: "",
            },
            react: {
              reactions: "",
            },
            post_content: {
              reference: "",
              caption: "",
              duration: "",
              social_media: "",
            },
            event: {
              address: "",
            },
          };
          if (!state.dirty) return errors;

          if (state.form.title == "") {
            errors.title = "Title must be filled";
          }

          if (state.form.brief.text == "") {
            errors.brief.text = "Brief must be filled";
          }

          switch (state.missionType) {
            case "EVENT":
              if (
                state.form.event.address == "" ||
                this.form.event.lat == 0 ||
                this.form.event.long == 0
              ) {
                errors.event.address = "Address must be filled";
              }
              break;
            case "WATCH":
              if (state.form.watch.youtube_url == "") {
                errors.watch.youtube_url = "Link must be filled";
              }

              if (!state.form.watch.valid_url) {
                errors.watch.youtube_url = "Invalid Link";
              }

              if (
                state.form.watch.youtube_duration < state.form.watch.duration
              ) {
                errors.watch.youtube_duration =
                  // "Durasi harus lebih besar dari durasi video";
                  "Duration must be greater than video duration";
              }

              break;

            case "ARTICLE":
              if (
                state.form.crowdsourcing.reference.path == "" ||
                state.form.crowdsourcing.reference.file == ""
              ) {
                errors.crowdsourcing.reference = "FIle must be filled";
              }
              break;

            case "POST":
              if (
                !this.form.post_content.is_instagram &&
                !this.form.post_content.is_instagram_story &&
                !this.form.post_content.is_twitter &&
                !this.form.post_content.is_tiktok
              ) {
                errors.post_content.social_media =
                  "Social media must be filled";
              }

              if (state.form.post_content.type == "SHARE") {
                if (
                  state.form.post_content.reference.path == "" ||
                  state.form.post_content.reference.file == ""
                ) {
                  errors.post_content.reference = "Reference must be filled";
                }
              }

              if (
                state.form.post_content.reference.type == "VIDEO" &&
                state.form.post_content.duration == 0
              ) {
                errors.post_content.duration = "Duration must be filled";
              }

              if (
                state.form.post_content.is_caption &&
                state.form.post_content.caption == ""
              ) {
                errors.post_content.caption = "Caption must be filled";
              }
              break;
            default:
              break;
          }

          return errors;
        },
        getMissionType(state) {
          return state.missionType;
        },
        getParticipantType(state) {
          return state.form.participant_type;
        },
        getMemberProFilter(state) {
          return state.filter.member_pro;
        },
        getParticipantsGroups(state) {
          return state.form.participantsGroups;
        },
        getParticipantsMembers(state) {
          return state.form.participantsMembers;
        },
        getFilter(state) {
          return state.filter;
        },
        getIsValidYoutubeUrl(state) {
          return state.form.watch.valid_url;
        },
        isIncludeHighestRank(state) {
          let valid = false;

          state.form.additional_reward.forEach((item) => {
            if (item.condition == "HIGHEST_ENGAGEMENT" && item.checked)
              valid = true;
            if (item.condition == "HIGHEST_REACH" && item.checked) valid = true;
          });

          return valid;
        },
        isPostMission(state) {
          return state.missionType == "POST";
        },
        getBannerUrl(state) {
          if (state.form.banner.path == "" || state.form.banner.file == "")
            return "";

          return `${image_url}${state.form.banner.path}/${state.form.banner.file}`;
        },
        getPointPreview(state): number {
          let point = 0;
          switch (state.missionType) {
            case "POST":
              if (this.form.post_content.type == "POST") {
                point += this.form.post_content.post_point.point_post;

                if (this.form.post_content.is_script) {
                  point += this.form.post_content.post_point.point_script;
                }

                if (this.form.post_content.is_instagram) {
                  point += this.form.post_content.post_point.point_instagram;
                }
                if (this.form.post_content.is_instagram_story) {
                  point +=
                    this.form.post_content.post_point.point_instagram_story;
                }
                if (this.form.post_content.is_twitter) {
                  point += this.form.post_content.post_point.point_twitter;
                }
                if (this.form.post_content.is_tiktok) {
                  point += this.form.post_content.post_point.point_tiktok;
                }
              } else if (this.form.post_content.type == "SHARE") {
                if (this.form.post_content.is_instagram) {
                  point += this.form.post_content.share_point.point_instagram;
                }
                if (this.form.post_content.is_instagram_story) {
                  point +=
                    this.form.post_content.share_point.point_instagram_story;
                }
                if (this.form.post_content.is_twitter) {
                  point += this.form.post_content.share_point.point_twitter;
                }
                if (this.form.post_content.is_tiktok) {
                  point += this.form.post_content.share_point.point_tiktok;
                }
              }

              return point;
            case "SHARE":
              return 5;
            case "WATCH":
              return this.form.watch.point;
            case "REACT":
              state.form.react.forEach((item) => {
                if (item.is_like) {
                  point += item.like_point;
                }

                if (item.is_comment) {
                  point += item.comment_point;
                }

                if (item.is_share) {
                  point += item.share_point;
                }
              });
              return point;
            case "ARTICLE":
              return this.form.crowdsourcing.point;
            case "EVENT":
              return this.form.event.point;
            case "RESEARCH":
              if (this.form.research.type == "SURVEY") {
                if (this.form.research.survey.point_type == "PER_ANSWER") {
                  return (
                    this.form.research.survey.point *
                    this.form.research.survey.surveys.length
                  );
                }

                return this.form.research.survey.point;
              }

              if (this.form.research.type == "POLLING") {
                if (this.form.research.polling.point_type == "PER_ANSWER") {
                  return (
                    this.form.research.polling.point *
                    this.form.research.polling.pollings.length
                  );
                }

                return this.form.research.polling.point;
              }

              return 1000000;
            default:
              return 10;
          }
        },
        getMissionTypePreview(state) {
          switch (state.missionType) {
            case "POST":
              if (state.form.post_content.type == "POST")
                return "Create Content";

              return "Share Content";

            case "SHARE":
              return "Share Content";
            case "RESEARCH":
              return "Survey & Polling";
            default:
              return MissionLabel[state.missionType!];
          }
        },
        getTotalParticipant(state) {
          switch (state.form.participant_type) {
            case "MEMBER":
              return state.form.participantsMembers.length;
            case "GROUP":
              return state.form.participantsGroups.reduce((total, group) => {
                return total + group.member_total;
              }, 0);
            default:
              return 0;
          }
        },
        getMinimumStartDate(): Date {
          return moment()
            .set({
              hour: 0,
              minute: 0,
              second: 0,
            })
            .toDate();
        },
        getMinimumEndDate(): Date {
          if (this.isIncludeHighestRank || this.isPostMission) {
            return moment(this.form.start_at)
              .add(7, "days")
              .set({ hour: 23, minute: 59, second: 59 })
              .toDate();
          }
          return moment(this.form.start_at)
            .add(1, "days")
            .set({ hour: 23, minute: 59, second: 59 })
            .toDate();
        },

        getPostContentType(state) {
          return state.form.post_content.type;
        },

        haveChoosedAssignedMission(state): boolean {
          switch (state.missionType) {
            case "EVENT":
              if (state.form.event.type == "") {
                return false;
              }
              break;
            case "POST":
              if (state.form.post_content.type == "") {
                return false;
              }
              break;
            case "ARTICLE":
              if (state.form.crowdsourcing.type == null) {
                return false;
              }
              break;
            case "REACT":
              if (state.form.react.length == 0) {
                return false;
              }
              break;
            case "RESEARCH":
              if (state.form.research.type == null) {
                return false;
              }
              break;
            case "WATCH":
              return true;

            default:
              return false;
          }

          return true;
        },
        isCurrentStepValid(state): boolean {
          // For step 0
          if (state.step === 0) {
            if (!state.form.title || !state.form.brief.text) return false;

            switch (state.missionType) {
              case "EVENT":
                if (
                  !state.form.event.address ||
                  state.form.event.lat === 0 ||
                  state.form.event.long === 0
                )
                  return false;
                break;
              case "WATCH":
                if (
                  !state.form.watch.valid_url ||
                  state.form.watch.youtube_duration < state.form.watch.duration
                )
                  return false;
                break;
              case "ARTICLE":
                if (
                  !state.form.crowdsourcing.type ||
                  !state.form.crowdsourcing.reference.path ||
                  !state.form.crowdsourcing.reference.file
                )
                  return false;
                break;
              case "POST":
                if (!state.form.post_content.type) return false;
                if (state.form.post_content.type === "SHARE") {
                  if (
                    !state.form.post_content.is_instagram &&
                    !state.form.post_content.is_instagram_story &&
                    !state.form.post_content.is_twitter &&
                    !state.form.post_content.is_tiktok
                  )
                    return false;
                  if (
                    !state.form.post_content.reference.path ||
                    !state.form.post_content.reference.file
                  )
                    return false;
                }
                if (
                  state.form.post_content.is_caption &&
                  !state.form.post_content.caption
                )
                  return false;
                break;
              case "REACT":
                if (state.form.react.length === 0) return false;
                break;
            }
            return true;
          }

          // For step 1
          if (state.step === 1) {
            switch (state.form.participant_type) {
              case "MEMBER":
                if (state.form.participantsMembers.length === 0) return false;
                break;
              case "GROUP":
                if (state.form.participantsGroups.length === 0) return false;
                break;
            }
            return true;
          }

          // For step 2
          if (state.step === 2) {
            if (!state.form.title) return false;
            if (this.isIncludeHighestRank || this.isPostMission) {
              if (
                moment(state.form.end_at).diff(
                  moment(state.form.start_at),
                  "days",
                ) < 7
              )
                return false;
            } else {
              if (
                moment(state.form.end_at).diff(
                  moment(state.form.start_at),
                  "days",
                ) < 1
              )
                return false;
            }
            return true;
          }

          return false;
        },
      },
      actions: {
        setObjective(objective: MissionObjective) {
          this.objective = objective;
          this.missionType = null;
          this.resetAdditional();
        },
        resetAdditional() {
          this.form.additional_point = [];
          this.form.additional_reward = [];
        },

        setLoadingFetch(value: boolean) {
          this.loadingFetch = value;
        },
        openModal() {
          this.openModalExit = true;
        },
        closeModal() {
          this.openModalExit = false;
        },
        setMissionType(type: Mission, programEndDate: string) {
          if (type == "WATCH" || type == "RESEARCH") {
            this.form.approval = "AUTO";
          }

          if (type == "POST" || type == "SHARE") {
            if (moment(programEndDate).diff(moment(), "days") < 7) {
              toast.error("Program end date must be at least 7 days from now");
              return;
            }
          }

          if (type == "POST") {
            this.form.end_at = moment(this.form.start_at)
              .set("hour", 23)
              .set("minute", 59)
              .add(7, "days")
              .format("YYYY-MM-DD HH:mm");
          } else {
            this.form.end_at = this.form.end_at = moment(this.form.start_at)
              .set("hour", 23)
              .set("minute", 59)
              .add(1, "days")
              .format("YYYY-MM-DD HH:mm");
          }

          this.missionType = type;
        },
        nextStep() {
          switch (this.step) {
            case 0:
              this.validateStep0();
              break;
            case 1:
              this.validateStep1();
              break;
            case 2:
              this.validateStep2();
              break;
          }

          if (this.step > this.maxReachedStep) {
            this.maxReachedStep = this.step;
          }

          useScrollToTop();
        },
        formatBannerSrc(path: string, file: string) {
          return `${image_url + path}/${file}`;
        },
        validateReward() {
          let valid = true;
          this.form.additional_reward.forEach((item) => {
            if (item.checked && item.reward_id == "") {
              valid = false;
            }
          });

          return valid;
        },
        validateAdditionalPoint() {
          let valid = true;
          this.form.additional_point.forEach((item) => {
            if (item.checked && item.point == 0) {
              valid = false;
            }
          });

          return valid;
        },
        validateStep0() {
          this.dirty = true;
          let isValid = true;

          if (this.form.title == "") {
            toast.error("Title must be filled");
            isValid = false;
          }

          const validateAdditionalPoint = this.validateAdditionalPoint();
          if (!validateAdditionalPoint) {
            toast.error("Additional Point must be filled");
            isValid = false;
          }

          const validateReward = this.validateReward();
          if (!validateReward) {
            toast.error("Reward must be filled");
            isValid = false;
          }

          if (this.form.brief.text == "") {
            toast.error("Brief must be filled");
            isValid = false;
          }

          switch (this.missionType) {
            case "EVENT":
              if (
                this.form.event.address == "" ||
                this.form.event.lat == 0 ||
                this.form.event.long == 0
              ) {
                toast.error('Please select "Address" and "Location"');
                return;
              }
              break;
            case "WATCH":
              if (!this.form.watch.valid_url) {
                toast.error("Invalid Link");
                this.form.watch.youtube_url = "";
                isValid = false;
              }

              if (this.form.watch.youtube_duration < this.form.watch.duration) {
                toast.error("Duration must be greater than video duration");
                isValid = false;
              }
              break;
            case "ARTICLE":
              if (this.form.crowdsourcing.type == null) {
                toast.error("Crowdsourcing type must be filled");
                isValid = false;
              }

              if (
                this.form.crowdsourcing.reference.path == "" ||
                this.form.crowdsourcing.reference.file == ""
              ) {
                toast.error("Crowdsourcing Reference must be filled");
                isValid = false;
              }
              break;
            case "REACT":
              if (this.form.react.length == 0) {
                toast.error("Please select at least one Platforms");
                isValid = false;
              }

              let valid = true;
              this.form.react.forEach((item) => {
                if (item.link == "") {
                  toast.error("Link Must be Filled");
                  valid = false;
                  return;
                }

                if (!isSocialMediaValid(item.link, item.type)) {
                  toast.error("Link Not Valid");
                  valid = false;
                  return;
                }

                if (
                  !item.is_comment &&
                  !item.is_like &&
                  !item.is_retweet &&
                  !item.is_share &&
                  !item.is_subscribe
                ) {
                  console.log(item);
                  toast.error("Please Choose at least one reaction");
                  valid = false;
                }
              });

              if (!valid) return;
              break;

            case "POST":
              if (this.form.post_content.type == null) {
                toast.error("At Least choose one Post Content Type");
                isValid = false;
              }

              if (
                this.form.post_content.reference.type == "VIDEO" &&
                this.form.post_content.duration == 0
              ) {
                toast.error("Duration must be filled");
                isValid = false;
              }

              if (this.form.post_content.type == "SHARE") {
                if (
                  !this.form.post_content.is_instagram &&
                  !this.form.post_content.is_instagram_story &&
                  !this.form.post_content.is_twitter &&
                  !this.form.post_content.is_tiktok
                ) {
                  toast.error("At least choose one social media");
                  isValid = false;
                }
                if (
                  this.form.post_content.reference.path == "" ||
                  this.form.post_content.reference.file == ""
                ) {
                  toast.error("Reference must be filled");
                  isValid = false;
                }
              } else if (this.form.post_content.type == "POST") {
                if (
                  !this.form.post_content.is_instagram &&
                  !this.form.post_content.is_twitter &&
                  !this.form.post_content.is_tiktok
                ) {
                  toast.error("At least choose one social media");
                  isValid = false;
                }
              }

              if (
                this.form.post_content.caption == "" &&
                this.form.post_content.is_caption
              ) {
                toast.error("Caption must be filled");
                isValid = false;
              }
              break;

            case "RESEARCH":
              if (this.form.research.type == null) {
                toast.error("At Least choose one data collection media");
                isValid = false;
              }

              if (this.form.research.type == "SURVEY") {
                const questionSurveyValid =
                  this.form.research.survey.surveys.every((item) => {
                    return item.question !== "";
                  });

                if (!questionSurveyValid) {
                  toast.error("Please fill all question");

                  return;
                }
              }

              if (this.form.research.type == "POLLING") {
                const questionPollingValid =
                  this.form.research.polling.pollings.every((item) => {
                    return (
                      item.question !== "" &&
                      item.options.length > 0 &&
                      item.options.every((option) => option !== "")
                    );
                  });

                if (!questionPollingValid) {
                  toast.error("Please fill all question");

                  return;
                }
              }

              break;
          }

          // Set the stepFulfilled flag based on validation result
          this.stepFulfilled[0] = isValid;

          // Only proceed to next step if validation passed
          if (isValid && this.step === 0) {
            this.step = 1;
            useScrollToTop();
          }

          return isValid;
        },
        validateStep1() {
          let isValid = true;
          switch (this.form.participant_type) {
            case "MEMBER":
              if (this.form.participantsMembers.length == 0) {
                toast.error("Please select at least one member");
                isValid = false;
              }
              break;
            case "GROUP":
              if (this.form.participantsGroups.length == 0) {
                toast.error("Please select at least one group");
                isValid = false;
              }
              break;
          }

          // Set the stepFulfilled flag based on validation result
          this.stepFulfilled[1] = isValid;

          // Only proceed to next step if validation passed
          if (isValid && this.step === 1) {
            this.step = 2;
            useScrollToTop();
          }

          return isValid;
        },
        validateStep2() {
          let isValid = true;

          if (this.form.title == "") {
            toast.error("Please enter title");
            isValid = false;
          }

          if (this.isIncludeHighestRank || this.isPostMission) {
            if (moment(this.form.end_at).diff(moment(this.form.start_at)) < 7) {
              toast.error("Minimum duration Post Mission is 7 days");
              isValid = false;
            }
          } else {
            if (moment(this.form.start_at).diff(moment(this.form.end_at)) < 1) {
              toast.error("Minimum duration is 1 day");
              isValid = false;
            }
          }

          // Set the stepFulfilled flag based on validation result
          this.stepFulfilled[2] = isValid;

          return isValid;
        },
        backStep() {
          this.step--;
        },
        setParticipantType(type: ParticipantType) {
          this.form.participant_type = type;
        },

        setMemberProFilter(value: boolean) {
          this.filter.member_pro = value;
        },
        setParticipantGroup(participant: Group) {
          if (
            this.form.participantsGroups.find((p) => p.id === participant.id)
          ) {
            this.form.participantsGroups = this.form.participantsGroups.filter(
              (p) => p.id !== participant.id,
            );
          } else {
            this.form.participantsGroups.push(participant);
          }
        },
        setParticipantMember(participant: Member) {
          if (
            this.form.participantsMembers.find((p) => p.id === participant.id)
          ) {
            this.form.participantsMembers =
              this.form.participantsMembers.filter(
                (p) => p.id !== participant.id,
              );
          } else {
            this.form.participantsMembers.push(participant);
          }
        },
        setYoutubeValidity(valid: boolean) {
          this.form.watch.valid_url = valid;
        },
        setYoutubeDuration(duration: number) {
          this.form.watch.youtube_duration = duration;
        },
        setCrowdsourcingType(type: MissionCrowdsourcingType) {
          this.form.crowdsourcing.type = type;
        },
        clearCrowdsourcingReference() {
          this.form.crowdsourcing.reference.path = "";
          this.form.crowdsourcing.reference.file = "";
        },
        clearPostContentReference() {
          this.form.post_content.reference.path = "";
          this.form.post_content.reference.file = "";
        },
        getIsReact(type: MissionReactType) {
          return this.form.react.some((item) => item.type == type);
        },
        setIsReact(type: MissionReactType) {
          if (this.form.react.some((item) => item.type === type)) {
            this.form.react = this.form.react.filter(
              (item) => item.type !== type,
            );
            return;
          }

          this.form.react = [this.getDefaultReactData(type, "")];
        },
        getDefaultReactData(type: MissionReactType, link: string) {
          let like_point = 0;
          let comment_point = 0;
          let share_point = 0;

          if (type == "INSTAGRAM") {
            like_point = this.form.defaultReactData.instagram.like_point;
            comment_point = this.form.defaultReactData.instagram.comment_point;
            share_point = this.form.defaultReactData.instagram.share_point;
          } else if (type == "TWITTER") {
            like_point = this.form.defaultReactData.twitter.like_point;
            comment_point = this.form.defaultReactData.twitter.comment_point;
            share_point = this.form.defaultReactData.twitter.share_point;
          } else if (type == "TIKTOK") {
            like_point = this.form.defaultReactData.tiktok.like_point;
            comment_point = this.form.defaultReactData.tiktok.comment_point;
            share_point = this.form.defaultReactData.tiktok.share_point;
          } else if (type == "YOUTUBE") {
            like_point = this.form.defaultReactData.youtube.like_point;
            comment_point = this.form.defaultReactData.youtube.comment_point;
            share_point = this.form.defaultReactData.youtube.share_point;
          } else if (type == "LINKEDIN") {
            like_point = this.form.defaultReactData.linkedin.like_point;
            comment_point = this.form.defaultReactData.linkedin.comment_point;
            share_point = this.form.defaultReactData.linkedin.share_point;
          } else if (type == "CUSTOM") {
            like_point = this.form.defaultReactData.custom.like_point;
            comment_point = this.form.defaultReactData.custom.comment_point;
            share_point = this.form.defaultReactData.custom.share_point;
          }

          return {
            type: type,
            link: link,
            is_like: true,
            is_comment: false,
            is_share: false,
            is_retweet: false,
            is_subscribe: false,
            playstore_link: null,
            appstore_link: null,
            like_point: like_point,
            comment_point: comment_point,
            share_point: share_point,
            retweet_point: 999,
            subscribe_point: 999,
            playstore_point: 999,
            appstore_point: 999,
          };
        },

        setPostContentType(type: MissionPostContentType) {
          if (this.form.post_content.type == type) {
            this.form.post_content.type = "";
            return;
          }

          if (type == "POST") {
            this.form.post_content.is_instagram_story = false;
          }

          this.clearPostContentReference();

          this.form.post_content.type = type;
        },
        togglePostContentCaption() {
          this.form.post_content.is_caption =
            !this.form.post_content.is_caption;

          this.form.post_content.caption = "";
        },
        setEventType(event: MissionEventType) {
          if (this.form.event.type == event) {
            this.form.event.type = "";
            return;
          }
          this.form.event.type = event;
        },
        clearEventReference() {
          this.form.event.reference.path = "";
          this.form.event.reference.file = "";
        },
        setLocation({
          lat,
          long,
          address,
        }: {
          lat: number;
          long: number;
          address?: string;
        }) {
          if (address) {
            this.form.event.address = address;
          }
          this.form.event.lat = lat;
          this.form.event.long = long;
        },
        openModalMap() {
          this.form.event.modal = true;
        },
        setResearchType(type: MissionResearchType) {
          if (this.form.research.type == type) {
            this.form.research.type = "";
            return;
          }

          this.form.research.type = type;
        },
        goToStep(step: number) {
          // If trying to go to a higher step
          if (step > this.step) {
            this.dirty = true;

            // First validate the current step
            let currentStepValid = false;
            switch (this.step) {
              case 0:
                this.validateStep0();
                currentStepValid = this.stepFulfilled[0];
                break;
              case 1:
                this.validateStep1();
                currentStepValid = this.stepFulfilled[1];
                break;
              case 2:
                this.validateStep2();
                currentStepValid = this.stepFulfilled[2];
                break;
            }

            // If current step validation fails, don't proceed
            if (!currentStepValid) {
              return;
            }

            // Then check if all previous steps are fulfilled before allowing navigation
            const stepsToCheck = [0, 1, 2].filter((i) => i < step);
            for (const i of stepsToCheck) {
              if (!this.stepFulfilled[i as keyof typeof this.stepFulfilled]) {
                toast.error(`Please complete step ${i + 1} first`);
                // Navigate to the first unfulfilled step
                this.step = i;
                return;
              }
            }
          }

          // If we get here, all validations passed or we're going to a lower step
          this.step = step;

          // Update maxReachedStep if we've moved to a higher step
          if (step > this.maxReachedStep) {
            this.maxReachedStep = step;
          }

          useScrollToTop();
        },
      },
      persist: true,
    },
  );

  return store();
};
