// import { PageQueryType } from "@/types";
import Trophy3 from "@/assets/icon/trophy-3.svg";
import Overview from "@/assets/icon/overview.svg";
import Leaderboard from "@/assets/icon/leaderboard.svg";
import Announcement from "@/assets/icon/announcement.svg";
import { computed } from "vue";
import { useAuthStore } from "@/store/auth-store";

const SidebarMenu = computed(() => {
  // const authStore = useAuthStore();ap
  return [
    {
      id: 1,
      name: "Home",
      icon: "eva:home-fill",
      link: "/",
      hidden: false,
    },
    {
      id: 2,
      name: "Program",
      customIcon: Trophy3,
      link: "/program",
      hidden: false,
    },
    {
      id: 3,
      name: "Member",
      icon: "eva:people-fill",
      link: "/member",
      hidden: false,
    },
    {
      id: 4,
      name: "Mission",
      icon: "eva:briefcase-fill",
      link: "/mission",
      hidden: true,
    },
    {
      id: 5,
      name: "Announcement",
      customIcon: Announcement,
      link: "/announcement",
      hidden: false,
    },
    {
      id: 6,
      name: "Reward",
      icon: "eva:gift-fill",
      link: "/reward",
      hidden: false,
    },
  ];
});

const SidebarSuperDashboard = [
  {
    id: 1,
    name: "Home",
    icon: "eva:home-fill",
    link: "super-dashboard.home.index",
    hidden: false,
  },
  {
    id: 2,
    name: "Reward",
    icon: "eva:gift-fill",
    link: "super-dashboard.reward.index",
    hidden: false,
  },
  {
    id: 3,
    name: "Setting",
    icon: "eva:settings-2-fill",
    link: "super-dashboard.setting.index",
    hidden: false,
  },
];

const SidebarOthers = computed(() => {
  const authStore = useAuthStore();
  return [
    // {
    //   id: 1,
    //   name: "Balance",
    //   icon: "eva:credit-card-fill",
    //   link: "/balance",
    //   hidden: false,
    // },
    {
      id: 2,
      name: "Setting",
      icon: "eva:settings-2-fill",
      link: authStore.level == "SUPER" ? "/setting/admin" : "/setting",
      hidden: false,
    },
  ];
});

const SidebarOtherProgram = [
  {
    id: 1,
    name: "Setting",
    icon: "eva:settings-2-fill",
    link: "program.detail.setting.index",
    hidden: false,
  },
];
const SidebarMenuProgram = [
  {
    id: 1,
    name: "Overview",
    customIcon: Overview,
    link: "program.detail.index",
    hidden: false,
  },
  {
    id: 2,
    name: "Mission",
    icon: "eva:briefcase-fill",
    link: "program.detail.mission.all-mission-history",
    hidden: false,
  },
  {
    id: 3,
    name: "Member",
    icon: "eva:people-fill",
    link: "program.detail.member.index",
    hidden: false,
  },
];

const SidebarProgram = [
  {
    id: 1,
    name: "Leaderboard",
    customIcon: Leaderboard,
    link: "program.detail.leaderboard",
    hidden: false,
  },
  {
    id: 2,
    name: "Badge",
    icon: "eva:star-fill",
    link: "program.detail.badge.index",
    hidden: false,
  },
  {
    id: 3,
    name: "Content Library",
    icon: "eva:video-fill",
    link: "program.detail.content-library",
    hidden: false,
  },
];

// export const DefaultPageQuery = {
//   search: "",
//   sort: "",
//   per_page: 20,
//   page: 1,
//   last_page: 1,
// };

// interface HandleIconMissionObjective {
//   [key: string]: string;
// }

interface ColorChart {
  [key: string]: string;
}

const handleColorChart: ColorChart = {
  blue: "#2B5DD7",
  yellow: "#FEB530",
  green: "#08B295",
  purple: "#9500CA",
  red: "#FF5959",
};

const ProdUrl = "https://cuit.komune.id/be";
const DefaultUrl = import.meta.env.VITE_API_URL;
const Environment = import.meta.env.VITE_APP_MODE;
const ActiveBuyReward = import.meta.env.VITE_BUY_REWARD == "ACTIVE";

const DefaultServer = {
  label: "Default",
  value: DefaultUrl,
  color: "yellow-500",
};

const Servers = [
  {
    label: "Default",
    value: DefaultUrl,
    color: "yellow-500",
  },
  {
    label: "Dev",
    value: "https://komune-dev.venturo.pro/be",
    color: "green-500",
  },
  {
    label: "UAT",
    value: "https://uat.komune.id/be",
    color: "blue-500",
  },
  {
    label: "Staging",
    value: "https://komune-fe-staging-912388243819.asia-southeast2.run.app/be",
    color: "blue-500",
  },
  {
    label: "Production",
    value: "https://cuit.komune.id/be",
    color: "blue-500",
  },
  {
    label: "Custom",
    value: "",
    color: "indigo-500",
  },
];

export {
  ActiveBuyReward,
  // DefaultPageQuery,
  Servers,
  ProdUrl,
  DefaultUrl,
  DefaultServer,
  handleColorChart,
  Environment,
  SidebarMenu,
  SidebarOthers,
  SidebarProgram,
  SidebarMenuProgram,
  SidebarOtherProgram,
  SidebarSuperDashboard,
};
