<script setup lang="ts">
import { useProgramStore } from "@/store/program";

import Button from "@/components/button/index.vue";

const programStore = useProgramStore();
</script>

<template>
  <div class="flex flex-row flex-wrap items-center gap-3 lg:flex-nowrap">
    <p>Step</p>
    <Button
      text="Program Details"
      size="sm"
      :icon="
        programStore.stepFulfilled[0] && programStore.step !== 0
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="programStore.step == 0 ? 'stepFulfilledYellow' : 'roleGreen'"
      @click="programStore.goToStep(0)"
      class="step-button"
    />
    <Button
      v-if="programStore.maxReachedStep >= 1"
      text="Program Objectives"
      size="sm"
      :icon="
        programStore.stepFulfilled[1] && programStore.step !== 1
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="programStore.step == 1 ? 'stepFulfilledYellow' : 'roleGreen'"
      @click="programStore.goToStep(1)"
      class="step-button"
    />
    <Button
      v-if="programStore.maxReachedStep >= 2"
      text="Setup Achievement"
      size="sm"
      :icon="
        programStore.stepFulfilled[2] && programStore.step !== 2
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="programStore.step == 2 ? 'stepFulfilledYellow' : 'roleGreen'"
      @click="programStore.goToStep(2)"
      class="step-button"
    />
    <Button
      v-if="programStore.maxReachedStep >= 3"
      text="Select Participants"
      size="sm"
      :icon="
        programStore.stepFulfilled[3] && programStore.step !== 3
          ? 'eva:checkmark-circle-2-fill'
          : ''
      "
      icon-class="text-white"
      :variant="programStore.step == 3 ? 'stepFulfilledYellow' : 'roleGreen'"
      @click="programStore.goToStep(3)"
      class="step-button"
    />
    <Button
      v-if="programStore.maxReachedStep >= 4"
      text="Program Review"
      size="sm"
      :icon="programStore.step !== 4 ? 'eva:checkmark-circle-2-fill' : ''"
      icon-class="text-white"
      :variant="programStore.step == 4 ? 'stepFulfilledYellow' : 'roleGreen'"
      @click="programStore.goToStep(4)"
      class="step-button"
    />
  </div>
  <div class="flex flex-row items-center gap-3">
    <Button
      v-if="programStore.step > 0"
      @click="programStore.backStep"
      text="Previous"
      size="sm"
      variant="outline"
      icon="eva:arrow-ios-back-outline"
    />
    <Button
      v-if="programStore.step < 4"
      @click="programStore.nextStep"
      text="Continue"
      variant="roleGreen"
      size="sm"      
      icon="eva:arrow-ios-forward-outline"
      position-icon="right"
      :disabled="programStore.isNextDisabled"
    />
    <Button
      v-else
      @click="programStore.openModal"
      text="Create Program"
      size="sm"
      variant="roleGreen"
      icon="eva:arrow-ios-forward-outline"
      position-icon="right"
    />
  </div>
</template>

<style scoped>
.step-button {
  cursor: pointer !important;
}
.step-button:hover {
  cursor: pointer !important;
}
</style>
