import { AddMemberValues, UpdateMemberValues } from "@/constants/form/member";
import axiosInstance from "@/lib/axiosInstance";
import { PageQueryType, ResponseType } from "@/types";
import {
  Member,
  MemberAchievement,
  MemberActivity,
  MemberArea,
  MemberConnection,
  MemberDailyLogin,
  MemberDetail,
  MemberMissionHistory,
  MemberPointHistory,
  PostPerformanceType,
  MemberQuota,
  MemberRanks,
  ReactPerformanceType,
  MemberReward,
  MemberSocials,
  SocialSummary,
  SocialPosts,
  MemberGrowth,
  MemberBulkPreview,
  MemberProgramHistory,
  BadgeMemberInProgram,
} from "@/types/member";
import { Mission, ProofPost } from "@/types/mission";
import moment from "moment";
import nProgress from "nprogress";

export async function getMemberQuota(): Promise<ResponseType<MemberQuota>> {
  const response = await axiosInstance.get("/members/quota");
  return response.data;
}

export async function getMembersActivity(): Promise<
  ResponseType<MemberActivity>
> {
  const response = await axiosInstance.get("/members/activity");
  return response.data;
}

export async function getMemberGrowth(
  year: number,
): Promise<ResponseType<MemberGrowth[]>> {
  const response = await axiosInstance.get("/members/growth", {
    params: {
      year: year,
    },
  });
  return response.data;
}

export async function getMemberAreas(): Promise<ResponseType<MemberArea[]>> {
  const response = await axiosInstance.get("/members/areas");
  return response.data;
}

export async function changeMemberStatus({
  memberId,
  status,
}: {
  memberId: string;
  status: "REJECTED" | "VERIFIED";
}): Promise<ResponseType> {
  const response = await axiosInstance.put(`/members/${memberId}/approval`, {
    status: status,
  });
  return response.data;
}

export async function getMemberConnection(): Promise<
  ResponseType<MemberConnection>
> {
  const response = await axiosInstance.get("/members/connection");
  return response.data;
}

export async function getMemberList(
  PageQuery: PageQueryType,
  is_pro?: boolean,
  unused_in?: string,
): Promise<ResponseType<Member[]>> {
  const response = await axiosInstance.get("/members", {
    params: {
      ...PageQuery,
      is_pro: is_pro,
      unused_in: unused_in,
    },
  });

  return response.data;
}

export async function getMemberDetail(
  id: string,
): Promise<ResponseType<MemberDetail>> {
  const response = await axiosInstance.get(`/members/${id}`);
  return response.data;
}

export async function getPostPerformanceType(
  id: string,
  month: string,
): Promise<ResponseType<PostPerformanceType>> {
  const monthNumber = parseInt(month);
  const response = await axiosInstance.get(`/members/${id}/performance/post`, {
    params: {
      month: monthNumber,
    },
  });
  return response.data;
}
export async function getReactPerformanceType(
  id: string,
  month: string,
): Promise<ResponseType<ReactPerformanceType>> {
  const monthNumber = parseInt(month);
  const response = await axiosInstance.get(`/members/${id}/performance/react`, {
    params: {
      month: monthNumber,
    },
  });
  return response.data;
}

export async function getMemberAchievementSummary(
  id: string,
): Promise<ResponseType<MemberAchievement>> {
  const response = await axiosInstance.get(`/members/${id}/achievement`);
  return response.data;
}

export async function getMemberProgramSummary(
  id: string,
  programId: string,
): Promise<ResponseType<MemberRanks>> {
  const response = await axiosInstance.get(
    `/members/${id}/program/${programId}/rank`,
  );
  return response.data;
}

export async function getMemberDailyLogin(
  id: string,
  month: string,
): Promise<ResponseType<MemberDailyLogin[]>> {
  const monthNumber = parseInt(month);
  const response = await axiosInstance.get(`/members/${id}/activity`, {
    params: {
      month: monthNumber,
    },
  });
  return response.data;
}

export async function getMemberSocialMediaConnected(
  id: string,
): Promise<ResponseType<MemberSocials[]>> {
  const response = await axiosInstance.get(`/members/${id}/socials`);
  return response.data;
}

export async function getMemberReward(
  id: string,
  PageQuery: PageQueryType,
): Promise<ResponseType<MemberReward[]>> {
  const params = {
    ...PageQuery,
    status: PageQuery.additionalQuery,
  };
  const response = await axiosInstance.get(`/members/${id}/vouchers`, {
    params: params,
  });
  return response.data;
}

export async function getMemberPointHistory(
  id: string,
  PageQuery: PageQueryType,
): Promise<ResponseType<MemberPointHistory[]>> {
  const response = await axiosInstance.get(`/members/${id}/points`, {
    params: PageQuery,
  });
  return response.data;
}

export async function getMemberMissionHistory(
  id: string,
  PageQuery: PageQueryType<Mission>,
): Promise<ResponseType<MemberMissionHistory[]>> {
  const response = await axiosInstance.get(`/members/${id}/missions`, {
    params: PageQuery,
  });
  return response.data;
}

export async function getMemberProgramHistory(
  id: string,
  pageQuery: PageQueryType,
): Promise<ResponseType<MemberProgramHistory[]>> {
  const response = await axiosInstance.get(`/members/${id}/programs`, {
    params: pageQuery,
  });
  return response.data;
}

export async function getMemberContentLibrary(
  pageQuery: PageQueryType<string>,
): Promise<ResponseType<ProofPost[]>> {
  const params = {
    ...pageQuery,
    member_id: pageQuery.additionalQuery,
  };
  const response = await axiosInstance.get(`/contents`, {
    params: params,
  });

  return response.data;
}

export async function getSocialMediaSummary(
  socialId: string,
): Promise<ResponseType<SocialSummary>> {
  const response = await axiosInstance.get(`/socials/${socialId}`);
  return response.data;
}

export async function getSocialMediaPosts(
  socialId: string,
): Promise<ResponseType<SocialPosts[]>> {
  try {
    const response = await axiosInstance.get(`/socials/${socialId}/posts`);
    return response.data;
  } catch (error) {
    error;
    return {
      data: [],
      dev: error,
      message: "Data not found",
      status_code: 404,
    };
  }
}

export const CreateMember = async (
  data: AddMemberValues,
): Promise<ResponseType<string>> => {
  const response = await axiosInstance.post("/members", data);
  return response.data;
};

export const UpdateMember = async (
  id: string,
  data: UpdateMemberValues,
): Promise<ResponseType<string>> => {
  const response = await axiosInstance.put(`/members/${id}`, data);
  return response.data;
};

export const deleteMember = async (
  ids: Array<string>,
): Promise<ResponseType<string[]>> => {
  const response = await axiosInstance.post(`/members/delete`, {
    ids: ids,
  });
  return {
    message: response.data.message,
    status_code: response.data.status_code,
    data: ids,
    dev: response.data.dev,
    meta: response.data.meta,
  };
};

export const getBadgeMemberInProgram = async (
  id: string,
): Promise<ResponseType<BadgeMemberInProgram[]>> => {
  const response = await axiosInstance.get(`/members/${id}/badges`);
  return response.data;
};

export const getPreviewBulkMember = async (
  file: File,
): Promise<ResponseType<MemberBulkPreview[]>> => {
  const response = await axiosInstance.postForm(`/members/bulk/preview`, {
    file: file,
  });
  return response.data;
  // /members/bklu / preview;
};

export const storeMemberBulk = async ({
  group_id,
  is_whatsapp,
  members,
  endpoint,
}: {
  group_id: string[];
  is_whatsapp: boolean;
  members: MemberBulkPreview[];
  endpoint: string;
}): Promise<ResponseType<string>> => {
  const formattedGroupId =
    group_id.length == 0 ? null : group_id.map((id) => id);
  const form = {
    group_id: formattedGroupId,
    is_whatsapp: is_whatsapp,
    members: members,
    endpoint: endpoint,
  };
  const response = await axiosInstance.post(`/members/bulk`, form);
  return response.data;
};

export const bulkReminderMember = async (
  memberIds: Array<string>,
  endpoint: string,
): Promise<ResponseType<string>> => {
  const response = await axiosInstance.post(`/members/reminder`, {
    member_ids: memberIds,
    endpoint: endpoint,
  });
  return response.data;
};

export function downloadReport(): void {
  nProgress.start();

  axiosInstance
    .get(`/members/report`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment().format("DDMMYY")}_member_growth_report.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}
