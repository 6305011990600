import {
  AddMemberToGroupValues,
  CreateGroupValues,
} from "@/constants/form/group";
import axiosInstance from "@/lib/axiosInstance";
import { PageQueryType, ResponseType } from "@/types";
import { Group, GroupMember } from "@/types/group";
import { PostPerformanceType, ReactPerformanceType } from "@/types/member";
import nProgress from "nprogress";

export async function getAllGroups(
  params: PageQueryType,
  with_general: boolean = true,
  unused_in?: string,
): Promise<ResponseType<Group[]>> {
  let formattedParams = { ...params, with_general: with_general, unused_in };
  const response = await axiosInstance.get("/groups", {
    params: formattedParams,
  });
  return response.data;
}

export async function addGroups(
  data: CreateGroupValues | { title: string },
): Promise<ResponseType<Group>> {
  const form = new FormData();

  form.append("title", data.title);

  if ("member_ids" in data) {
    data.member_ids.forEach((id) => form.append("member_ids[]", id.toString()));
  }

  if ("banner" in data) {
    form.append("banner", data.banner);
  }

  const response = await axiosInstance.post("/groups", form);
  return response.data;
}

export async function updateGroups(
  id: string,
  data: CreateGroupValues,
): Promise<ResponseType> {
  const form = new FormData();

  form.append("title", data.title);
  data.member_ids.forEach((id) => form.append("member_ids[]", id.toString()));
  form.append("banner", data.banner);

  const response = await axiosInstance.post(`/groups/${id}`, form);
  return response.data;
}

export async function addGroupToProgram({
  programId,
  group_ids,
}: {
  programId: string;
  group_ids: string[];
}): Promise<ResponseType> {
  const response = await axiosInstance.post(`/programs/${programId}/groups`, {
    group_ids,
  });
  return response.data;
}

export async function getGroupDetail(id: string): Promise<ResponseType<Group>> {
  const response = await axiosInstance.get(`/groups/${id}`);
  return response.data;
}
export const getGroupPerformancePost = async (
  id: string,
  month: string,
): Promise<ResponseType<PostPerformanceType>> => {
  const monthNumber = parseInt(month);
  const response = await axiosInstance.get(`/groups/${id}/performance/post`, {
    params: {
      month: monthNumber,
    },
  });
  return response.data;
};

export const getGroupPerformanceReact = async (
  id: string,
  month: string,
): Promise<ResponseType<ReactPerformanceType>> => {
  const monthNumber = parseInt(month);
  const response = await axiosInstance.get(`/groups/${id}/performance/react`, {
    params: {
      month: monthNumber,
    },
  });
  return response.data;
};

export const getMemberGroups = async (
  groupId: string,
  pageQuery: PageQueryType,
): Promise<ResponseType<GroupMember[]>> => {
  const response = await axiosInstance.get(`/members`, {
    params: {
      ...pageQuery,
      group_id: groupId,
    },
  });
  return response.data;
};

export const addMemberToGroup = async (
  groupId: string,
  data: AddMemberToGroupValues,
): Promise<ResponseType<string>> => {
  const response = await axiosInstance.post(`/groups/${groupId}/members`, data);
  return response.data;
};

export const deleteGroup = async (
  ids: Array<string>,
): Promise<ResponseType<string>> => {
  const response = await axiosInstance.post("/groups/delete", {
    ids: ids,
  });
  return response.data;
};

export const deleteMemberFromGroup = async (
  groupId: string,
  memberIds: string[],
): Promise<ResponseType> => {
  const response = await axiosInstance.post(
    `/groups/${groupId}/members/remove`,
    {
      member_ids: memberIds,
    },
  );
  return response.data;
};

export function downloadReport(): void {
  nProgress.start();
  axiosInstance
    .get("/groups/report", {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Group Report.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}
