<script setup lang="ts">
import Avatar from "@/components/avatar/index.vue";
import Notification from "../fragments/notification.vue";
import Icon from "@/components/icon/index.vue";
import { useLayoutStore } from "@/store/layout";

const layoutStore = useLayoutStore();
</script>

<template>
  <nav
    class="flex h-[90px] w-full flex-row items-center justify-between overflow-hidden border-b border-primary_slate bg-white px-3 py-3 pr-7"
  >
    <div class="flex w-full flex-row items-center gap-6">
      <Icon
        icon="eva:menu-outline"
        class="cursor-pointer text-2xl md:hidden"
        @click="layoutStore.toggleSidebar()"
      />
      <h1 class="title-routing">{{ $route.meta.title }}</h1>
    </div>

    <div class="flex flex-row items-center gap-3">
      <Notification />
      <Avatar />
    </div>
  </nav>
</template>
