import NProgress from "nprogress";
import { createRouter, createWebHistory } from "vue-router";

import { MemberPages } from "./route/member";
import { MissionPages } from "./route/mission";
import { RewardsPages } from "./route/reward";

import { HomePages } from "./route/home";
import { SuperDashboardPages } from "./route/super-dashboard";
import { SettingsPages } from "./route/setting";
import { ProgramsPages } from "./route/program";
import { BalancePages } from "./route/balance";
import { GroupPages } from "./route/group";
import { OthersPages } from "./route/others";
import handleNavigation from "@/lib/navigation";

const routes = [
  ...OthersPages,
  MemberPages,
  SuperDashboardPages,
  HomePages,
  MissionPages,
  RewardsPages,
  SettingsPages,
  ProgramsPages,
  BalancePages,
  GroupPages,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_: any, __: any, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, _, next) => {
  fetch("/version.json")
    .then((res) => res.json())
    .then((data) => {
      if (import.meta.env.VITE_APP_MODE === "local") return;

      const currentVersion = data.version;
      const storedVersion = localStorage.getItem("app-version");

      if (storedVersion !== currentVersion) {
        if (storedVersion) {
          alert("App version changed. Please refresh the page.");
        }

        localStorage.setItem("app-version", currentVersion);
        window.location.reload();
      }
    })
    .catch((error) => {
      console.error("Error fetching version.json:", error);
    });

  handleNavigation(to, next);
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
