import axiosInstance from "@/lib/axiosInstance";
import { PageQueryType, ResponseType } from "@/types";
import {
  MissionSurvey,
  ParticipantSurvey,
  SummarySurvey,
} from "@/types/mission-survey";
import moment from "moment";
import nProgress from "nprogress";

export async function getMissionDetail(
  MissionId: string,
): Promise<ResponseType<MissionSurvey>> {
  const response = await axiosInstance.get(`/missions/${MissionId}/survey`);
  return response.data;
}

export async function getMissionSummary(
  MissionId: string,
): Promise<ResponseType<SummarySurvey>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/survey/summary`,
  );
  return response.data;
}

export async function getMissionParticipant(
  MissionId: string,
  PageQuery: PageQueryType,
): Promise<ResponseType<ParticipantSurvey[]>> {
  const response = await axiosInstance.get(
    `/missions/${MissionId}/survey/participants`,
    {
      params: PageQuery,
    },
  );
  return response.data;
}

export function downloadReport({
  missionId,
  name,
}: {
  missionId: string;
  name: string;
}): void {
  nProgress.start();
  axiosInstance
    .get(`/missions/${missionId}/survey/report`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment().format("YYYYMMDD")}_polling_report_${name}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    })
    .finally(() => {
      nProgress.done();
    });
}
